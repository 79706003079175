import React, { useState } from "react";
import { useDrop } from 'react-dnd';
import Tooltip from 'rc-tooltip';

// Constants
import colors from "../../../utils/colors";
import items from "../../../utils/items";

// Images
import factoryPlaceholder from "../../../assets/images/factory-placeholder.png"
import machinePlaceholder from "../../../assets/images/machine-placeholder.png"
import productPlaceholder from "../../../assets/images/product-placeholder.png"

// Components
import DraggableContainer from "./DraggableContainer";
import {Col, Container, Image, Row} from "react-bootstrap";

export default function DroppableField(props) {
	const [isHover, setIsHover] = useState(false);

	const [{ isOver, canDrop }, drop] = useDrop({
		accept: (props.containerType !== undefined) ? [items.container, props.containerType] : [items.container],
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		drop: (item) => {
			if (props.productionType) {
				props.appendContainer(props.productionType, item.coins, props.id, item.hasCard, item.cardValue, item.cardColor);
			}else{
				props.appendContainer(item.coins, props.id);
			}

			return item
		},
	})

	const backgroundColor = (isOver && canDrop) ? colors.activeBackground : "";
	const canPlayerDrop = (props.canEdit) ? drop : null;
	let showTooltip = (props.data.isDragging && props.data.type === "CONTAINER") ? true : false
	if (!showTooltip) {
		if (isHover && props.data.playerId) {
			showTooltip = true
		}
	}else if (props.data.playerId === window.playerId){
		showTooltip = false
	}
	return (
		<>
			{
				(props.data.hasContainer) ?
					<div className={ props.class } onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
						<Tooltip
							placement="topRight"
							align={{
								offset: [0, 5],
							}}
							visible={ showTooltip }
							overlay={<span>{ props.data.playerName }</span>}
						>
							<DraggableContainer
								canEdit={props.canEdit}
								id={ props.id }
								data={ props.data }
								isHorizontal={ props.isHorizontal }
								containerType={ props.containerType }
								productionType={ props.productionType }
								removeContainer={ props.removeContainer }
								addCoin={ props.addCoin }
								removeCoin={ props.removeCoin }
								addCard={ props.addCard }
							/>
						</Tooltip>
					</div>
					:
					<div className={ props.class } style={{ backgroundColor: backgroundColor }} ref={canPlayerDrop}>
						{
							(props.productionType === "factory" && <div className="field-content">
									<Container>
										<Row>
											<Col className="field-content-title">&nbsp;</Col>
											<Col className="field-content-value">&nbsp;</Col>
										</Row>
										<Row>
											<Col className="field-content-title">&nbsp;</Col>
											<Col className="field-content-value">&nbsp;</Col>
										</Row>
										<Row>
											<Col className="field-content-title">&nbsp;</Col>
											<Col className="field-content-value">&nbsp;</Col>
										</Row>
										<Row className="field-content-image-row machine">
											<Col>
												<Image src={factoryPlaceholder} />
											</Col>
										</Row>
									</Container>
								</div>)
							||
							(props.productionType === "product" && <div className="field-content">
									<Container>
										<Row>
											<Col className="field-content-title">f</Col>
											<Col className="field-content-value">10.0</Col>
										</Row>
										<Row>
											<Col className="field-content-title">m</Col>
											<Col className="field-content-value">6.0</Col>
										</Row>
										<Row>
											<Col className="field-content-title">s</Col>
											<Col className="field-content-value">4.0</Col>
										</Row>
										<Row className="field-content-image-row product">
											<Col>
												<Image src={productPlaceholder} />
											</Col>
										</Row>
									</Container>
								</div>)
							||
							(props.productionType === "machine" && <div className="field-content">
									<Container>
										<Row>
											<Col className="field-content-title">f</Col>
											<Col className="field-content-value">10.0</Col>
										</Row>
										<Row>
											<Col className="field-content-title">m</Col>
											<Col className="field-content-value">7.5</Col>
										</Row>
										<Row>
											<Col className="field-content-title">s</Col>
											<Col className="field-content-value">5.0</Col>
										</Row>
										<Row className="field-content-image-row machine">
											<Col>
												<Image src={machinePlaceholder} />
											</Col>
										</Row>
									</Container>
								</div>)
						}
					</div>
			}
		</>
	);
}