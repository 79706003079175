import React, { Component } from 'react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './css/SimFlex/Style3dModel.css';
import './css/SimFlex/Zoom.css';
import './css/PropertiesPanel.css';
import 'rc-tooltip/assets/bootstrap.css';

// Components
import Header from "./components/SimFlex/Header";
import SimFlex from "./components/SimFlex/SimFlex";
import KitsAndObjects from "./components/SimFlex/KitsAndObjects";

import reducer from "./reducers/index";
import FilesLibrary from "./components/SimFlex/FilesLibrary";

const store = createStore(reducer);

class App extends Component{

	constructor(props) {
		super(props);

		this.state = {
			canEdit: true
		}
	}

	logout = () => {
		
	}

	render() {
		return (
			<Provider store={store}>
				<DndProvider backend={Backend}>
					<Header
						canEdit={this.state.canEdit}
						isAdmin={ false }
						logoutAction={this.logout}
					/>
					<KitsAndObjects
						canEdit={this.state.canEdit}
					/>
					<div className="main-app-bg">
						<SimFlex
							canEdit={this.state.canEdit}
						/>
					</div>
					<FilesLibrary />
				</DndProvider>
			</Provider>
		);
	}

}

export default App;