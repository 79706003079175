import React, {Component} from "react";
import {Row, Col} from "react-bootstrap";

// Components
import PurchaseRow from "./PurchaseRow";

class PurchaseBlock extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit
		}
	}

	render() {
		return (
			<div className="Purchase-block-container">
				<Row>
					<Col>
						<div className="Purchase-bold-text Purchase-title-text-size Same-line">EINKAUF</div>
						<div className="Purchase-title-text-size Same-line">PURCHASING</div>
						<div className="Purchase-title-text-size Text-italic Same-line">COMPRAS</div>
					</Col>
				</Row>
				<PurchaseRow canEdit={this.state.canEdit} className="Standard-row" title="STANDARD" />
				<PurchaseRow canEdit={this.state.canEdit} className="Compact-row" title="COMPACT" />
				<PurchaseRow canEdit={this.state.canEdit} className="Plus-row" title="PLUS" />
				<PurchaseRow canEdit={this.state.canEdit} className="Luxus-row" title="LUXUS" />
			</div>
		);
	}
}

export default PurchaseBlock;