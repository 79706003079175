import strategyPaperInitState from "./strategy-papyer-init-state";
import decisionSheetInitState from "./decision-sheet-init-state";
import AnnualReportInitStatement from "./annual-report-init-state";

const initState = {
	StrategyPaper: strategyPaperInitState,
	DecisionSheet: decisionSheetInitState,
	AnnualReport: AnnualReportInitStatement
}

const reducer = (state = initState, action) => {
	if (action.type === "UPDATE_FORM") {
		return {
			...state,
			[action.form]: action.value
		}
	}else if (action.type === "StrategyPaper") {
		if (action.option !== undefined) {
			state[action.type][action.section][action.field][action.option] = action.value
		}else{
			state[action.type][action.section][action.field] = action.value
		}

		return {
			...state,
			StrategyPaper: {
				...state.StrategyPaper
			}
		}
	}else if (action.type === "DecisionSheet") {
		state[action.type][action.section][action.field][action.option] = action.value;

		return {
			...state,
			DecisionSheet: {
				...state.DecisionSheet
			}
		}
	}else if (action.type === "AnnualReport") {
		state[action.type][action.section][action.field] = action.value;

		return {
			...state,
			AnnualReport: {
				...state.AnnualReport
			}
		}
	}

	return state;
}

export default reducer;