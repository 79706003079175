import React from 'react';
import {connect} from "react-redux";
import { Container, Row, Col, Table } from 'react-bootstrap';

import { FaCheck } from 'react-icons/fa';

import "../../../css/Forms.css";

import localization from "../../../utils/localization";

// Dispatcher
import mapDispatchToProps from "../../../reducers/dispatchers";

// Components
import FixedAsset from "./Partials/StrategyPaperFixedAsset";
import StrategyPaperFormControl from './Partials/StrategyPaperFormControl';
import StrategyPaperProduction from './Partials/StrategyPaperProduction';

class StrategyPaper extends React.Component {

	closeForm = () => {
		this.props.openFormView("")
	}

	checkboxPressed = (section, field) => {
		if (this.props.strategyPaper[section][field]) {
			this.props.updateFormOption("StrategyPaper", section, field, undefined, false)
		}else{
			this.props.updateFormOption("StrategyPaper", section, field, undefined, true)
		}
	}

	inputChanged = (field, value) => {
		this.props.updateFormOption("StrategyPaper", "credits", field, undefined, value)
	}

	optionChanged = (section, field, option, value) => {
		this.props.updateFormOption("StrategyPaper", section, field, option, value)
	}

	render() {
		const selectedLang = this.props.session.selectedLang;

		return (
			<Container className="formContainer">
				<Row>
					<Col>
						<div className="formHeader">
							<div className="headerTitle bold">{ localization[selectedLang].strategyPaper }</div>
							<div className="closeForm" onClick={ this.closeForm.bind(this) }>X</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table bordered>
							<tbody>
								<tr>
									<td style={{ width: "140px" }}>
										<div className="bold">{ localization[selectedLang].developmentOfProducts }</div>
									</td>
									<td>
										<Container>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold">{ localization[selectedLang].compact }</div>
													<div className="hintForm">2 { localization[selectedLang].years } { localization[selectedLang].costs } 6 (3/3)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "developmentOfProducts", "compact")}>
														{
															(this.props.strategyPaper.developmentOfProducts.compact) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold">{ localization[selectedLang].plus }</div>
													<div className="hintForm">3 { localization[selectedLang].years } { localization[selectedLang].costs } 8 (3/3/2)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "developmentOfProducts", "plus")}>
														{
															(this.props.strategyPaper.developmentOfProducts.plus) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold">{ localization[selectedLang].luxus }</div>
													<div className="hintForm">5 { localization[selectedLang].years } { localization[selectedLang].costs } 10 (2/2/2/2/2)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "developmentOfProducts", "luxus")}>
														{
															(this.props.strategyPaper.developmentOfProducts.luxus) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
										</Container>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold">{ localization[selectedLang].openingUpOfMarkets }</div>
									</td>
									<td>
										<Container>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold">{ localization[selectedLang].international }</div>
													<div className="hintForm">1 { localization[selectedLang].year } { localization[selectedLang].costs } 2 (2)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "openingMarket", "international") }>
														{
															(this.props.strategyPaper.openingMarket.international) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold">{ localization[selectedLang].intercontinental1 }</div>
													<div className="hintForm">2 { localization[selectedLang].years } { localization[selectedLang].costs } 4 (2/2)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "openingMarket", "intercontinental1") }>
														{
															(this.props.strategyPaper.openingMarket.intercontinental1) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
											<Row>
												<Col md="7" className="removeLeftPadding">
													<div className="bold">{ localization[selectedLang].intercontinental2 }</div>
													<div className="hintForm">4 { localization[selectedLang].years } { localization[selectedLang].costs } 8 (2/2/2/2)</div>
												</Col>
												<Col md="5">
													<div className="checkContainer" onClick={ this.checkboxPressed.bind(this, "openingMarket", "intercontinental2") }>
														{
															(this.props.strategyPaper.openingMarket.intercontinental2) ?
																<FaCheck />
															:
																null
														}
													</div>
												</Col>
											</Row>
										</Container>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold">{ localization[selectedLang].fixedAssets }</div>
										<div className="hintForm">- { localization[selectedLang].prodFacilities }?</div>
										<div className="hintForm">- { localization[selectedLang].purchase }/{ localization[selectedLang].rent }?</div>
										<div className="hintForm">- { localization[selectedLang].machines }?</div>
										<div className="hintForm">&nbsp; F - Fast 10</div>
										<div className="hintForm">&nbsp; M - Medium 7.5</div>
										<div className="hintForm">&nbsp; S - Slow 5</div>
									</td>
									<td>
										<Container>
											<FixedAsset
												option="national_1"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.national_1 } 
												index={ 0 } 
												selectedLang={ selectedLang }
											/>
											<FixedAsset 
												option="national_2"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.national_2 } 
												index={ 1 } 
												selectedLang={ selectedLang }
											/>
											<FixedAsset 
												option="national_3"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.national_3 } 
												index={ 2 } 
												selectedLang={ selectedLang }
											/>
											<FixedAsset 
												option="international_1"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.international_1 } 
												index={ 3 } 
												selectedLang={ selectedLang }
											/>
											<FixedAsset 
												option="international_2"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.international_2 } 
												index={ 4 } 
												selectedLang={ selectedLang }
											/>
											<FixedAsset 
												option="international_3"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.international_3 } 
												index={ 5 } 
												selectedLang={ selectedLang }
											/>
											<FixedAsset 
												option="intercontinental1_1"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.intercontinental1_1 } 
												index={ 6 } 
												selectedLang={ selectedLang }
											/>
											<FixedAsset 
												option="intercontinental1_2"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.intercontinental1_2 } 
												index={ 7 } 
												selectedLang={ selectedLang }
											/>
											<FixedAsset 
												option="intercontinental2_1"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.intercontinental2_1 } 
												index={ 8 } 
												selectedLang={ selectedLang }
											/>
											<FixedAsset 
												option="intercontinental2_2"
												optionChanged={ this.optionChanged }
												field={ this.props.strategyPaper.fixedAssets.intercontinental2_2 } 
												index={ 9 } 
												selectedLang={ selectedLang }
											/>
										</Container>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold">{ localization[selectedLang].productionInProdFacility }</div>
										<div className="hintForm">- { localization[selectedLang].product }?</div>
										<div className="hintForm">- { localization[selectedLang].quantity }?</div>
									</td>
									<td>
										<StrategyPaperProduction
											option="national_1"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.national_1 } 
											index={ 0 } 
											selectedLang={ selectedLang }
										/>
										<StrategyPaperProduction 
											option="national_2"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.national_2 } 
											index={ 1 } 
											selectedLang={ selectedLang }
										/>
										<StrategyPaperProduction 
											option="national_3"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.national_3 } 
											index={ 2 } 
											selectedLang={ selectedLang }
										/>
										<StrategyPaperProduction 
											option="international_1"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.international_1 } 
											index={ 3 } 
											selectedLang={ selectedLang }
										/>
										<StrategyPaperProduction 
											option="international_2"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.international_2 } 
											index={ 4 } 
											selectedLang={ selectedLang }
										/>
										<StrategyPaperProduction 
											option="international_3"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.international_3 } 
											index={ 5 } 
											selectedLang={ selectedLang }
										/>
										<StrategyPaperProduction 
											option="intercontinental1_1"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.intercontinental1_1 } 
											index={ 6 } 
											selectedLang={ selectedLang }
										/>
										<StrategyPaperProduction 
											option="intercontinental1_2"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.intercontinental1_2 } 
											index={ 7 } 
											selectedLang={ selectedLang }
										/>
										<StrategyPaperProduction 
											option="intercontinental2_1"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.intercontinental2_1 } 
											index={ 8 } 
											selectedLang={ selectedLang }
										/>
										<StrategyPaperProduction 
											option="intercontinental2_2"
											optionChanged={ this.optionChanged }
											field={ this.props.strategyPaper.production.intercontinental2_2 } 
											index={ 9 } 
											selectedLang={ selectedLang }
										/>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold">{ localization[selectedLang].creditTakeUp }</div>
									</td>
									<td>
										<Container>
											<Row>
												<Col>
													<div className="bold">{ localization[selectedLang].creditNonCurrent } 5%</div>
												</Col>
												<Col>
													<StrategyPaperFormControl
														inputChanged={ this.inputChanged }
														field={ "nonCurrent" }
														value={ this.props.strategyPaper.credits.nonCurrent }
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<div className="bold">{ localization[selectedLang].creditCurrent } 10%</div>
												</Col>
												<Col>
													<StrategyPaperFormControl
														inputChanged={ this.inputChanged }
														field={ "current" }
														value={ this.props.strategyPaper.credits.current }
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<div className="bold">{ localization[selectedLang].creditTakeUpRepayment }</div>
												</Col>
												<Col>
													<StrategyPaperFormControl
														inputChanged={ this.inputChanged }
														field={ "takeUpCredit" }
														value={ this.props.strategyPaper.credits.takeUpCredit }
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<div className="bold">{ localization[selectedLang].newCreditStatus }</div>
												</Col>
												<Col>
													<StrategyPaperFormControl
														inputChanged={ this.inputChanged }
														field={ "creditStatus" }
														value={ this.props.strategyPaper.credits.creditStatus }
													/>
												</Col>
											</Row>
										</Container>
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
			</Container>
		);
	}

}

const mapStateToProps = (state) => {
	return {
		session: state.session,
		strategyPaper: state.form.StrategyPaper
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(StrategyPaper);

//value={ this.props.strategyPaper.credits.nonCurrent }