import React, {Component} from "react";
import { connect } from "react-redux";


// Dispatcher
import mapDispatchToProps from "../../../../reducers/dispatchers";

// Components
import TokenDroppableField from "./TokenDroppableField";

class TokenDroppableContainer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit
		}
	}


	appendToken = (value, type) => {
		this.props.addToken(this.props.type, value, type);
	}

	removeToken = (tokenValue) => {
		this.props.removeToken(this.props.type, tokenValue)
	}

	render() {
		return (
			<TokenDroppableField
				canEdit={this.state.canEdit}
				type={this.props.type}
				value={this.props.value}
				appendToken={this.appendToken}
				removeToken={this.removeToken}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TokenDroppableContainer);