import React, {Component} from "react";
import { connect } from "react-redux";
import {Col, Container, Image, Row} from "react-bootstrap";
import Popup from "reactjs-popup";
import moment from 'moment-timezone';

// Dispatchers
import mapDispatchToProps from "../../reducers/dispatchers";

// Images
import logo from "../../assets/images/header-logo.png";
import zoomIn from "../../assets/images/zoom-in.png";
import zoomOut from "../../assets/images/zoom-out.png";
import chatIcon from "../../assets/images/chat-icon.png";
import logoutIcon from "../../assets/images/logout-icon.png";

// Components
import Properties from "../Properties";
import ChatComponent from "./ChatComponent";

class Header extends Component {

	constructor(props) {
		super(props);

		this.zoomInEvent.bind(this);
		this.zoomOutEvent.bind(this);

		this.counterTimer = {} /*{
			starting_at: moment.utc(Date()),
			name: "Guided Tour",
			time: 15
		};*/

		this.state = {
			showPopup: false,
			openChat: false,
			canEdit: props.canEdit,
			zoomLevel: 0,
			editTeamName: false,
			teamName: "Simflex",
			counterName: "Guided Tour",
			minutes: 15
		}
	}

	updateTeamName() {
		this.setState({
			editTeamName: false
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// To prevent multiple component updates
		if (this.props.properties.isFieldSelected && (this.props.properties.isFieldSelected !== prevProps.properties.isFieldSelected)) {
			this.openPanel()
		}
	}

	logout() {
		this.props.logoutAction();
	}


	zoomInEvent = () => {
		if (this.state.zoomLevel < 100) {
			let appContainer = document.getElementsByClassName("App");
			let kitContainer = document.getElementsByClassName("main-app-bg");

			appContainer[0].classList.remove(`zoom-${this.state.zoomLevel}`);
			kitContainer[0].classList.remove(`zoom-value-${this.state.zoomLevel}`)
			kitContainer[0].classList.remove(`zoom-${this.state.zoomLevel}`)

			this.setState({"zoomLevel": this.state.zoomLevel + 20}, () => {
				appContainer[0].classList.add(`zoom-${this.state.zoomLevel}`);
				kitContainer[0].classList.add(`zoom-value-${this.state.zoomLevel}`)
				kitContainer[0].classList.add(`zoom-${this.state.zoomLevel}`)
			});
		}
	}

	zoomOutEvent = () => {
		if (this.state.zoomLevel > 0) {
			let appContainer = document.getElementsByClassName("App");
			let kitContainer = document.getElementsByClassName("main-app-bg");

			appContainer[0].classList.remove(`zoom-${this.state.zoomLevel}`);
			kitContainer[0].classList.remove(`zoom-value-${this.state.zoomLevel}`)
			kitContainer[0].classList.remove(`zoom-${this.state.zoomLevel}`)

			this.setState({ "zoomLevel": this.state.zoomLevel - 20 }, () => {
				appContainer[0].classList.add(`zoom-${this.state.zoomLevel}`);
				kitContainer[0].classList.add(`zoom-value-${this.state.zoomLevel}`)
				kitContainer[0].classList.add(`zoom-${this.state.zoomLevel}`)
			});
		}
	}

	openPanel = () => {
		this.setState({ showPopup: true });
	}

	closePanel = () => {
		this.props.closeProperties();
		this.setState({ showPopup: false });
	}

	toggleChat() {
		console.log("HERE");
		this.setState({ openChat: !this.state.openChat }, () => {
			this.props.toggleChat(this.state.openChat)
		})
	}

	calculateCounter = () => {
		let startDate = moment.utc(this.counterTimer.starting_at);
		startDate.subtract(2, 'hours');
		let endDate = startDate.clone().add(parseInt(this.counterTimer.time), 'minutes');
		let currentDate = moment()

		if (currentDate.diff(startDate) > 0 && endDate.diff(currentDate) > 0) {
			const diffMinutes = endDate.diff(currentDate);
			this.setState({
				counterName: this.counterTimer.name,
				minutes: Math.ceil((diffMinutes / 1000) / 60)
			})
		}else{
			this.setState({
				counterName: this.counterTimer.name,
				minutes: 0
			})
			clearInterval(this.myInterval)
		}
	}

	updateEditTeamName = () => {
		if (parseInt(this.props.session.year) === 0) {
			this.setState({ editTeamName: true })
		}
	}

	changeLanguage = () => {
		if (this.props.session.selectedLang === "en") {
			this.props.changeLanguage("de");
		}else{
			this.props.changeLanguage("en");
		}
	}

	onGuidedTourNext = () => {
		this.props.nextGuidedTour();
	}
	onGuidedTourPrev = () => {
		this.props.prevGuidedTour();
	}

	render() {
		return (
			<>
				<div className="App-header">
					<div className="App-header-container">
						<div style={{ "width": "15%", "margin": "auto 0", "height": "36px" }}>
						</div>
						<div className="header-logo-container">
							<Image src={logo} className="App-logo" />
							{
								(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "logo") ?
									<div className="headerGuidedTourPopup">
										<div className="upArrow"></div>
										<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
										<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

										<div className="actions">
											<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
												Start
											</div>
										</div>
									</div>
								:
									null
							}
						</div>
						<div className="change-team-name-container">
							{
								(this.state.canEdit && this.state.editTeamName) ?
									<Container className="bg-blue form">
										<div className="team-data-content">
											<Row>
												<Col className="team-form-container">
													<input type="text" value={ this.state.teamName } onChange={e => this.setState({ teamName: e.target.value })} />
												</Col>
											</Row>
											<Row>
												<Col className="team-form-container">
													<div
														className="team-name-btn"
														onClick={ this.updateTeamName.bind(this) }
													>
														Save
													</div>
												</Col>
											</Row>
										</div>
									</Container>
									:
									<Container className="bg-blue">
										<div className="widget-content">
											<div className="header-section">
												Team
											</div>
											<div className="team-data-content">
												<Row className="cursorPointer" onClick={ this.updateEditTeamName.bind(this) }>
													<Col>
														<div className="team-name-text" >
															{ (this.props.session.teamName === "") ? this.state.teamName : this.props.session.teamName }
														</div>
													</Col>
												</Row>
											</div>
										</div>
									</Container>
							}
							{
								(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "teamName") ?
									<div className="headerGuidedTourPopup">
										<div className="upArrow"></div>
										<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
										<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

										<div className="actions">
											<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
												Prev
											</div>
											<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
												Next
											</div>
										</div>
									</div>
								:
									null
							}
						</div>
						<div className="player-name-container">
							<Container className="bg-green">
								<div className="widget-content">
									<div className="header-section">
										Player
									</div>
									<div className="team-data-content">
										<Row>
											<Col>
												<div className="player-name">
													Player 1
												</div>
											</Col>
										</Row>
									</div>
								</div>
							</Container>
							{
								(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "playerName") ?
									<div className="headerGuidedTourPopup">
										<div className="upArrow"></div>
										<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
										<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

										<div className="actions">
											<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
												Prev
											</div>
											<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
												Next
											</div>
										</div>
									</div>
								:
									null
							}
						</div>
						<div className="year-container">
							<Container className="bg-blue">
								<div className="widget-content">
									<div className="team-data-content">
										<Row>
											<Col style={{ display: "flex" }}>
												<div style={{ display: "flex", margin: "0 auto" }}>
													<div className="header-section">Year</div>
													<div className="year-value">{ this.props.session.year }</div>
												</div>
											</Col>
										</Row>
									</div>
								</div>
							</Container>
							{
								(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "boardYear") ?
									<div className="headerGuidedTourPopup">
										<div className="upArrow"></div>
										<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
										<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

										<div className="actions">
											<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
												Prev
											</div>
											<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
												Next
											</div>
										</div>
									</div>
								:
									null
							}
						</div>
						<div className="counter-container">
							{
								(this.state.counterName !== "") ?
									<Container className="bg-red">
										<div className="widget-content">
											<div className="header-section">
												{ this.state.counterName }
											</div>
											<div className="team-data-content">
												<Row>
													<Col>
														<div className="counter-value">{ this.state.minutes } min</div>
													</Col>
												</Row>
											</div>
										</div>
										<div className="header-separator-2">&nbsp;</div>
									</Container>
									:
									null
							}
							{
								(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "counter") ?
									<div className="headerGuidedTourPopup2">
										<div className="upArrow"></div>
										<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
										<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

										<div className="actions">
											<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
												Prev
											</div>
											<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
												Next
											</div>
										</div>
									</div>
								:
									null
							}
						</div>
						<div className="zoom-container">
							<Container>
								<Row>
									<Col>
										<div className="zoom-btn-container" onClick={ this.zoomInEvent } >
											<Image src={zoomIn} className="zoom-btn" />
										</div>
									</Col>
									<Col>
										<div className="zoom-btn-container" onClick={ this.zoomOutEvent } >
											<Image src={zoomOut} className="zoom-btn" />
										</div>
									</Col>
								</Row>
							</Container>
							{
								(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "zoomFeature") ?
									<div className="headerGuidedTourPopup">
										<div className="upArrow"></div>
										<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
										<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

										<div className="actions">
											<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
												Prev
											</div>
											<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
												Next
											</div>
										</div>
									</div>
								:
									null
							}
						</div>
						<div className="chat-icon-container">
							<div className="header-separator">&nbsp;</div>

							<div className="chat-container" onClick={this.toggleChat.bind(this)}>
								<Image src={chatIcon} />
								<div className="chat-title">Chat</div>
							</div>
							{
								(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "chatIcon") ?
									<div className="headerGuidedTourPopup">
										<div className="upArrow"></div>
										<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
										<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

										<div className="actions">
											<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
												Prev
											</div>
											<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
												Next
											</div>
										</div>
									</div>
								:
									null
							}
						</div>
						<div className="logout-btn-container">
							<div className="language-btn" onClick={ this.changeLanguage.bind(this) }>
								{ (this.props.session.selectedLang === "en") ? "German" : "English" }
							</div>
							<div className="logout-btn" onClick={ this.logout.bind(this) }>
								<Image src={ logoutIcon } />
							</div>
							{
								(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "language") ?
									<div className="guidedTourPopup2">
										<div className="upArrow"></div>
										<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
										<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

										<div className="actions">
											<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
												Prev
											</div>
											<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
												Next
											</div>
										</div>
									</div>
								:
									null
							}
						</div>
					</div>
				</div>
				{
					(this.state.showPopup)?
						<div className="field-properties-popup">
							<Popup
								open
								modal
								onClose={ this.closePanel }
							>
								<div className="popup-body">
									{
										(this.props.properties.isFieldSelected)?
											<Properties
												canEdit={this.state.canEdit}
												startingKit={this.props.startingKit}
												properties={this.props.properties}
												board={this.props.board}
												closePanel={this.closePanel}
											/>
											:
											null
									}
								</div>
							</Popup>
						</div>
						:
						null
				}
				{
					(this.state.openChat) ?
						<ChatComponent
							canEdit={this.state.canEdit}
							closeChat={ this.toggleChat.bind(this) }
						/>
						:
						null
				}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		board: state.board,
		properties: state.properties,
		startingKit: state.startingKit,
		session: state.session,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);