import React, { useState } from "react";
import { useDrop } from 'react-dnd';
import {Button, Image} from "react-bootstrap";
import Popup from "reactjs-popup";

// Constants
import colors from "../../../../utils/colors";

// Images
import verticalContainerImg from "../../../../assets/images/container-v.png";
import green from "../../../../assets/images/tiled-green-card.png";
import yellow from "../../../../assets/images/tiled-yellow-card.png";
import blue from "../../../../assets/images/tiled-blue-card.png";
import red from "../../../../assets/images/tiled-red-card.png";

export default function DroppableDirectCostField(props) {
	const [showUnitsPopup, setShowUnitsPopup] = useState(false);
	const [soldUnites, setSoldUnites] = useState(0);
	const [item, setItem] = useState(null);

	const [{ isOver, canDrop }, drop] = useDrop({
		accept: 'StockContainer',
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		drop: (item) => {
			setItem(item);
			setShowUnitsPopup(true);


			return item;
			/*let soldNumber = prompt("How many did you sell?", "0");
			if (soldNumber) {
				props.appendContainer(item.coins, item.hasCard, soldNumber, item.cardColor, item.cardValue, item.id);
			}*/

			//return undefined;
		},
	})

	const backgroundColor = (isOver && canDrop) ? colors.activeBackground : ""
	const canPlayerDrop = (props.canEdit) ? drop : null;

	return (
		<>
			<div className="sold-units-popup">
			{
				(showUnitsPopup)?
					<Popup
						open
						modal
						onClose={ () => setShowUnitsPopup(false) }
					>
						<>
							<div className="popup-header">
								Confirmation required!
							</div>
							<div className="popup-body">
								<div>How many units did you sell?</div>
								<input autoFocus type="text" id="units" name="units" onChange={e => setSoldUnites(parseInt(e.target.value))} />
								<Button size="sm" onClick={ (e) => {
									if (soldUnites <= parseInt(item.cardValue)) {
										props.appendContainer(item.coins, item.hasCard, soldUnites, item.cardColor, item.cardValue, item.id);
										setShowUnitsPopup(false);
									}
								}}>Confirm</Button>
							</div>
						</>
					</Popup>
					:
					null
			}
			</div>
			{
				(props.data.hasContainer)?
					<div className="Vertical-droppable-field">
						<div className="containerObjClass">
							<Image className="objModelClass" src={verticalContainerImg} />
							<div className="Coins-vertical-droppable">
								{
									(props.data.coins.map((value, index) => {
										let coinClass = (props.data.coins.length === (index + 1) && (index + 1) % 5 === 0) ? "last-coin " : "not-last-coin ";
										switch (value) {
											case 10:
												coinClass += "gold";
												break
											case 1:
												coinClass += "silver";
												break
											case 0.5:
												coinClass += "bronze";
												break
											default:
												break
										}

										return (
											<div key={index} className={ ["coin", coinClass].join(' ') }></div>
										)
									}))
								}
								{
									(props.data.hasCard && props.data.cardType === "label") ?
										<div className="containerProductCardDraggable">
											{(
												(props.data.cardColor === "green" &&
													<Image className="containerProductCardDraggableImg" src={ green } />)
												|| (props.data.cardColor === "yellow" &&
													<Image className="containerProductCardDraggableImg" src={ yellow } />)
												|| (props.data.cardColor === "blue" &&
													<Image className="containerProductCardDraggableImg" src={ blue } />)
												|| (props.data.cardColor === "red" &&
													<Image className="containerProductCardDraggableImg" src={ red } />)
											)}
											{
												(props.data.cardValue > 0) ?
													<div className="containerProductCardValue">{ props.data.cardValue }</div>
													:
													null
											}
										</div>
										:
										null
								}
							</div>
						</div>
					</div>
					:
					<div className="Vertical-droppable-field" style={{ backgroundColor: backgroundColor }} ref={canPlayerDrop}></div>
			}
		</>
	);
}