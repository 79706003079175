import React, {Component} from "react";

class LeftBlockSpacer extends Component {

	render() {
		return (
			<div className="Left-block-vertical-space"></div>
		);
	}
}

export default LeftBlockSpacer;