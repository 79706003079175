import React, {Component} from "react";

// Components
import SalesDroppablePin from "./SalesDroppablePin";

class SalesNumberBox extends Component {
	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit,
			number: props.number
		}
	}

	render() {
		return (
			<SalesDroppablePin
				canEdit={this.state.canEdit}
				id={this.props.id}
				number={this.state.number}
				hasPin={this.props.hasPin}
				title={this.props.title}
				appendPin={this.props.appendPin}
				removePin={this.props.removePin}
			/>
		);
	}
}

export default SalesNumberBox;