import React, { Component } from "react";
import { connect } from "react-redux";
import { Image } from "react-bootstrap";

// Images
import documentIcon from "../../assets/images/document-icon.png";
import checkListIcon from "../../assets/images/checklist-icon.png";

// Dispatchers
import mapDispatchToProps from "../../reducers/dispatchers";
import localization from "../../utils/localization";

// Components
import DecisionSheet from "./Forms/DecisionSheet";
import StrategyPaper from "./Forms/StrategyPaper";
import AnnualReport from "./Forms/AnnualReport";


class FilesLibrary extends Component{

	constructor(props) {
		super(props);

		this.state = {
			isMenuOpened: false,
			isCheckListOpened: false
		}
	}

	toggleFilesMenu = () => {
		if (this.state.isMenuOpened) {
			this.setState({ isMenuOpened: false });
			this.updateUI("FILES", "close");
		}else{
			this.setState({ isMenuOpened: true });
			this.setState({ isCheckListOpened: false });
			this.updateUI("FILES", "open");
		}
	}

	toggleCheckListMenu = () => {
		if (this.state.isCheckListOpened) {
			this.setState({ isCheckListOpened: false });
			this.updateUI("CHECKLIST", "close");
		}else{
			this.setState({ isCheckListOpened: true });
			this.setState({ isMenuOpened: false });
			this.updateUI("CHECKLIST", "open");
		}
	}

	updateUI = (panelType, status) => {
		const filesContainer = document.getElementsByClassName("filesContainer");
		const checkListContainer = document.getElementsByClassName("checkListContainer");

		if (status === "close") {
			filesContainer[0].classList.remove('fullWidth');
			checkListContainer[0].classList.remove('fullWidth');
		}else{
			if (panelType === "FILES") {
				filesContainer[0].classList.add('fullWidth');
				checkListContainer[0].classList.remove('fullWidth');
			}else if (panelType === "CHECKLIST") {
				checkListContainer[0].classList.add('fullWidth');
				filesContainer[0].classList.remove('fullWidth');
			}else{
				this.setState({ isMenuOpened: false, isCheckListOpened: false }, () => {
					filesContainer[0].classList.remove('fullWidth');
					checkListContainer[0].classList.remove('fullWidth');
				});
			}
		}
	}

	openForm = (form) => {
		this.props.openFormView(form)
	}

	onGuidedTourNext = () => {
		this.props.nextGuidedTour();
	}
	onGuidedTourPrev = () => {
		this.props.prevGuidedTour();
	}

	onFileLibraryGuidedTourNext = () => {
		this.props.nextGuidedTour();
		this.setState({ isMenuOpened: true });
		this.setState({ isCheckListOpened: false });
		this.updateUI("FILES", "open");
	}
	onGuidedTourNextDecisionSheet = () => {
		this.props.nextGuidedTour();
		this.openForm("DecisionSheet")
	}
	onStrategyPaperGuidedTourNext = () => {
		this.props.nextGuidedTour();
		
		this.setState({ isMenuOpened: false });
		this.setState({ isCheckListOpened: false });
		this.updateUI("FILES", "close");
	}

	render() {
		const selectedLang = this.props.session.selectedLang;

		let files = [];
		let links = [];
		this.props.session.files.forEach(obj => {
			if (obj.type === "file") {
				files.push(obj);
			}else{
				links.push(obj);
			}
		})

		return (
			<>
				<div className="files-library-btn">
					<div className="section" style={{ position: "relative" }}>
						<div style={{ width: "100%", height: "100%", display: "flex" }} onClick={ this.toggleCheckListMenu }>
							<div className="button checklist">
								<Image src={checkListIcon} />
								<div className="text">Check List</div>
							</div>
						</div>
						{
							(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "checklist") ?
								<div className="checklistGuidedTour">
									<div className="rightArrow"></div>
									<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
									<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

									<div className="actions">
										<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
											Prev
										</div>
										<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
											Next
										</div>
									</div>
								</div>
							:
								null
						}
					</div>
					<div className="section" style={{ position: "relative" }} >
						<div style={{ width: "100%", height: "100%", display: "flex" }} onClick={ this.toggleFilesMenu }>
							<div className="button">
								<Image src={documentIcon} />
								<div className="text">Files Library</div>
							</div>
						</div>
						{
							(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "fileLibrary") ?
								<div className="checklistGuidedTour">
									<div className="rightArrow"></div>
									<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
									<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

									<div className="actions">
										<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
											Prev
										</div>
										<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
											Next
										</div>
									</div>
								</div>
							:
								null
						}
						{
							(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "fileLibrary2") ?
								<div className="checklistGuidedTour">
									<div className="rightArrow"></div>
									<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
									<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

									<div className="actions">
										<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
											Prev
										</div>
										<div className="nextBtnContainer" onClick={ this.onFileLibraryGuidedTourNext.bind(this) }>
											Next
										</div>
									</div>
								</div>
							:
								null
						}
					</div>
				</div>
				<div className="checkListContainer">
					<div className="files-header">
						<div className="files-header-logo">
							<Image src={checkListIcon} />
							<div className="text">Check List</div>
						</div>
					</div>
					<div className="blur">
						{
							this.props.session.checkList[selectedLang].map((obj, index) => {
								return (
									<div className="checkListRowContainer" key={ index }>
										<div className="checkListTitle">
											{ obj.title }
										</div>
										{(
											obj.list.map((listObj, index2) => {
												return (
													<div className="checkListRow" key={ index2 }>
														{
															(listObj.drawRedLine) ?
																<div className="phaseSeparator">
																	<div className="phaseText">
																		<i className="arrow up"></i> Phase 1
																	</div>
																	<div className="phaseLineSeparator"></div>
																	<div className="phaseText">
																		<i className="arrow down"></i> Phase 2
																	</div>
																</div>
																:
																null
														}
														<div className="checkListSubtitle">{ listObj.title }</div>
														<div className="checkListValue">{ listObj.value }</div>
														<div>
															<ul>
																{(
																	listObj.actions.map((action, index3) => {
																		return (
																			<li key={index3}>{ action }</li>
																		)
																	})
																)}
															</ul>
														</div>
													</div>
												)
											})
										)}
									</div>
								);
							})
						}
					</div>
				</div>
				<div className="filesContainer">
					<div className="files-header">
						<div className="files-header-logo">
							<Image src={documentIcon} />
							<div className="text">Files Library</div>
						</div>
					</div>
					<div className="blur">
						{
							(this.props.session.openedForm !== "") ?
								<>
									{(
										(this.props.session.openedForm === "DecisionSheet" && <DecisionSheet />)
										|| 
										(this.props.session.openedForm === "StrategyPaper" && <StrategyPaper />)
										||
										(this.props.session.openedForm === "AnnualReport" && <AnnualReport />)
									)}
								</>
							:
								<>
								<div className="files-section">
									<div className="filesListTitle">
										Forms
									</div>
									<div className="fileRow" style={{ position: 'relative' }}>
										<div className="file-link form-link">
											<a onClick={ this.openForm.bind(this, "DecisionSheet")} rel="noopener noreferrer">{ localization[selectedLang].decisionSheet }</a>
										</div>
										{
											(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "decisionSheet") ?
												<div className="decisionSheetGuidedTour">
													<div className="upArrow"></div>
													{
														(this.props.guidedTour.steps[this.props.guidedTour.currentStep].title !== "") ?
															<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
															:
															null
													}
													<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

													<div className="actions">
														<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
															Prev
														</div>
														<div className="nextBtnContainer" onClick={ this.onGuidedTourNextDecisionSheet.bind(this) }>
															Next
														</div>
													</div>
												</div>
											:
												null
										}
									</div>
									<div className="fileRow" style={{ position: 'relative' }}>
										<div className="file-link form-link">
											<a onClick={ this.openForm.bind(this, "StrategyPaper")} rel="noopener noreferrer">{ localization[selectedLang].strategyPaper }</a>
										</div>
										{
											(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "strategyPaper") ?
												<div className="strategyPaperGuidedTour">
													<div className="upArrow"></div>
													<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
													<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

													<div className="actions">
														<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
															Prev
														</div>
														<div className="nextBtnContainer" onClick={ this.onStrategyPaperGuidedTourNext.bind(this) }>
															Next
														</div>
													</div>
												</div>
											:
												null
										}
									</div>
									<div className="fileRow">
										<div className="file-link form-link">
											<a onClick={ this.openForm.bind(this, "AnnualReport")} rel="noopener noreferrer">Annual Report</a>
										</div>
									</div>
								</div>
								{
									(files.length > 0) ?
										<div className="files-section">
											<div className="filesListTitle">
												Files
											</div>
											{
												files.map((obj, index) => {
													return (
														<div className="fileRow" key={ index }>
															<div className="file-link">
																<a href={ obj.file } target="_blank" rel="noopener noreferrer">{ obj.name }</a>
															</div>
														</div>
													);
												})
											}
										</div>
										:
										null
								}
								{
									(links.length > 0) ?
										<div className="links-section">
											<div className="linksListTitle">
												Links
											</div>
											{
												links.map((obj, index) => {
													return (
														<div className="fileRow" key={ index }>
															<div className="file-link">
																<a href={ obj.file } target="_blank" rel="noopener noreferrer">{ obj.name }</a>
															</div>
														</div>
													);
												})
											}
										</div>
										:
										null
								}
							</>
						}
					</div>
				</div>
			</>
		)
	}

}

const mapStateToProps = (state) => {
	return {
		session: state.session,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesLibrary);