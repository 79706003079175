import React from 'react';
import { Container, Row, Col, InputGroup, FormControl } from 'react-bootstrap';

import localization from "../../../../utils/localization";

class StrategyPaperProduction extends React.Component {

	optionChanged = (field, value) => {
		this.props.optionChanged("production", this.props.option, field, value)
	}

	render() {
		const selectedLang = this.props.selectedLang;

		let title = ""
		switch (this.props.index) {
			case 0:
				title= "1 National";
				break;
			case 1:
				title= "2 National";
				break;
			case 2:
				title= "3 National";
				break;
			case 3:
				title= "1 International";
				break;
			case 4:
				title= "2 International";
				break;
			case 5:
				title= "3 International";
				break;
			case 6:
				title= "1 Intercontinental 1";
				break;
			case 7:
				title= "2 Intercontinental 1";
				break;
			case 8:
				title= "1 Intercontinental 2";
				break;
			case 9:
				title= "2 Intercontinental 2";
				break;
			default:
				break;
		}
		return (
			<Container>
				<Row>
					<Col>
						<div className="bold">{ title }</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputGroup size="sm" className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text id="inputGroup-sizing-sm">{ localization[selectedLang].product }</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl 
								aria-label="Small" 
								aria-describedby="inputGroup-sizing-sm" 
								value={ this.props.field.product }
								onChange={ (e) => this.optionChanged("product", e.target.value) }
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputGroup size="sm" className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text id="inputGroup-sizing-sm">{ localization[selectedLang].units }</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl 
								aria-label="Small" 
								aria-describedby="inputGroup-sizing-sm" 
								value={ this.props.field.units }
								onChange={ (e) => this.optionChanged("units", e.target.value) }
							/>
						</InputGroup>
					</Col>
				</Row>
			</Container>
		);
	}

}

export default StrategyPaperProduction;