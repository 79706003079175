import React from "react";
import {useDrag, useDrop} from 'react-dnd';

// Constants
import colors from "../../../../utils/colors";

export default function TokenDroppableField(props) {
	const hasValue = props.value;

	const [{ isOver, canDrop }, drop] = useDrop({
		accept: [(props.type === "token_black") ? 'BlackToken' : 'Token', 'DroppedToken'],
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		drop: (item) => {
			if (item.type === "DroppedToken") {
				props.appendToken(item.value.value, "Token");
				return item;
			}else if (item.count >= item.value && (hasValue.type === "" || hasValue.type === item.type)) {
				props.appendToken(item.value, item.type);
				return item;
			}

			return {};
		},
	})

	const [, drag] = useDrag({
		item: {
			type: "DroppedToken",
			value: props.value
		},
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (dropResult, monitor) => {
			let doppableItem = monitor.getDropResult();
			if (monitor.didDrop()) {
				if (doppableItem !== null && doppableItem.type !== undefined) {
					props.removeToken(doppableItem.value.value)
				}
			}
		}
	});

	const backgroundColor = (isOver && canDrop) ? colors.activeBackground : "";
	const canPlayerDrop = (props.canEdit) ? drop : null;
	return (
		<>
			{
				(hasValue.value) ?
					<div className={ ["Token-droppable-field", props.type].join(' ') } style={{ backgroundColor: backgroundColor }} ref={canPlayerDrop}>
						{
							(hasValue.type === "Token") ?
								<div className="Token-container" ref={drag}>
									<div className="Token-value">{hasValue.value}</div>
									<div className="Token-text">
										Kredit liability credito
									</div>
								</div>
								:
								<>
									{
										(hasValue.type === "BlackToken") ?
											<div className="Token-container black">
												<div className="Token-value">{hasValue.value}</div>
												<div className="Token-text">
													venture capital
												</div>
											</div>
											:
											null
									}
								</>
						}
					</div>
					:
					<div className={ ["Token-droppable-field", props.type].join(' ') } style={{ backgroundColor: backgroundColor }} ref={canPlayerDrop}></div>
			}
		</>
	);
}
