export default {
	en: {
		decisionSheet: "Decision Sheet",
		market: "Market",
		products: "Products",
		national: "National",
		international: "International",
		intercontinental1: "Intercontenintal 1",
		intercontinental2: "Intercontenintal 2",
		standard: "Standard",
		compact: "Compact",
		plus: "Plus",
		luxus: "Luxus",
		price: "Price",
		marketing: "Marketing",
		units: "Units",
		strategyPaper: "Strategy Paper",
		developmentOfProducts: "Development of products",
		year: "year",
		years: 'years',
		costs: 'costs',
		openingUpOfMarkets: "Opening-up of markets",
		fixedAssets: "Fixed assets",
		machines: "Machines",
		purchase: "Purchase",
		rent: "Rent",
		prodFacilities: "Prod. facilities",
		productionInProdFacility: "Production in Prod. facility",
		product: "Product",
		quantity: "Quantity",
		units: "Units",
		creditTakeUp: "Credit take-up",
		creditNonCurrent: "Credit take up",
		creditCurrent: "Credit take up",
		creditTakeUpRepayment: "Credit repayment",
		newCreditStatus: "New credit status",
		salesRevenue: "Sales revenue",
		directCosts: "Direct costs",
		grossProfit: "Gross profit",
		indirectRDCosts: "Indirect R&D costs",
		depreciationOfCapitalizedDevelopmentCosts: "Depreciation of capitalized development costs",
		depreciationOfProductionPlants: "Depreciation of production plants",
		depreciationOfMachines: "Depreciation of machines",
		indirectProductionCosts: "Indirect production costs",
		storageCosts: "Storage costs",
		indirectSalesCosts: "Indirect sales costs",
		processOptimization: "Process optimization",
		qualityAssurance: "Quality assurance",
		lease: "Lease",
		otherOverheads: "Other overheads",
		totalOverhead: "Total overheads",
		operationProfit: "Operating profit / EBIT",
		otherIncomes: "Other incomes",
		interest: "Interest",
		profitBeforeTax: "Profit before tax",
		lossCarriedForward: "Loss carried forward",
		taxes: "Taxes",
		profitAfterTax: "Profit after tax",
		assets: "Assets",
		capitalizedDevelopmentCosts: "Capitalized development costs",
		productionFacilities: "Production facilities",
		finishedProducts: "Finished products",
		inventories: "Inventories",
		cashAndCashEquivalents: "Cash and cash equivalents",
		total: "Total",
		liabilities: "Liabilities",
		capital: "Capital/Equity",
		liabilitiesNonCurrent: "Liabilities non-current",
		liabilitiesCurrent: "Liabilities current",
		cashLevelAtStartOfYear: "Cash level at start of year",
		materialsPurchase: "Materials purchase",
		labour: "Labour",
		purchaseOfProductionFacilities: "Purchase of production facilities",
		purchaseOfMachines: "Purchase of machines",
		totalIncomes: "Total Incomes",
		productDevelopmentCost: "Product development cost",
		totalExpenditures: "Total expenditures",
		netCashFlow: "Net Cash Flow",
		cashLevelAtYearEnd: "Cash level at year end",
		creditRepayment: "Credit take-ups/repayments",
	},
	de: {
		decisionSheet: "Entscheidungsblatt",
		market: "Märkte",
		products: "Produkte",
		national: "National",
		international: "International",
		intercontinental1: "Intercontinental 1",
		intercontinental2: "Intercontinental 2",
		standard: "Standard",
		compact: "Compact",
		plus: "Plus",
		luxus: "Luxus",
		price: "Preis",
		marketing: "Marketing",
		units: "Serien",
		strategyPaper: "Strategiepapier",
		developmentOfProducts: "Entwicklung Produkte",
		year: "Jahr",
		years: 'Jahre',
		costs: 'Kosten',
		openingUpOfMarkets: "Erschließung Absatzmarkte",
		fixedAssets: "Anlagevermögen",
		machines: "Maschinen",
		purchase: "Kaufen",
		rent: "Mieten",
		prodFacilities: "Produktionsstätten",
		productionInProdFacility: "Produktion in Produktionsstätte",
		product: "Produkt",
		quantity: "Anzahl",
		units: "Serien",
		creditTakeUp: "Kreditaufnahme",
		creditNonCurrent: "Kreditstand langfristig",
		creditCurrent: "Kreditstand kurzfristig",
		creditTakeUpRepayment: "Kreditaufnahme / rüchzahlung",
		newCreditStatus: "Neuer Kreditstand",
		salesRevenue: "Umsatz",
		directCosts: "Einzelkosten",
		grossProfit: "Ergebnisbeitrag",
		indirectRDCosts: "Gemeinkosten F&E",
		depreciationOfCapitalizedDevelopmentCosts: "Abschreibung auf aktivierte Entwicklungskosten",
		depreciationOfProductionPlants: "Abschreibungen auf Produktionsstätten",
		depreciationOfMachines: "Abschreibungen auf Maschinen",
		indirectProductionCosts: "Gemeinkosten Produktion",
		storageCosts: "Lagerkosten",
		indirectSalesCosts: "Gemeinkosten Vertrieb",
		processOptimization: "Prozessoptimierung",
		qualityAssurance: "Qualitätssicherung",
		lease: "Miete",
		otherOverheads: "sonstige Gemeinkosten",
		totalOverhead: "Summe Gemeinkosten",
		operationProfit: "operatives Ergebnis",
		otherIncomes: "sonstige Erträge",
		interest: "Zinsen",
		profitBeforeTax: "Gewinn vor Steuern",
		lossCarriedForward: "Verlustvortrag",
		taxes: "Steuern",
		profitAfterTax: "Jahresüberschuss",
		assets: "Aktiva",
		capitalizedDevelopmentCosts: "aktivierte Entwicklungskosten",
		productionFacilities: "Produktionsstätten",
		finishedProducts: "Fertig Erzeugnisse",
		inventories: "Vorräte",
		cashAndCashEquivalents: "Kasse",
		total: "Summe",
		liabilities: "Passiva",
		capital: "Eigenkapital",
		liabilitiesNonCurrent: "Fremdkapital Langfristig",
		liabilitiesCurrent: "Fremdkapital Kurzfristig",
		cashLevelAtStartOfYear: "Kassenbestand Jahresbeginn",
		materialsPurchase: "Materialkauf",
		labour: "Löhne",
		purchaseOfProductionFacilities: "Kauf von Produktionsstätten",
		purchaseOfMachines: "Kauf von Maschinen",
		totalIncomes: "Summe Einnahmen",
		productDevelopmentCost: "Kosten für Produktentwicklung",
		totalExpenditures: "Summe Ausgaben",
		netCashFlow: "Net Cash Flow",
		cashLevelAtYearEnd: "Kassenbestand Jahresende",
		creditRepayment: "Kreditaufnahmen/-rückzahlungen",
	}
}