import React  from "react";
import { useDrop } from 'react-dnd';

// Constants
import colors from "../../../../utils/colors";

// Components
import DraggablePin from "../../Common/DraggablePin";

export default function SalesDroppablePin(props) {

	const [{ isOver, canDrop }, drop] = useDrop({
		accept: 'Pin',
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		drop: (item) => {
			if (parseInt(item.count) > 0) {
				props.appendPin(props.title, props.speed, props.id);
			}else if (item.count === undefined) {
				props.appendPin(props.title, props.speed, props.id);
			}

			return item;
		},
	})

	const backgroundColor = (isOver && canDrop) ? colors.activeBackground : "";
	const canPlayerDrop = (props.canEdit) ? drop : null;
	return (
		<>
			{
				(props.hasPin)?
					<div className="Sales-number-box" >
						<div className="Sales-number-height">
							<span className="notranslate">
								{ (props.number !== 0) ? props.number : " " }
							</span>
						</div>
						<div className="Sales-dot-container">
							<div className="Sales-dot-class"></div>
							<DraggablePin
								canEdit={props.canEdit}
								id={props.id}
								title={props.title}
								speed={props.speed}
								removePin={props.removePin}
							/>
						</div>
					</div>
					:
					<div className="Sales-number-box" style={{ backgroundColor: backgroundColor }} ref={canPlayerDrop} >
						<div className="Sales-number-height">
							<span className="notranslate">
								{ (props.number !== 0) ? props.number : " " }
							</span>
						</div>
						<div className="Sales-dot-container">
							<div className="Sales-dot-class"></div>
						</div>
					</div>
			}
		</>

	);
}