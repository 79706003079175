import React from 'react';
import { Table, FormControl } from 'react-bootstrap';

import localization from "../../../../utils/localization";

class BalanceSheet extends React.Component {

	handleChange = (field, value) => {
		this.props.inputChanged("balanceSheet", field, value)
	}

	render() {
		const selectedLang = this.props.selectedLang;

		let totalAssets = 0;
		totalAssets += parseFloat(this.props.balanceSheet.capitalizedDevelopmentCosts)
		totalAssets += parseFloat(this.props.balanceSheet.productionFacilities)
		totalAssets += parseFloat(this.props.balanceSheet.machines)
		totalAssets += parseFloat(this.props.balanceSheet.finishedProducts)
		totalAssets += parseFloat(this.props.balanceSheet.inventories)
		totalAssets += parseFloat(this.props.balanceSheet.cash)

		let totalLiabilities = 0;
		totalLiabilities += parseFloat(this.props.balanceSheet.equity)
		totalLiabilities += parseFloat(this.props.balanceSheet.liabilitiesNonCurrent)
		totalLiabilities += parseFloat(this.props.balanceSheet.liabilitiesCurrent)
		
		return (
			<Table bordered>
				<thead>
					<tr>
						<th colSpan="2"><div className="bold">{ localization[selectedLang].assets }</div></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].capitalizedDevelopmentCosts }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.capitalizedDevelopmentCosts }
								onChange={ (e) => this.handleChange("capitalizedDevelopmentCosts", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].productionFacilities }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.productionFacilities }
								onChange={ (e) => this.handleChange("productionFacilities", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].machines }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.machines }
								onChange={ (e) => this.handleChange("machines", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">Finished products</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.finishedProducts }
								onChange={ (e) => this.handleChange("finishedProducts", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].inventories }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.inventories }
								onChange={ (e) => this.handleChange("inventories", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].cashAndCashEquivalents }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.cash }
								onChange={ (e) => this.handleChange("cash", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].total }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ totalAssets }
								disabled
							/>
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
				</tbody>
				<thead>
					<tr>
						<th colSpan="2"><div className="bold">{ localization[selectedLang].liabilities }</div></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].capital }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.equity }
								onChange={ (e) => this.handleChange("equity", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].liabilitiesNonCurrent }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.liabilitiesNonCurrent }
								onChange={ (e) => this.handleChange("liabilitiesNonCurrent", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].liabilitiesCurrent }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.balanceSheet.liabilitiesCurrent }
								onChange={ (e) => this.handleChange("liabilitiesCurrent", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].total }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ totalLiabilities }
								disabled
							/>
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}

}

export default BalanceSheet;