import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";

// Constants
import dropFieldClassNames from "../../../../utils/dropFieldClassNames";

// Dispatcher
import mapDispatchToProps from "../../../../reducers/dispatchers";

// Components
import NumberBox from "../../Common/NumberBox";
import DroppableField from "../../Common/DroppableField";


class PurchaseRow extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit,
			className: props.className,
			title: props.title
		}
	}

	appendContainer = (coins, id) => {
		this.props.addContainerFieldType('Purchase', this.state.title.toLowerCase(), coins, id, false, 0, "", window.playerId, window.playerName)
	}

	removeContainer = (id) => {
		this.props.removeContainerFieldType('Purchase', this.state.title.toLowerCase(), id)
	}

	addCoin = (id, value) => {
		this.props.addCoinFieldType("Purchase", this.state.title.toLowerCase(), value, id, window.playerId, window.playerName);
	}

	removeCoin = (id, index) => {
		this.props.removeCoinFieldType("Purchase", this.state.title.toLowerCase(), index, id, window.playerId, window.playerName);
	}

	showContainerProperties = (id) => {
		this.props.openContainerPropertiesWithTitle('FIELD', 'Purchase', this.state.title.toLowerCase(), id);
	}

	renderNumber(title) {
		switch(title) {
			case "Standard-row":
				return (
					<NumberBox number={2} />
				)
			case "Compact-row":
				return (
					<NumberBox number={1} />
				)
			case "Plus-row":
				return (
					<NumberBox number={3} />
				)
			case "Luxus-row":
				return (
					<NumberBox number={4} />
				)
			default:
				break;
		}
	}

	onGuidedTourNext = () => {
		this.props.nextGuidedTour();
	}

	onGuidedTourPrev = () => {
		this.props.prevGuidedTour();
	}

	render() {
		let coinsValue = 0;
		const hasContainer = this.props.purchase.standard[0].hasContainer;
		if (hasContainer) {
			const containerCoins = this.props.purchase.standard[0].coins;
			if (containerCoins.length > 0) {
				coinsValue = containerCoins.reduce((total, num) => {
					return parseFloat(total) + parseFloat(num);
				})
			}
		}

		return (
			<Row className={`Purchase-row ${ this.state.className }`}>
				<Col className="Block-center">
					{ this.renderNumber(this.state.className) }
				</Col>
				<Col xs={4} style={{ position: 'relative' }}>
					<div onClick={this.showContainerProperties.bind(this, 0)} style={{ width: "100%", height: "100%" }}>
						<DroppableField
							canEdit={this.state.canEdit}
							id={0}
							class={ dropFieldClassNames.Purchase }
							data={ this.props.purchase[this.state.title.toLowerCase()][0] }
							isHorizontal={ true }
							appendContainer={ this.appendContainer }
							removeContainer={ this.removeContainer }
							addCoin={ this.addCoin }
							removeCoin={ this.removeCoin }
						/>
					</div>
					{
						(this.state.title.toLowerCase() === "standard" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "purchasing") ?
							<div className="purchaseTourPopup">
								<div className="upArrow"></div>
								<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
								<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

								<div className="actions">
									<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
										Prev
									</div>
									{
										(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "container-coins" && hasContainer && coinsValue === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value) ?
											<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
												Next
											</div>
										:
											null
									}
								</div>
							</div>
						:
							null
					}
					{
						(this.state.title.toLowerCase() === "standard" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "product2") ?
							<div className="purchaseTourPopup">
								<div className="upArrow"></div>
								<p>This is the material you just purchased</p>
							</div>
						:
							null
					}
					{
						(this.state.title.toLowerCase() === "standard" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "material") ?
							<div className="purchaseTourPopup">
								<div className="upArrow"></div>
								<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
								<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

								<div className="actions">
									<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
										Prev
									</div>
									{
										(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "container-coins" && hasContainer && coinsValue === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value) ?
											<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
												Next
											</div>
										:
											null
									}
								</div>
							</div>
						:
							null
					}
				</Col>
				<Col xs={4} onClick={this.showContainerProperties.bind(this, 1)}>
					<DroppableField
						canEdit={this.state.canEdit}
						id={1}
						class={ dropFieldClassNames.Purchase }
						data={ this.props.purchase[this.state.title.toLowerCase()][1] }
						isHorizontal={ true }
						appendContainer={ this.appendContainer }
						removeContainer={ this.removeContainer }
						addCoin={ this.addCoin }
						removeCoin={ this.removeCoin }
					/>
				</Col>
			</Row>
		);
	}

}

const mapStateToProps = (state) => {
	return {
		purchase: state.board.CenterBlock.Purchase,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseRow);