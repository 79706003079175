import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";

// Components
import SalesBlock from "./SalesBlock/SalesBlock";
import StockGreenBlock from "./StockBlock/StockGreenBlock";
import StockYellowBlock from "./StockBlock/StockYellowBlock";
import StockRedBlock from "./StockBlock/StockRedBlock";
import StockBlueBlock from "./StockBlock/StockBlueBlock";


class SalesStockBlock extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit
		}
	}


	render() {
		return (
			<div className="Sales-Stock-block">
				<Row>
					<Col xs={1}>
						<div className="Sales-title bold-text">VERTRIEB</div>
						<div className="Sales-title">SALES</div>
						<div className="Sales-title">VENTAS</div>
					</Col>
					<Col>
						<SalesBlock canEdit={this.state.canEdit} />
					</Col>
					<Col xs={1} style={{ "marginLeft": "5px" }}>
						<div className="Sales-title bold-text">LAGER</div>
						<div className="Sales-title">STOCK</div>
						<div className="Sales-title">ALMACÉN</div>
					</Col>
					<Col xs={4} style={{ "marginRight": "5px" }}>
						<StockGreenBlock canEdit={this.state.canEdit} data={this.props.colorBlocks.green} />
						<StockYellowBlock canEdit={this.state.canEdit} data={this.props.colorBlocks.yellow} />
					</Col>
					<Col xs={4}>
						<StockBlueBlock canEdit={this.state.canEdit} data={this.props.colorBlocks.blue} />
						<StockRedBlock canEdit={this.state.canEdit} data={this.props.colorBlocks.red} />
					</Col>
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		colorBlocks: state.board.CenterBlock.Stock
	}
}

export default connect(mapStateToProps)(SalesStockBlock);
