import React, { Component } from "react";
import { connect } from "react-redux";
import {Col, Container, Image, Row} from "react-bootstrap";

// Images
import kitsIcon from "../../assets/images/kits-icon.png";

// Dispatchers
import mapDispatchToProps from "../../reducers/dispatchers";

// Constants
import coins from "../../utils/coins";

// Components
import MainDraggableContainer from "./StarterKit/MainDraggableContainer";
import MainDraggableToken from "./StarterKit/MainDraggableToken";
import MainDraggableBlackToken from "./StarterKit/MainDraggableBlackToken";
import MainDraggablePin from  "./StarterKit/MainDraggablePin";
import MainDraggableCoin from "./StarterKit/MainDraggableCoin";
import MainDraggableFactory from "./StarterKit/MainDraggableFactory";
import MainDraggableMachine from "./StarterKit/MainDraggableMachine";
import MainDraggableProductCard from "./StarterKit/MainDraggableProductCard";
import MainDroppableDelete from "./StarterKit/MainDroppableDelete";


class KitsAndObjects extends Component{

	constructor(props) {
		super(props);

		this.productionLblColors = {"STANDARD":"green", "COMPACT":"yellow", "PLUS":"blue", "LUXUS":"red"}

		this.state = {
			canEdit: props.canEdit,
			isMenuOpened: false
		}
	}

	toggleMenu = () => {
		if (this.state.isMenuOpened) {
			this.setState({ isMenuOpened: false });
			this.updateUI("MENU", "close");
		}else{
			this.setState({ isMenuOpened: true });
			this.updateUI("MENU", "open");
		}
	}

	updateUI = (panelType, status) => {
		const appContainer = document.getElementsByClassName("App");
		const menuContainer = document.getElementsByClassName("kitContainer");

		if (status === "close") {
			appContainer[0].classList.remove('moveLeft');
			menuContainer[0].classList.remove('fullWidth');
		}else{
			appContainer[0].classList.add('moveLeft');

			if (panelType === "MENU") {
				menuContainer[0].classList.add('fullWidth');
			}else{
				this.setState({ isMenuOpened: false }, () => {
					menuContainer[0].classList.remove('fullWidth');
				});
			}
		}
	}

	decreaseStartKitPin = () => {
		this.props.decreaseStartKitPin();
	}

	increaseStartKitPin = (count) => {
		this.props.increaseStartKitPin(count);
	}

	decreaseStartKitCoin = (value) => {
		this.props.decreaseStartKitCoin(value);
	}

	increaseStartKitCoin = (value) => {
		this.props.increaseStartKitCoin(value);
	}

	decreaseStartKitToken = (value) => {
		this.props.decreaseStartKitToken(value);
	}

	increaseStartKitToken = (value) => {
		this.props.increaseStartKitToken(value);
	}

	decreaseStartKitBlackToken = (value) => {
		this.props.decreaseStartKitBlackToken(value);
	}

	increaseStartKitBlackToken = (value) => {
		this.props.increaseStartKitBlackToken(value);
	}

	decreaseStartKitFactory = (value) => {
		this.props.decreaseStartKitFactory(value);
	}

	increaseStartKitFactory = (value) => {
		this.props.increaseStartKitFactory(value);
	}

	decreaseStartKitMachine = (value) => {
		this.props.decreaseStartKitMachine(value);
	}

	increaseStartKitMachine = (value) => {
		this.props.increaseStartKitMachine(value);
	}

	increaseStartKitProduction = (value, color) => {
		this.props.increaseStartKitProductCard(value, color);
	}

	decreaseStartKitProductCard = (value, color) => {
		this.props.decreaseStartKitProductCard(value, color);
	}

	onGuidedTourNext = () => {
		this.props.nextGuidedTour();

		this.setState({ isMenuOpened: true });
		this.updateUI("MENU", "open");
	}

	onGuidedTourPrev = () => {
		this.props.prevGuidedTour();
	}

	render() {
		let lbls = [];
		let count = 0;
		if (this.props.startingKit.production !== undefined && Object.keys(this.props.startingKit.production).length > 0) {
			Object.keys(this.props.startingKit.production).forEach((value, index) => {
				Object.keys(this.props.startingKit.production[value]).forEach((value2, index2) => {
					if(this.props.startingKit.production[value][value2] > 0) {
						lbls.push(
							<React.Fragment key={ count }>
								{
									(count % 2 === 0) ?
										<div className="w-100"></div>
										:
										null
								}
								<Col>
									<MainDraggableProductCard
										canEdit={this.state.canEdit}
										color={this.productionLblColors[value2]}
										value={value}
										decreaseStartKitProductCard={this.decreaseStartKitProductCard}
									/>
								</Col>
							</React.Fragment>
						)
						count++;
					}
				})
			})
		}

		return (
			<>
			<div className="kits-objects-btn">
				<div style={{ width: "100%", height: "100%", display: "flex" }} onClick={ this.toggleMenu }>
					<div className="button">
						<Image src={kitsIcon} />
						<div className="text">Team Table</div>
					</div>
				</div>
				{
					(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "teamTable") ?
						<div className="teamTableTourPopup">
							<div className="leftArrow"></div>
							<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
							<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

							<div className="actions">
								<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
									Prev
								</div>
								<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
									Next
								</div>
							</div>
						</div>
					:
						null
				}
				{
					(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "teamTable-2") ?
						<div className="teamTableTourPopup">
							<div className="leftArrow"></div>
							<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
							<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

							<div className="actions">
								<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
									Next
								</div>
							</div>
						</div>
					:
						null
				}
			</div>
			<div className="kitContainer">
				<div className="kits-header">
					<div className="kits-header-logo">
						<Image src={kitsIcon} />
						<div className="text">Team Table</div>
					</div>
				</div>
				<div className="blur">
					<div className="object-container-div">
						<div className="container-text">
							Containers
						</div>
						<MainDraggableContainer
							canEdit={this.state.canEdit}
						/>
					</div>
					<div className="kits-separator"></div>
					{
						(this.props.startingKit.coins > 0) ?
							<>
								<div className="object-coins-div">
									<div className="container-text">
										Cash
										<div className="object-value">
											<span className="notranslate">
												{ this.props.startingKit.coins }
											</span>
										</div>
									</div>
									<Container>
										<Row>
											{(
												coins.map((value, index) =>
													<Col key={index}>
														<MainDraggableCoin
															canEdit={this.state.canEdit}
															decreaseStartKitCoin={this.decreaseStartKitCoin}
															value={value}
															count={this.props.startingKit.coins}
														/>
													</Col>
												)
											)}
										</Row>
									</Container>
								</div>
								<div className="kits-separator"></div>
							</>
							:
							null
					}
					<div className="object-labels-div">
						<div className="container-text">
							Labels
						</div>
						<Container>
							<Row>
								<Col>
									<MainDraggableFactory
										canEdit={this.state.canEdit}
										decreaseStartKitFactory={this.decreaseStartKitFactory}
										count={this.props.startingKit.factory}
									/>
								</Col>
								<Col>
									<MainDraggableMachine
										canEdit={this.state.canEdit}
										decreaseStartKitMachine={this.decreaseStartKitMachine}
										count={this.props.startingKit.machine}
									/>
								</Col>
								<div className="w-100"></div>
								<Col>
									<div className="object-value-center">
										<span className="notranslate">
											{ this.props.startingKit.factory }
										</span>
									</div>
								</Col>
								<Col>
									<div className="object-value-center">
										<span className="notranslate">
											{ this.props.startingKit.machine }
										</span>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
					<div className="kits-separator"></div>
					{
						(this.props.startingKit.pins > 0) ?
							<>
								<div className="object-pins-div">
									<div className="container-text">
										Pins
										<div className="object-value">
											<span className="notranslate">
												{this.props.startingKit.pins}
											</span>
										</div>
									</div>
									<div className="draggable-pins-container">
										<Container>
											<Row>
												{
													Array.apply(null, { length: this.props.startingKit.pins }).map((e, i) => (
														<React.Fragment key={i}>
															{
																(i % 6 === 0) ?
																	<div className="w-100"></div>
																	:
																	null
															}
															<MainDraggablePin
																key={i}
																canEdit={this.state.canEdit}
																count={this.props.startingKit.pins}
																decreaseStartKitPin={this.decreaseStartKitPin}
															/>
														</React.Fragment>
													))
												}
											</Row>
										</Container>
									</div>
								</div>
								<div className="kits-separator"></div>
							</>
							:
							null
					}
					{
						(this.props.startingKit.tokens > 0 || this.props.startingKit.blackTokens) ?
							<>
								<div className="object-tokens-div">
									<div className="container-text">
										Liability tokens
									</div>
									<Container>
										<Row>
											{
												(this.props.startingKit.tokens > 0) ?
													<Col>
														<MainDraggableToken
															canEdit={this.state.canEdit}
															decreaseStartKitToken={this.decreaseStartKitToken}
															count={ this.props.startingKit.tokens }
															value={ this.props.startingKit.tokens }
														/>
													</Col>
													:
													null
											}
											{
												(this.props.startingKit.blackTokens > 0) ?
													<Col>
														<MainDraggableBlackToken
															canEdit={this.state.canEdit}
															decreaseStartKitBlackToken={this.decreaseStartKitBlackToken}
															count={ this.props.startingKit.blackTokens }
															value={ this.props.startingKit.blackTokens }
														/>
													</Col>
													:
													null
											}
										</Row>
									</Container>
								</div>
								<div className="kits-separator"></div>
							</>
							:
							null
					}
					{
						(lbls.length > 0) ?
							<>
								<div className="object-pins-div">
									<div className="container-text">
										Production units
									</div>
									<div style={{ "display": "flex" }}>
										<div style={{ "width": "65%", "margin": "0 auto" }}>
											<Container>
												<Row>
													{ lbls }
												</Row>
											</Container>
										</div>
									</div>
								</div>
								<div className="kits-separator"></div>
							</>
							:
							null
					}
					<div className="object-delete-div">
						<div className="container-text">
							Delete
						</div>
						<MainDroppableDelete
							increaseStartKitPin={this.increaseStartKitPin}
							increaseStartKitCoin={this.increaseStartKitCoin}
							increaseStartKitFactory={this.increaseStartKitFactory}
							increaseStartKitMachine={this.increaseStartKitMachine}
							increaseStartKitProduction={this.increaseStartKitProduction}
							increaseStartKitToken={this.increaseStartKitToken}
						/>
						<div className="clearfix"></div>
					</div>
					<div className="kits-separator"></div>
					<div style={{ height: "30px" }}></div>
				</div>
			</div>
			</>
		)
	}

}

const mapStateToProps = (state) => {
	return {
		startingKit: state.startingKit,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(KitsAndObjects);