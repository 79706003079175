import React from "react";
import {Image} from "react-bootstrap";
import {DragPreviewImage, useDrag} from 'react-dnd';

// Constants
import items from "../../../utils/items";

// Images
import boxImg from "../../../assets/images/draggable-container.png";
import previewImg from "../../../assets/images/draggable-container-preview.png";


export default function MainDraggableContainer(props) {
	const [, drag, preview] = useDrag({
		item: { type: items.container, coins: [] }
	});

	const canDrag = (props.canEdit) ? drag : null
	return (
		<>
			<DragPreviewImage className="dragging" connect={preview} src={previewImg} />
			<div className="containerDraggable" ref={ canDrag }>
				<Image src={boxImg} />
			</div>
		</>
	);
}