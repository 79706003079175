import React, {Component} from "react";
import { connect } from "react-redux";
import {Row, Col, Image} from 'react-bootstrap';

// Dispatcher
import mapDispatchToProps from "../../../reducers/dispatchers";

// CSS
import '../../../css/SimFlex/RightBlock.css';

// Images
import rightArrow from "../../../assets/images/right-arrow.png";

// Components
import BottomRightBlockRowField from "./BottomRightBlockRowField";

class BottomRightBlock extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit,
			germanTitle: props.germanTitle,
			englishTitle: props.englishTitle,
			spanishTitle: props.spanishTitle
		}
	}

	appendContainer = (id, coins, hasCard, cardValue, cardColor, oldCardValue, stockId) => {

		let directCostCoins = []
		let stockCoins = []

		const diffCardValue = oldCardValue - cardValue;
		if (diffCardValue > 0) {

			if (coins.length > 0) {
				let totalCoins = coins.reduce((total, num) => {
					return parseFloat(total) + parseFloat(num);
				})

				const productValue = parseInt(totalCoins) / parseInt(oldCardValue);
				let directCostValue = productValue * cardValue;

				while (directCostValue > 0) {
					if (directCostValue >= 10) {
						directCostCoins.push(10)
						directCostValue -= 10
					}else if(directCostValue >= 1) {
						directCostCoins.push(1)
						directCostValue -= 1
					}else{
						directCostCoins.push(0.5)
						directCostValue -= 0.5
					}
				}

				let salesCostValue = productValue * diffCardValue;
				while (salesCostValue > 0) {
					if (salesCostValue >= 10) {
						stockCoins.push(10)
						salesCostValue -= 10
					}else if(salesCostValue >= 1) {
						stockCoins.push(1)
						salesCostValue -= 1
					}else{
						stockCoins.push(0.5)
						salesCostValue -= 0.5
					}
				}

				console.log(directCostCoins);
				console.log(stockCoins);

				this.props.addContainerDirectCost("DirectCost", id, directCostCoins, hasCard, cardValue, cardColor, window.playerId, window.playerName);
				this.props.addContainerColorField('ColorField', cardColor, stockId, stockCoins, hasCard, diffCardValue, cardColor, window.playerId, window.playerName);
			}

		}else {
			directCostCoins = coins
			this.props.addContainerDirectCost("DirectCost", id, directCostCoins, hasCard, cardValue, cardColor, window.playerId, window.playerName);
			this.props.removeContainerColorField('ColorField', cardColor, stockId);
		}

	}

	removeContainer = (id) => {
		this.props.removeContainer("DirectCost", id);
	}

	showContainerProperties = (id) => {
		this.props.openContainerProperties('FIELD', 'DirectCost', id);
	}

	render() {
		return (
			<div className="Right-block left-right-block bottom">
				<Row>
					<Col>
						<div className="Text-bold-size">{ this.state.germanTitle }</div>
					</Col>
					<Col>
						<div className="Text-size">{ this.state.englishTitle }</div>
					</Col>
					<Col>
						<div className="Text-size">{ this.state.spanishTitle }</div>
					</Col>
				</Row>
				<Row>
					{
						this.props.directCost.map((value, key) => {
							return (
								<React.Fragment key={key}>
									{
										(key % 3 === 0) ?
											<div className="w-100"></div>
											:
											null
									}
									<BottomRightBlockRowField
										canEdit={this.state.canEdit}
										id={key}
										object={value}
										appendContainer={this.appendContainer}
										removeContainer={this.removeContainer}
										showContainerProperties={this.showContainerProperties}
									/>
								</React.Fragment>
							)
						})
					}
				</Row>
				<Image src={rightArrow} className="Arrow-class" style={{"top": "30%"}} />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		directCost: state.board.DirectCost
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BottomRightBlock);