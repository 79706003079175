import React, {Component} from "react";
import { connect } from "react-redux";
import {Col, Container, Row} from "react-bootstrap";

// Dispatcher
import mapDispatchToProps from "../../../../reducers/dispatchers";

import ColoredDroppableHorizontalField from "./Fields/ColoredDroppableHorizontalField";

class StockGreenBlock extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit
		}
	}

	onGuidedTourNext = () => {
		this.props.nextGuidedTour();
	}
	onGuidedTourPrev = () => {
		this.props.prevGuidedTour();
	}

	render() {

		const fieldsList = this.props.data;
		console.log(fieldsList)
		const droppableFieldsList = this.props.data.map((value, key) => {
			if (key % 4 === 0) {
				return <React.Fragment key={key}>
					<div className="w-100"></div>
					<Col><ColoredDroppableHorizontalField canEdit={this.state.canEdit} id={key} type="green" fieldObject={value} /></Col>
				</React.Fragment>
			}

			return <Col key={key}><ColoredDroppableHorizontalField canEdit={this.state.canEdit} id={key} type="green" fieldObject={value} /></Col>
		})

		return (
			<Container className="Stock-green-block colored-block">
				<Row style={{ position: 'relative' }}>
					{ droppableFieldsList }
					{
						(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "greenBlock") ?
							<div className="productionTourPopup">
								<div className="upArrow"></div>
								<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
								<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

								<div className="actions">
									<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
										Prev
									</div>
									<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
										Next
									</div>
								</div>
							</div>
						:
							null
					}
				</Row>
			</Container>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(StockGreenBlock);

/*
	<Col className="bottom">
		<ColoredDroppableHorizontalField />
	</Col>
	<Col className="bottom">
		<ColoredDroppableHorizontalField />
	</Col>
	<Col className="bottom">
		<ColoredDroppableHorizontalField />
	</Col>
	<Col className="bottom">
		<ColoredDroppableHorizontalField />
	</Col>
</Row>
<Row>
	<Col><ColoredDroppableHorizontalField /></Col>
	<Col><ColoredDroppableHorizontalField /></Col>
	<Col><ColoredDroppableHorizontalField /></Col>
	<Col><ColoredDroppableHorizontalField /></Col>
 */