import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Image, Row} from "react-bootstrap";

// Constants
import dropFieldClassNames from "../../../../utils/dropFieldClassNames";

// Dispatcher
import mapDispatchToProps from "../../../../reducers/dispatchers";

// Images
import downArrow from "../../../../assets/images/down-arrow.png";
import upArrow from "../../../../assets/images/up-arrow.png";

// Components
import DroppableField from "../../Common/DroppableField";


class BottomBlock extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit,
			id: props.id,
			germanTitle: props.germanTitle,
			englishTitle: props.englishTitle,
			spanishTitle: props.spanishTitle
		}
	}

	appendContainer = (coins) => {
		this.props.addContainer("BottomBlock", this.state.id, coins, window.playerId, window.playerName);
	}

	removeContainer = () => {
		this.props.removeContainer("BottomBlock", this.state.id);
	}

	addCoin = (value) => {
		this.props.addCoin("BottomBlock", this.state.id, value, window.playerId, window.playerName);
	}

	removeCoin = (index) => {
		this.props.removeCoin("BottomBlock", this.state.id, index, window.playerId, window.playerName);
	}

	showContainerProperties = () => {
		this.props.openContainerProperties('FIELD', 'BottomBlock', this.state.id);
	}

	render() {
		return (
			<div className="Remove-padding">
				<Row>
					<Col xs={8}>
						<div className="Bottom-block">
							<Row>
								<div className="text-column">
									<p className="Text-bold Text-size">{ this.state.germanTitle }</p>
									<p className="Text-size">{ this.state.englishTitle }</p>
									<p className="Text-size">{ this.state.spanishTitle }</p>
								</div>
								<div className="field-column" onClick={this.showContainerProperties}>
									<DroppableField
										canEdit={this.state.canEdit}
										class={ dropFieldClassNames.BottomBlock }
										data={ this.props.bottomBlock[this.state.id] }
										isHorizontal={ true }
										appendContainer={ this.appendContainer }
										removeContainer={ this.removeContainer }
										addCoin={ this.addCoin }
										removeCoin={ this.removeCoin }
									/>
								</div>
							</Row>
							{
								(this.state.id === 0) ?
									<Image src={ upArrow } className="Arrow-class" />
									:
									<Image src={ downArrow } className="Arrow-class" />
							}
						</div>
					</Col>
					<Col xs={4}></Col>
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		bottomBlock: state.board.BottomBlock
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BottomBlock);