import React from 'react';
import {connect} from "react-redux";
import { Container, Row, Col, Table } from 'react-bootstrap';

// Dispatcher
import mapDispatchToProps from "../../../reducers/dispatchers";

import "../../../css/Forms.css";

import localization from "../../../utils/localization";

import DecisionSheetFields from "./Partials/DecisionSheetFields";

class DecisionSheet extends React.Component {

	closeForm = () => {
		this.props.openFormView("")
	}

	inputChanged = (section, field, option, value) => {
		this.props.updateFormOption("DecisionSheet", section, field, option, value)
	}

	onGuidedTourNext = () => {
		this.props.nextGuidedTour();
	}

	render() {
		const selectedLang = this.props.session.selectedLang;
		return (
			<Container className="formContainer" style={{ position: 'relative'}}>
				<Row>
					<Col>
						<div className="formHeader">
							<div className="headerTitle bold">{ localization[selectedLang].decisionSheet }</div>
							<div className="closeForm" onClick={ this.closeForm.bind(this) }>X</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table bordered>
							<thead>
								<tr>
									<th style={{ width: "120px" }}>
										<div className="bold">{ localization[selectedLang].market }</div>
									</th>
									<th>
										<div className="bold">{ localization[selectedLang].products }</div>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<div className="bold">{ localization[selectedLang].national }</div>
									</td>
									<td>
										<DecisionSheetFields
											section="national"
											inputChanged={this.inputChanged}
											field={ this.props.decisionSheet.national }
											selectedLang={ selectedLang }
										/>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold">{ localization[selectedLang].international }</div>
									</td>
									<td>
										<DecisionSheetFields 
											section="international"
											inputChanged={this.inputChanged}
											field={ this.props.decisionSheet.international }
											selectedLang={ selectedLang }
										/>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold">{ localization[selectedLang].intercontinental1 }</div>
									</td>
									<td>
										<DecisionSheetFields 
											section="intercontinental1"
											inputChanged={this.inputChanged}
											field={ this.props.decisionSheet.international }
											selectedLang={ selectedLang }
										/>
									</td>
								</tr>
								<tr>
									<td>
										<div className="bold">{ localization[selectedLang].intercontinental2 }</div>
									</td>
									<td>
										<DecisionSheetFields 
											section="intercontinental2"
											inputChanged={this.inputChanged}
											field={ this.props.decisionSheet.international }
											selectedLang={ selectedLang }
										/>
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
				{
					(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "decisionSheet2") ?
						<div className="decisionSheetGuidedTour2">
							<div className="upArrow"></div>
							<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
							<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

							<div className="actions">
								<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
									Next
								</div>
							</div>
						</div>
					:
						null
				}
			</Container>
		);
	}

}

const mapStateToProps = (state) => {
	return {
		session: state.session,
		decisionSheet: state.form.DecisionSheet,
		guidedTour: state.guidedTour
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(DecisionSheet);