import React, {Component} from "react";
import { connect } from "react-redux";
import {Col, Row} from "react-bootstrap";

// Dispatcher
import mapDispatchToProps from "../../../../reducers/dispatchers";

// Components
import ProductionBlockSection from "./ProductionBlockSection";

class ProductionBlock extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit
		}
	}

	onGuidedTourNext = () => {
		this.props.nextGuidedTour();
	}
	onGuidedTourPrev = () => {
		this.props.prevGuidedTour();
	}

	render() {
		return (
			<div className="Production-container">
				<Row>
					<Col>
						<div className="Block-title-bold-text Block-title-text-size Same-line">PRODUKTION</div>
						<div className="Block-title-text-size Same-line">PRODUCTION</div>
						<div className="Block-title-text-size Text-italic Same-line">PRODUCCIÓN</div>
					</Col>
				</Row>
				<Row>
					<div className="Production-column" style={{ position: "relative" }}>
						<ProductionBlockSection canEdit={this.state.canEdit} id={0} title="National" />
						{
							(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "production") ?
								<div className="productionTourPopup">
									<div className="upArrow"></div>
									<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
									<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

									<div className="actions">
										<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
											Prev
										</div>
										<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
											Next
										</div>
									</div>
								</div>
							:
								null
						}
					</div>
					<div className="Production-column">
						<ProductionBlockSection canEdit={this.state.canEdit} id={1} title="National" />
					</div>
					<div className="Production-column" style={{ position: 'relative' }}>
						<ProductionBlockSection canEdit={this.state.canEdit} id={2} title="National" />
						{
							(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "center") ?
								<div className="productionTourPopup">
									<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
									<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

									<div className="actions">
										<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
											Prev
										</div>
										<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
											Finish
										</div>
									</div>
								</div>
							:
								null
						}
					</div>
					<div className="Production-column">
						<ProductionBlockSection canEdit={this.state.canEdit} id={3} title="International" />
					</div>
					<div className="Production-column">
						<ProductionBlockSection canEdit={this.state.canEdit} id={4} title="International" />
					</div>
				</Row>
				<Row>
					<div className="Production-column">
						<ProductionBlockSection canEdit={this.state.canEdit} id={5} title="International" />
					</div>
					<div className="Production-column">
						<ProductionBlockSection canEdit={this.state.canEdit} id={6} title="Inter-continental 1" />
					</div>
					<div className="Production-column">
						<ProductionBlockSection canEdit={this.state.canEdit} id={7} title="Inter-continental 1" />
					</div>
					<div className="Production-column">
						<ProductionBlockSection canEdit={this.state.canEdit} id={8} title="Inter-continental 2" />
					</div>
					<div className="Production-column">
						<ProductionBlockSection canEdit={this.state.canEdit} id={9} title="Inter-continental 2" />
					</div>
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductionBlock);