import React from "react";
import { useDrag, DragPreviewImage } from 'react-dnd';
import {Image} from "react-bootstrap";

// Constants
import items from "../../../utils/items";

// Images
import machine from "../../../assets/images/machine-card.png";
import previewImg from "../../../assets/images/machine-card-preview.png";


export default function MainDraggableMachine(props) {
	const [, drag, preview] = useDrag({
		item: { type: items.machineLbl, count: props.count },
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (dropResult, monitor) => {
			let droppedItem = monitor.getDropResult();
			if (droppedItem !== null && droppedItem.type !== undefined) {
				if (monitor.didDrop() && dropResult.count > 0) {
					props.decreaseStartKitMachine(1);
				}
			}
		}
	});

	const canDrag = (props.canEdit) ? drag : null
	return (
		<>
			<DragPreviewImage connect={preview} src={previewImg} />
			<div className="labelDraggable" ref={canDrag}>
				<Image src={machine} />
			</div>
		</>
	);
}