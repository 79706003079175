import React from 'react';
import {connect} from "react-redux";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

import "../../../css/Forms.css";

// Dispatcher
import mapDispatchToProps from "../../../reducers/dispatchers";

// Components
import IncomeStatement from "./Partials/IncomeStatement";
import BalanceSheet from "./Partials/BalanceSheet";
import CashFlow from "./Partials/CashFlow";

class AnnualReport extends React.Component {

	closeForm = () => {
		this.props.openFormView("")
	}

	inputChanged = (section, field, value) => {
		this.props.updateFormOption("AnnualReport", section, field, undefined, value)
	}

	render() {
		const selectedLang = this.props.session.selectedLang;

		return (
			<Container className="formContainer">
				<Row>
					<Col>
						<div className="formHeader">
							<div className="headerTitle bold">Annual Report</div>
							<div className="closeForm" onClick={ this.closeForm.bind(this) }>X</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<Tabs defaultActiveKey="incomeStatement" id="uncontrolled-tab-example">
							<Tab eventKey="incomeStatement" title={ `P&L` }>
								<IncomeStatement 
									inputChanged={ this.inputChanged }
									incomeStatement={ this.props.annualReport.incomeStatement }
									selectedLang={ selectedLang }
								/>
							</Tab>
							<Tab eventKey="balanceSheet" title="Balance Sheet">
								<BalanceSheet
									inputChanged={ this.inputChanged }
									balanceSheet={ this.props.annualReport.balanceSheet }
									selectedLang={ selectedLang }
								/>
							</Tab>
							<Tab eventKey="cashFlow" title="Cash Flow">
								<CashFlow
									inputChanged={ this.inputChanged }
									cashFlow={ this.props.annualReport.cashFlow }
									selectedLang={ selectedLang }
								/>
							</Tab>
						</Tabs>
					</Col>
				</Row>
			</Container>
		);
	}

}

const mapStateToProps = (state) => {
	return {
		session: state.session,
		annualReport: state.form.AnnualReport
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(AnnualReport);