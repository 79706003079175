const startingKit = {
	developmentOfProducts: {
		compact: false,
		plus: false,
		luxus: false
	},
	openingMarket: {
		international: false,
		intercontinental1: false,
		intercontinental2: false
	},
	fixedAssets: {
		national_1: {
			factory: "", //Purchase / Rent
			machine: "" // f/m/s
		},
		national_2: {
			factory: "", //Purchase / Rent
			machine: "" // f/m/s
		},
		national_3: {
			factory: "", //Purchase / Rent
			machine: "" // f/m/s
		},
		international_1: {
			factory: "", //Purchase / Rent
			machine: "" // f/m/s
		},
		international_2: {
			factory: "", //Purchase / Rent
			machine: "" // f/m/s
		},
		international_3: {
			factory: "", //Purchase / Rent
			machine: "" // f/m/s
		},
		intercontinental1_1: {
			factory: "", //Purchase / Rent
			machine: "" // f/m/s
		},
		intercontinental1_2: {
			factory: "", //Purchase / Rent
			machine: "" // f/m/s
		},
		intercontinental2_1: {
			factory: "", //Purchase / Rent
			machine: "" // f/m/s
		},
		intercontinental2_2: {
			factory: "", //Purchase / Rent
			machine: "" // f/m/s
		}
	},
	production: {
		national_1: {
			product: "",
			units: ""
		},
		national_2: {
			product: "",
			units: ""
		},
		national_3: {
			product: "",
			units: ""
		},
		international_1: {
			product: "",
			units: ""
		},
		international_2: {
			product: "",
			units: ""
		},
		international_3: {
			product: "",
			units: ""
		},
		intercontinental1_1: {
			product: "",
			units: ""
		},
		intercontinental1_2: {
			product: "",
			units: ""
		},
		intercontinental2_1: {
			product: "",
			units: ""
		},
		intercontinental2_2: {
			product: "",
			units: ""
		}
	},
	credits: {
		nonCurrent: "",
		current: "",
		takeUpCredit: "",
		creditStatus: ""
	}
}

export default startingKit;