import moment from 'moment-timezone';

const sessionState = {
	year: 0,
	teamName: "",
	timer: {},
	files: [],
	isChatOpened: false,
	messages: [],
	unreadCount: 0,
	checkList: {},
	openedForm: "",
	selectedLang: "en"
}

export default sessionState;