import React, { Component } from "react";
import {connect} from "react-redux";
import {Row, Col, Container, Button} from "react-bootstrap";

// Dispatchers
import mapDispatchToProps from "../reducers/dispatchers";

class TokenProperties extends Component{

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit,
			showAddTokenQuestion: false,
			showSubtractTokenQuestion: false,
			addTokenValue: 0,
			subtractTokenValue: 0
		}
	}

	addTokenValue = (totalTokens) => {
		if (totalTokens >= this.state.addTokenValue) {
			let droppedTokenType = this.props.board.CenterBlock.Finance[this.props.properties.options.tokenType].type;
			if (droppedTokenType === "") {
				if (this.props.properties.options.tokenType === "token_black") {
					droppedTokenType = "BlackToken"
				} else {
					droppedTokenType = "Token"
				}
			}

			this.props.addToken(this.props.properties.options.tokenType, this.state.addTokenValue, droppedTokenType)

			if (this.props.properties.options.tokenType === "token_black") {
				this.props.decreaseStartKitBlackToken(this.state.addTokenValue);
			}else{
				this.props.decreaseStartKitToken(this.state.addTokenValue);
			}

			this.props.closePanel();
		}
	}

	subtractTokenValue = (tokenValue) => {
		if (tokenValue >= this.state.subtractTokenValue) {
			const droppedTokenType = this.props.board.CenterBlock.Finance[this.props.properties.options.tokenType].type;

			this.props.removeToken(this.props.properties.options.tokenType, this.state.subtractTokenValue)
			if (this.props.properties.options.tokenType === "token_black") {
				if (tokenValue > this.state.subtractTokenValue)
					this.props.increaseStartKitBlackToken(this.state.subtractTokenValue);
				else
					this.props.increaseStartKitBlackToken(tokenValue);
			}else{
				if (tokenValue > this.state.subtractTokenValue)
					this.props.increaseStartKitToken(this.state.subtractTokenValue);
				else
					this.props.increaseStartKitToken(tokenValue);
			}

			this.props.closePanel();
		}
	}

	addTokensBtn = () => {
		this.setState({ showAddTokenQuestion: true, showSubtractTokenQuestion: false })
	}

	subtractTokensBtn = () => {
		this.setState({ showAddTokenQuestion: false, showSubtractTokenQuestion: true })
	}

	render() {
		const properties = this.props.properties;
		const boardData = this.props.board;

		const tokenType = boardData.CenterBlock.Finance[properties.options.tokenType].type;
		const tokenValue = boardData.CenterBlock.Finance[properties.options.tokenType].value;

		let totalTokens = 0;
		if (tokenType === "BlackToken") {
			totalTokens = this.props.startingKit.blackTokens;
		}else{
			totalTokens = this.props.startingKit.tokens;
		}

		return (
			<>
				<div className="Token-droppable-field">
					{
						(tokenType === "Token") ?
							<div className="Token-container">
								<div className="Token-value">{tokenValue}</div>
								<div className="Token-text">
									Kredit liability credito
								</div>
							</div>
							:
							<>
							{
								(tokenType === "BlackToken") ?
									<div className="Token-container black">
										<div className="Token-value">{tokenValue}</div>
										<div className="Token-text">
											venture capital
										</div>
									</div>
									:
									null
							}
							</>
					}
				</div>
				{
					(this.state.canEdit) ?
						<div className="container-actions">
							<Container style={{ marginTop: "5px" }}>
								<Row>
									<Col xs={1}></Col>
									<Col>
										<Button
											className="purchase-btn"
											onClick={ this.addTokensBtn }
											size="sm"
										>
											Add
										</Button>
									</Col>
									<Col xs={2}></Col>
									<Col>
										<Button
											className="rent-btn"
											onClick={ this.subtractTokensBtn }
											size="sm"
										>
											Subtract
										</Button>
									</Col>
									<Col xs={1}></Col>
								</Row>
								<Row>
									<Col className="questions-container">
										{
											(this.state.showAddTokenQuestion) ?
												<>
													<div className="question-text">How much cash do you want to add?</div>
													<div className="question-input">
														<input
															type="text"
															autoFocus
															id="units"
															pattern="^-?[0-9]\d*\.?\d*$"
															name="units"
															onKeyPress={
																(event) => {
																	if (event.key === "Enter") {
																		this.addTokenValue(totalTokens)
																	}
																}
															}
															onChange={e => this.setState({ addTokenValue: parseInt(e.target.value) })}
														/>
													</div>
													<div className="question-btn">
														<Button size="sm" onClick={ this.addTokenValue.bind(this, totalTokens) }>Confirm</Button>
													</div>
												</>
												:
												null
										}
										{
											(this.state.showSubtractTokenQuestion) ?
												<>
													<div className="question-text">How much cash do you want to subtract?</div>
													<div className="question-input">
														<input
															type="text"
															autoFocus
															id="units2"
															pattern="^-?[0-9]\d*\.?\d*$"
															name="units2"
															onKeyPress={
																(event) => {
																	if (event.key === "Enter") {
																		this.subtractTokenValue(tokenValue)
																	}
																}
															}
															onChange={e => this.setState({ subtractTokenValue: parseInt(e.target.value) })}
														/>
													</div>
													<div className="question-btn">
														<Button size="sm" onClick={ this.subtractTokenValue.bind(this, tokenValue) }>Confirm</Button>
													</div>
												</>
												:
												null
										}
									</Col>
								</Row>
							</Container>
						</div>
						:
						null
				}
			</>
		);
	}

}

const mapStateToProps = (state) => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TokenProperties);