import React, {Component} from "react";
import { connect } from "react-redux";
import {Row} from "react-bootstrap";

// Constants
import dropFieldClassNames from "../../../../utils/dropFieldClassNames";

// Dispatchers
import mapDispatchToProps from "../../../../reducers/dispatchers";

// Components
import NumberBox from "../../Common/NumberBox";
import DroppableField from "../../Common/DroppableField";


class ResearchRow extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit,
			className: props.className,
			title: props.title
		}
	}

	appendContainer = (coins) => {
		this.props.addContainerFieldType("Research", this.state.title.toLowerCase(), coins, 0, false, 0, "", window.playerId, window.playerName)
	}

	removeContainer = () => {
		this.props.removeContainerFieldType("Research", this.state.title.toLowerCase())
	}

	addCoin = (value) => {
		this.props.addCoinFieldType("Research", this.state.title.toLowerCase(), value, 0, window.playerId, window.playerName);
	}

	removeCoin = (index) => {
		this.props.removeCoinFieldType("Research", this.state.title.toLowerCase(), index, 0, window.playerId, window.playerName);
	}

	showContainerProperties = () => {
		this.props.openContainerPropertiesWithTitle('FIELD', 'Research', this.state.title.toLowerCase());
	}

	renderNumber(title) {
		switch(title) {
			case "STANDARD":
				return (
					<div className="number Number-box-container">
						<NumberBox number={0} />
					</div>
				)
			case "COMPACT":
				return (
					<div className="number Number-box-container">
						<NumberBox number={3} />
						<NumberBox number={3} />
					</div>
				)
			case "PLUS":
				return (
					<div className="number Number-box-container">
						<NumberBox number={3} />
						<NumberBox number={3} />
						<NumberBox number={2} />
					</div>
				)
			case "LUXUS":
				return (
					<div className="number Number-box-container">
						<NumberBox number={2} />
						<NumberBox number={2} />
						<NumberBox number={2} />
						<NumberBox number={2} />
						<NumberBox number={2} />
					</div>
				)
			default:
				break;
		}
	}

	onGuidedTourNext = () => {
		this.props.nextGuidedTour();
	}

	onGuidedTourPrev = () => {
		this.props.prevGuidedTour();
	}

	render() {
		let coinsValue = 0;
		const hasContainer = this.props.research.standard.hasContainer;
		if (hasContainer) {
			const containerCoins = this.props.research.standard.coins;
			if (containerCoins.length > 0) {
				coinsValue = containerCoins.reduce((total, num) => {
					return parseFloat(total) + parseFloat(num);
				})
			}
		}
		return (
			<Row className={`Research-row ${ this.state.className }`}>
				<div className="title Block-center">
					<div>{ this.state.title }</div>
				</div>
				{ this.renderNumber(this.state.title) }
				<div className="field" style={{ position: 'relative' }}>
					<div className="droppable-field" onClick={this.showContainerProperties} style={{ position: 'relative', width: "100%", height: "100%" }}>
						<DroppableField
							canEdit={this.state.canEdit}
							class={ dropFieldClassNames.Research }
							data={ this.props.research[this.state.title.toLowerCase()] }
							isHorizontal={ true }
							appendContainer={ this.appendContainer }
							removeContainer={ this.removeContainer }
							addCoin={ this.addCoin }
							removeCoin={ this.removeCoin }
						/>
					</div>
					{
						(this.state.title.toLowerCase() === "standard" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "RAndD") ?
							<div className="purchaseTourPopup">
								<div className="upArrow"></div>
								<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
								<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

								<div className="actions">
									<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
										Prev
									</div>
									{
										(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "container-subtract" && hasContainer && (this.props.startingKit.coins === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value || coinsValue === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value)) ?
											<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
												Next
											</div>
										:
											null
									}
									{
										(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "coins" && hasContainer && coinsValue === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value) ?
											<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
												Next
											</div>
										:
											null
									}
								</div>
							</div>
						:
							null
					}
				</div>
			</Row>
		);
	}

}

const mapStateToProps = (state) => {
	return {
		startingKit: state.startingKit,
		research: state.board.CenterBlock.Research,
		guidedTour: state.guidedTour
	}
}

/*const mapDispatchToProps = (dispatch) => {
	return {
		addContainer: (alias, researchType, coins) => dispatch({type: 'ADD_CONTAINER', alias: alias, researchType: researchType, coins: coins}),
		removeContainer: (alias, researchType) => dispatch({type: 'REMOVE_CONTAINER', alias: alias, researchType: researchType}),
		addCoin: (alias, researchType, coinValue) => dispatch({type: 'ADD_COIN', alias: alias, researchType: researchType, coinValue: coinValue}),
		removeCoin: (alias, researchType, index) => dispatch({type: 'REMOVE_COIN', alias: alias, researchType: researchType, coinIndex: index}),
		openContainerProperties: (alias, fieldName, title) => dispatch({type: "PROPERTIES", alias: alias, options: { fieldName: fieldName, title: title }})
	}
}*/

export default connect(mapStateToProps, mapDispatchToProps)(ResearchRow);