import React, {Component} from "react";
import { connect } from "react-redux";
import {Col} from "react-bootstrap";

// Dispatcher
import mapDispatchToProps from "../../../reducers/dispatchers";

// Components
import DroppableDirectCostField from "./Fields/DroppableDirectCostField";

class BottomRightBlockRowField extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit
		}
	}

	appendContainer = (coins, hasCard, newCardValue, cardColor, oldCardValue, id) => {
		this.props.appendContainer(this.props.id, coins, hasCard, newCardValue, cardColor, oldCardValue, id);
	}

	removeContainer = () => {
		this.props.removeContainer(this.props.id);
	}

	showContainerProperties = () => {
		this.props.showContainerProperties(this.props.id);
	}

	onGuidedTourNext = () => {
		this.props.increaseStartKitCoin(30);
		this.props.nextGuidedTour();
	}
	onGuidedTourPrev = () => {
		this.props.prevGuidedTour();
	}

	render() {
		let coinsValue = 0
		this.props.directCost.forEach( obj => {
			if (obj.coins.length > 0) {
				coinsValue += obj.coins.reduce((total, num) => {
					return parseFloat(total) + parseFloat(num);
				})
			}
		})
		/*this.props.object.coins.forEach( coin => {
			coinsValue += coin
		})*/

		return (
			<>
			<Col className="Droppable-container-margin" onClick={this.showContainerProperties.bind(this)}>
				<DroppableDirectCostField
					canEdit={this.state.canEdit}
					data={this.props.object}
					appendContainer={this.appendContainer}
					removeContainer={this.removeContainer}
				/>
			</Col>
			{
				(this.props.id === 0 && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "sellProducts") ?
					<div className="rightBlockTourPopup2">
						<div className="upArrow"></div>
						<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
						<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

						<div className="actions">
							<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
								Prev
							</div>
							{
								(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "coins" && coinsValue === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value) ?
									<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
										Next
									</div>
									:
									null
							}
						</div>
					</div>
				:
					null
			}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		directCost: state.board.DirectCost,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BottomRightBlockRowField);