import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Image } from 'react-bootstrap';

// Constants
import dropFieldClassNames from "../../../utils/dropFieldClassNames";

// Dispatcher
import mapDispatchToProps from "../../../reducers/dispatchers";

// Images
import leftArrow from '../../../assets/images/left-arrow.png';

// Components
import DroppableField from "../Common/DroppableField";


class LeftBlock extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit,
			id: props.id,
			germanTitle: props.germanTitle,
			englishTitle: props.englishTitle,
			spanishTitle: props.spanishTitle
		}
	}

	appendContainer = (coins) => {
		this.props.addContainer("LeftBlock", this.state.id, coins, window.playerId, window.playerName);
	}

	removeContainer = () => {
		this.props.removeContainer("LeftBlock", this.state.id);
	}

	addCoin = (value) => {
		this.props.addCoin("LeftBlock", this.state.id, value, window.playerId, window.playerName);
	}

	removeCoin = (index) => {
		this.props.removeCoin("LeftBlock", this.state.id, index, window.playerId, window.playerName);
	}

	showContainerProperties = () => {
		this.props.openContainerProperties('FIELD', 'LeftBlock', this.state.id);
	}

	onGuidedTourNext = () => {
		this.props.nextGuidedTour();
	}
	onGuidedTourPrev = () => {
		this.props.prevGuidedTour();
	}

	render() {

		let coinsValue = 0
		this.props.leftBlock[this.state.id].coins.forEach( coin => {
			coinsValue += coin
		})

		return (
			<Container className="left-right-block Left-block">
				<Row>
					<div className="field-column" onClick={this.showContainerProperties}>
						<DroppableField
							canEdit={this.state.canEdit}
							class={ dropFieldClassNames.LeftBlock }
							data={ this.props.leftBlock[this.state.id] }
							isHorizontal={ false }
							appendContainer={ this.appendContainer }
							removeContainer={ this.removeContainer }
							addCoin={ this.addCoin }
							removeCoin={ this.removeCoin }
						/>
					</div>
					<div className="text-column">
						<p className="Text-bold-size">{ this.state.germanTitle }</p>
						<p className="Text-size">{ this.state.englishTitle }</p>
						<p className="Text-size">{ this.state.spanishTitle }</p>
					</div>

					{
						(this.state.id === 4 && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "qualityAssurance") ?
							<div className="pinTourPopup">
								<div className="upArrow"></div>
								<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
								<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

								<div className="actions">
									<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
										Prev
									</div>
									{
										(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "coins" && coinsValue === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value) ?
											<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
												Next
											</div>
											:
											null
									}
								</div>
							</div>
						:
							null
					}
					{
						(this.state.id === 1 && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "interest") ?
							<div className="leftBlockTourPopup">
								<div className="upArrow"></div>
								<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
								<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

								<div className="actions">
									<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
										Prev
									</div>
									{
										(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "coins" && coinsValue === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value) ?
											<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
												Next
											</div>
											:
											null
									}
								</div>
							</div>
						:
							null
					}
				</Row>
				<Image src={ leftArrow } className="Arrow-class" />
			</Container>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		leftBlock: state.board.LeftBlock,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftBlock);