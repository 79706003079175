import React, {Component} from "react";
import {Container} from "react-bootstrap";
import {connect} from "react-redux";

// Dispatcher
import mapDispatchToProps from "../../../../reducers/dispatchers";

// Components
import SalesRow from "./SalesRow";

class SalesBlock extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit
		}
	}

	appendPin = (title, speed, id) => {
		this.props.addPin("Sales", title, speed, id)
	}

	removePin = (title, speed, id) => {
		this.props.removePin("Sales", title, speed, id)
	}

	render() {
		const salesObj = this.props.sales;

		return (
			<Container>
				{
					Object.keys(salesObj).map( (key, index) =>
						<SalesRow
							canEdit={this.state.canEdit}
							key={index}
							title={key}
							object={salesObj[key]}
							appendPin={this.appendPin}
							removePin={this.removePin}
						/>
					)
				}
			</Container>
		);
	}

}

const mapStateToProps = (state) => {
	return {
		sales: state.board.CenterBlock.Sales
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(SalesBlock);