export default {
	container: "Container",
	factoryContainer: "FactoryContainer",
	machineContainer: "MachineContainer",
	productionContainer: "ProductionContainer",
	coin: "Coin",
	machineLbl: "MachineLabel",
	factoryLbl: "FactoryLabel",
	productLbl: "ProductLabel",
	pin: "Pin"
}