import React, {Component} from "react";

class NumberBox extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isProduction: props.isProduction,
			number: props.number
		}
	}

	render() {
		const className = (this.state.isProduction) ? "Production-Number-box" : "Number-box";
		return (
			<div className={ className }>
				<span className="notranslate">{ this.state.number }</span>
			</div>
		);
	}
}

export default NumberBox;