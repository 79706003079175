import initState from "./simflex-init-state";

const refactorCoins = (coins) => {
	let newCoins = []

	if (coins.length > 0) {
		let coinsValue = coins.reduce((total, num) => {
			return parseFloat(total) + parseFloat(num);
		})

		while (coinsValue > 0) {
			if (coinsValue >= 10) {
				newCoins.push(10)
				coinsValue -= 10
			}else if(coinsValue >= 1) {
				newCoins.push(1)
				coinsValue -= 1
			}else{
				newCoins.push(0.5)
				coinsValue -= 0.5
			}
		}
	}

	return newCoins;
}

const reducer = (state = initState, action) => {
	switch (action.type) {
		case 'UPDATE_STATE':
			state = action.data.state;
			return {
				...state
			}
		case 'UPDATE_CAPITAL':
			return {
				...state,
				CenterBlock: {
					...state.CenterBlock,
					Finance: {
						...state.CenterBlock.Finance,
						capital: action.value
					}
				}
			}
		case 'ADD_CONTAINER':
			console.log("HERE IN ADD CONTAINER");
			console.log(action);
			if (action.alias === "LeftBlock") {
				return {
					...state,
					LeftBlock: state.LeftBlock.map((content, i) =>
						i === action.id ?
							{
								...content,
								hasContainer: true,
								coins: action.coins,
								playerId: action.playerId,
								playerName: action.playerName
							} : content
					)
				};
			}else if (action.alias === "RightBlock") {
				return {
					...state,
					RightBlock: state.RightBlock.map((content, i) =>
						i === action.id ?
							{
								...content,
								hasContainer: true,
								coins: action.coins,
								playerId: action.playerId,
								playerName: action.playerName
							} : content
					)
				};
			}else if (action.alias === "BottomBlock") {
				return {
					...state,
					BottomBlock: state.BottomBlock.map((content, i) =>
						i === action.id ?
							{
								...content,
								hasContainer: true,
								coins: action.coins,
								playerId: action.playerId,
								playerName: action.playerName
							} : content
					)
				};
			}else if (action.alias === "Finance") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							cash: state.CenterBlock.Finance.cash.map((content, i) =>
								i === action.id ?
									{
										...content,
										hasContainer: true,
										coins: action.coins,
										playerId: action.playerId,
										playerName: action.playerName
									} : content
							)
						}
					}
				};
			}else if (action.alias === "Research") {
				state.CenterBlock.Research[action.fieldType].hasContainer = true;
				state.CenterBlock.Research[action.fieldType].coins = action.coins;
				state.CenterBlock.Research[action.fieldType].playerId = action.playerId;
				state.CenterBlock.Research[action.fieldType].playerName = action.playerName;

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Research: {
							...state.CenterBlock.Research
						}
					}
				};
			}else if (action.alias === "Purchase") {
				state.CenterBlock.Purchase[action.fieldType][action.id].hasContainer = true;
				state.CenterBlock.Purchase[action.fieldType][action.id].coins = action.coins;
				state.CenterBlock.Purchase[action.fieldType][action.id].playerId = action.playerId;
				state.CenterBlock.Purchase[action.fieldType][action.id].playerName = action.playerName;

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Purchase: {
							...state.CenterBlock.Purchase
						}
					}
				};
			}else if (action.alias === "Production") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: state.CenterBlock.Production.map((content, i) => {
							if(i === action.id) {
								content[action.fieldType].hasContainer = true
								content[action.fieldType].coins = action.coins
								content[action.fieldType].hasCard = action.hasCard
								content[action.fieldType].cardValue = action.cardValue
								content[action.fieldType].cardColor = action.cardColor
								content[action.fieldType].playerId = action.playerId
								content[action.fieldType].playerName = action.playerName
							}
							return content;
						})
					}
				};
			}else if (action.alias === "ColorField") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Stock: {
							...state.CenterBlock.Stock,
							[action.color]: state.CenterBlock.Stock[action.color].map((content, i) =>
								i === action.id ? {
											...content,
											hasContainer: true,
											coins: action.coins,
											hasCard: action.hasCard,
											cardValue: action.cardValue,
											cardColor: action.cardColor,
											playerId: action.playerId,
											playerName: action.playerName
										} : content
							)
						}
					}
				};
			}else if (action.alias === "DirectCost") {
				return {
					...state,
					DirectCost: state.DirectCost.map((content, i) =>
						i === action.id ? {
							...content,
							hasContainer: true,
							coins: action.coins,
							hasCard: action.hasCard,
							cardValue: action.cardValue,
							cardColor: action.cardColor,
							playerId: action.playerId,
							playerName: action.playerName
						} : content
					)
				};
			}
			break;
		case 'REMOVE_CONTAINER':
			if (action.alias === "LeftBlock") {
				return {
					...state,
					LeftBlock: state.LeftBlock.map((content, i) =>
						i === action.id ? {...content, hasContainer: false, coins: []} : content
					)
				};
			}else if (action.alias === "RightBlock") {
				return {
					...state,
					RightBlock: state.RightBlock.map((content, i) =>
						i === action.id ? {...content, hasContainer: false, coins: []} : content
					)
				};
			}else if (action.alias === "BottomBlock") {
				return {
					...state,
					BottomBlock: state.BottomBlock.map((content, i) =>
						i === action.id ? {...content, hasContainer: false, coins: []} : content
					)
				};
			}else if (action.alias === "Finance") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							cash: state.CenterBlock.Finance.cash.map((content, i) =>
								i === action.id ? {...content, hasContainer: false, coins: []} : content
							)
						}
					}
				};
			}else if (action.alias === "Research") {
				state.CenterBlock.Research[action.fieldType].hasContainer = false;
				state.CenterBlock.Research[action.fieldType].coins = [];

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Research: {
							...state.CenterBlock.Research
						}
					}
				};
			}else if (action.alias === "Purchase") {
				state.CenterBlock.Purchase[action.fieldType][action.id].hasContainer = false;
				state.CenterBlock.Purchase[action.fieldType][action.id].coins = [];

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Purchase: {
							...state.CenterBlock.Purchase
						}
					}
				};
			}else if (action.alias === "Production") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: state.CenterBlock.Production.map((content, i) => {
							if(i === action.id) {
								content[action.fieldType].hasContainer = false
								content[action.fieldType].hasCard = false
								if (content[action.fieldType].isFlip) {
									content[action.fieldType].isFlip = false
								}
								content[action.fieldType].coins = []
							}
							return content;
						})
					}
				};
			}else if (action.alias === "ColorField") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Stock: {
							...state.CenterBlock.Stock,
							[action.color]: state.CenterBlock.Stock[action.color].map((content, i) =>
								i === action.id ? {...content, hasContainer: false, coins: [], hasCard: false} : content
							)
						}
					}
				};
			}
			break;
		case 'ADD_TOKEN':
			if (state.CenterBlock.Finance[action.tokenType].type === ""
				||
				state.CenterBlock.Finance[action.tokenType].type === action.droppedTokenType
			) {
				let newValue = parseInt(state.CenterBlock.Finance[action.tokenType].value) + parseInt(action.value);
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							[action.tokenType]: {
								...state.CenterBlock.Finance[action.tokenType],
								value: newValue,
								type: action.droppedTokenType
							}
						}
					}
				}
			}
			break;
		case 'REMOVE_TOKEN':
			if (state.CenterBlock.Finance[action.tokenType].value !== 0) {
				const newValue = parseInt(state.CenterBlock.Finance[action.tokenType].value) - parseInt(action.value);
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							[action.tokenType]: {
								...state.CenterBlock.Finance[action.tokenType],
								value: newValue,
								type: (newValue === 0) ? "" : state.CenterBlock.Finance[action.tokenType].type
							}
						}
					}
				};
			}
			break;
		case 'ADD_PIN':
			if (action.alias === 'Production') {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: state.CenterBlock.Production.map((content, i) => {
							if(i === action.id) {
								content.pins[action.speed][action.title] = true
							}
							return content;
						})
					}
				};
			}else if (action.alias === 'Sales') {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Sales: {
							...state.CenterBlock.Sales,
							[action.title]: state.CenterBlock.Sales[action.title].map((content, i) =>
								i === action.id ? {...content, pin: true} : content
							)
						}
					}
				};
			}
			break;
		case 'REMOVE_PIN':
			if (action.alias === 'Production') {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: state.CenterBlock.Production.map((content, i) => {
							if(i === action.id) {
								content.pins[action.speed][action.title] = false
							}
							return content;
						})
					}
				};
			}else if (action.alias === 'Sales') {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Sales: {
							...state.CenterBlock.Sales,
							[action.title]: state.CenterBlock.Sales[action.title].map((content, i) =>
								i === action.id ? {...content, pin: false } : content
							)
						}
					}
				};
			}
			break;
		case 'ADD_COIN':
			if (action.alias === "LeftBlock") {
				let coinsList = [ ...state.LeftBlock[action.id].coins ];
				coinsList.push(action.coinValue)
				coinsList = refactorCoins(coinsList);

				state.LeftBlock[action.id].coins = coinsList
				state.LeftBlock[action.id].playerId = action.playerId;
				state.LeftBlock[action.id].playerName = action.playerName;
				return {
					...state,
					LeftBlock: [
						...state.LeftBlock
					]
				};
			}else if (action.alias === "RightBlock") {
				let coinsList = [ ...state.RightBlock[action.id].coins ];
				coinsList.push(action.coinValue)
				coinsList = refactorCoins(coinsList);

				state.RightBlock[action.id].coins = coinsList
				state.RightBlock[action.id].playerId = action.playerId;
				state.RightBlock[action.id].playerName = action.playerName;
				return {
					...state,
					RightBlock: [
						...state.RightBlock
					]
				};
			}else if (action.alias === "BottomBlock") {
				let coinsList = [ ...state.BottomBlock[action.id].coins ];
				coinsList.push(action.coinValue)
				coinsList = refactorCoins(coinsList);

				state.BottomBlock[action.id].coins = coinsList
				state.BottomBlock[action.id].playerId = action.playerId;
				state.BottomBlock[action.id].playerName = action.playerName;
				return {
					...state,
					BottomBlock: [
						...state.BottomBlock
					]
				};
			}else if (action.alias === "Finance") {
				let coinsList = [ ...state.CenterBlock.Finance.cash[action.id].coins ];
				coinsList.push(action.coinValue)
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Finance.cash[action.id].coins = coinsList;
				state.CenterBlock.Finance.cash[action.id].playerId = action.playerId;
				state.CenterBlock.Finance.cash[action.id].playerName = action.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							cash: [
								...state.CenterBlock.Finance.cash
							]
						}
					}
				}
			}else if (action.alias === "Research") {
				let coinsList = [ ...state.CenterBlock.Research[action.fieldType].coins ];
				coinsList.push(action.coinValue)
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Research[action.fieldType].coins = coinsList
				state.CenterBlock.Research[action.fieldType].playerId = action.playerId;
				state.CenterBlock.Research[action.fieldType].playerName = action.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Research: {
							...state.CenterBlock.Research
						}
					}
				};
			}else if (action.alias === "Purchase") {
				let coinsList = [ ...state.CenterBlock.Purchase[action.fieldType][action.id].coins ];
				coinsList.push(action.coinValue)
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Purchase[action.fieldType][action.id].coins = coinsList
				state.CenterBlock.Purchase[action.fieldType][action.id].playerId = action.playerId;
				state.CenterBlock.Purchase[action.fieldType][action.id].playerName = action.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Purchase: {
							...state.CenterBlock.Purchase
						}
					}
				};
			}else if (action.alias === "Production") {
				let coinsList = [ ...state.CenterBlock.Production[action.id][action.fieldType].coins ];
				coinsList.push(action.coinValue)
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Production[action.id][action.fieldType].coins = coinsList
				state.CenterBlock.Production[action.id][action.fieldType].playerId = action.playerId;
				state.CenterBlock.Production[action.id][action.fieldType].playerName = action.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: [
							...state.CenterBlock.Production
						]
					}
				};
			}
			break;
		case 'REMOVE_COIN':
			if (action.alias === "LeftBlock") {
				let coinsList = [ ...state.LeftBlock[action.id].coins ];
				coinsList.splice(action.coinIndex, 1);
				coinsList = refactorCoins(coinsList);

				state.LeftBlock[action.id].coins = coinsList
				state.LeftBlock[action.id].playerId = action.playerId;
				state.LeftBlock[action.id].playerName = action.playerName;
				return {
					...state,
					LeftBlock: [
						...state.LeftBlock
					]
				};
			}else if (action.alias === "RightBlock") {
				let coinsList = [ ...state.RightBlock[action.id].coins ];
				coinsList.splice(action.coinIndex, 1);
				coinsList = refactorCoins(coinsList);

				state.RightBlock[action.id].coins = coinsList
				state.RightBlock[action.id].playerId = action.playerId;
				state.RightBlock[action.id].playerName = action.playerName;
				return {
					...state,
					RightBlock: [
						...state.RightBlock
					]
				};
			}else if (action.alias === "BottomBlock") {
				let coinsList = [ ...state.BottomBlock[action.id].coins ];
				coinsList.splice(action.coinIndex, 1);
				coinsList = refactorCoins(coinsList);

				state.BottomBlock[action.id].coins = coinsList
				state.BottomBlock[action.id].playerId = action.playerId;
				state.BottomBlock[action.id].playerName = action.playerName;
				return {
					...state,
					BottomBlock: [
						...state.BottomBlock
					]
				};
			}else if (action.alias === "Finance") {
				let coinsList = [ ...state.CenterBlock.Finance.cash[action.id].coins ];
				coinsList.splice(action.coinIndex, 1);
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Finance.cash[action.id].coins = coinsList
				state.CenterBlock.Finance.cash[action.id].playerId = action.playerId;
				state.CenterBlock.Finance.cash[action.id].playerName = action.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							cash: [
								...state.CenterBlock.Finance.cash
							]
						}
					}
				};
			}else if (action.alias === "Research") {
				let coinsList = [ ...state.CenterBlock.Research[action.fieldType].coins ];
				coinsList.splice(action.coinIndex, 1);
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Research[action.fieldType].coins = coinsList;
				state.CenterBlock.Research[action.fieldType].playerId = action.playerId;
				state.CenterBlock.Research[action.fieldType].playerName = action.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Research: {
							...state.CenterBlock.Research
						}
					}
				};
			}else if (action.alias === "Purchase") {
				let coinsList = [ ...state.CenterBlock.Purchase[action.fieldType][action.id].coins ];
				coinsList.splice(action.coinIndex, 1);
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Purchase[action.fieldType][action.id].coins = coinsList;
				state.CenterBlock.Purchase[action.fieldType][action.id].playerId = action.playerId;
				state.CenterBlock.Purchase[action.fieldType][action.id].playerName = action.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Purchase: {
							...state.CenterBlock.Purchase
						}
					}
				};
			}else if (action.alias === "Production") {
				let coinsList = [ ...state.CenterBlock.Production[action.id][action.fieldType].coins ];
				coinsList.splice(action.coinIndex, 1);
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Production[action.id][action.fieldType].coins = coinsList;
				state.CenterBlock.Production[action.id][action.fieldType].playerId = action.playerId;
				state.CenterBlock.Production[action.id][action.fieldType].playerName = action.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: [
							...state.CenterBlock.Production
						]
					}
				};
			}
			break;
		case 'ADD_COINS':
			if (action.alias === "LeftBlock") {
				let coinsList = state.LeftBlock[action.id].coins;
				coinsList = coinsList.concat(action.coins);
				coinsList = refactorCoins(coinsList);

				return {
					...state,
					LeftBlock: state.LeftBlock.map((content, i) => {
						if(i === action.id) {
							content.coins = coinsList
							content.playerId = action.playerId
							content.playerName = action.playerName
						}
						return content;
					})
				};
			}else if (action.alias === "RightBlock") {
				let coinsList = state.RightBlock[action.id].coins;
				coinsList = coinsList.concat(action.coins);
				coinsList = refactorCoins(coinsList);

				return {
					...state,
					RightBlock: state.RightBlock.map((content, i) => {
						if(i === action.id) {
							content.coins = coinsList
							content.playerId = action.playerId
							content.playerName = action.playerName
						}
						return content;
					})
				};
			}else if (action.alias === "BottomBlock") {
				let coinsList = state.BottomBlock[action.id].coins;
				coinsList = coinsList.concat(action.coins);
				coinsList = refactorCoins(coinsList);

				return {
					...state,
					BottomBlock: state.BottomBlock.map((content, i) => {
						if(i === action.id) {
							content.coins = coinsList
							content.playerId = action.playerId
							content.playerName = action.playerName
						}
						return content;
					})
				};
			}else if (action.alias === "Finance") {
				let coinsList = state.CenterBlock.Finance.cash[action.id].coins;
				coinsList = coinsList.concat(action.coins);
				coinsList = refactorCoins(coinsList);

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							cash: state.CenterBlock.Finance.cash.map((content, i) => {
								if(i === action.id) {
									content.coins = coinsList
									content.playerId = action.playerId
									content.playerName = action.playerName
								}
								return content;
							})
						}
					}
				};
			}else if (action.alias === "Research") {
				let coinsList = state.CenterBlock.Research[action.fieldType].coins
				coinsList = coinsList.concat(action.coins);
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Research[action.fieldType].coins = coinsList;
				state.CenterBlock.Research[action.fieldType].playerId = action.playerId;
				state.CenterBlock.Research[action.fieldType].playerName = action.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Research: {
							...state.CenterBlock.Research
						}
					}
				};
			}else if (action.alias === "Purchase") {
				let coinsList = state.CenterBlock.Purchase[action.fieldType][action.id].coins
				coinsList = coinsList.concat(action.coins);
				coinsList = refactorCoins(coinsList);

				state.CenterBlock.Purchase[action.fieldType][action.id].coins = coinsList;
				state.CenterBlock.Purchase[action.fieldType][action.id].playerId = action.playerId;
				state.CenterBlock.Purchase[action.fieldType][action.id].playerName = action.playerName;
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Purchase: {
							...state.CenterBlock.Purchase
						}
					}
				};
			}else if (action.alias === "Production") {
				let coinsList = state.CenterBlock.Production[action.id][action.fieldType].coins;
				coinsList = coinsList.concat(action.coins);
				coinsList = refactorCoins(coinsList);

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: state.CenterBlock.Production.map((content, i) => {
							if(i === action.id) {
								content[action.fieldType].coins = coinsList
								content[action.fieldType].playerId = action.playerId
								content[action.fieldType].playerName = action.playerName
							}
							return content;
						})
					}
				};
			}
			break;
		case 'UPDATE_COINS':
			if (action.alias === "LeftBlock") {
				return {
					...state,
					LeftBlock: state.LeftBlock.map((content, i) => {
						if(i === action.id) {
							content.coins = action.coins
							content.playerId = action.playerId
							content.playerName = action.playerName
						}
						return content;
					})
				};
			}else if (action.alias === "RightBlock") {
				return {
					...state,
					RightBlock: state.RightBlock.map((content, i) => {
						if(i === action.id) {
							content.coins = action.coins
							content.playerId = action.playerId
							content.playerName = action.playerName
						}
						return content;
					})
				};
			}else if (action.alias === "BottomBlock") {
				return {
					...state,
					BottomBlock: state.BottomBlock.map((content, i) => {
						if(i === action.id) {
							content.coins = action.coins
							content.playerId = action.playerId
							content.playerName = action.playerName
						}
						return content;
					})
				};
			}else if (action.alias === "Finance") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							cash: state.CenterBlock.Finance.cash.map((content, i) => {
								if(i === action.id) {
									content.coins = action.coins
									content.playerId = action.playerId
									content.playerName = action.playerName
								}
								return content;
							})
						}
					}
				};
			}else if (action.alias === "Research") {
				state.CenterBlock.Research[action.fieldType].coins = action.coins;
				state.CenterBlock.Research[action.fieldType].playerId = action.playerId;
				state.CenterBlock.Research[action.fieldType].playerName = action.playerName;

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Research: {
							...state.CenterBlock.Research
						}
					}
				};
			}else if (action.alias === "Purchase") {
				state.CenterBlock.Purchase[action.fieldType][action.id].coins = action.coins;
				state.CenterBlock.Purchase[action.fieldType][action.id].playerId = action.playerId;
				state.CenterBlock.Purchase[action.fieldType][action.id].playerName = action.playerName;

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Purchase: {
							...state.CenterBlock.Purchase
						}
					}
				};
			}else if (action.alias === "Production") {
				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: state.CenterBlock.Production.map((content, i) => {
							if(i === action.id) {
								content[action.fieldType].coins = action.coins
								content[action.fieldType].playerId = action.playerId
								content[action.fieldType].playerName = action.playerName
							}
							return content;
						})
					}
				};
			}
			break;
		case 'ADD_CARD':
			return {
				...state,
				CenterBlock: {
					...state.CenterBlock,
					Production: state.CenterBlock.Production.map((content, i) => {
						if(i === action.id) {
							content[action.optionType].hasCard = true
							content[action.optionType].cardColor = action.cardColor
							content[action.optionType].cardValue = action.cardValue
						}
						return content;
					})
				}
			};
		case 'FACTORY_PURCHASE':
			return {
				...state,
				CenterBlock: {
					...state.CenterBlock,
					Production: state.CenterBlock.Production.map((content, i) => {
						if(i === action.id) {
							content.factory.isFlip = false
						}
						return content;
					})
				}
			};
		case 'FACTORY_RENT':
			return {
				...state,
				CenterBlock: {
					...state.CenterBlock,
					Production: state.CenterBlock.Production.map((content, i) => {
						if(i === action.id) {
							content.factory.coins = []
							content.factory.isFlip = true
						}
						return content;
					})
				}
			};
		case 'UPDATE_DRAGGING':
			if (action.alias === "LeftBlock") {
				state.LeftBlock[action.data.id].isDragging = action.isDragging
				state.LeftBlock[action.data.id].type = action.itemType
				//if (action.isDragging)
				state.LeftBlock[action.data.id].playerId = action.data.playerId
				state.LeftBlock[action.data.id].playerName = action.data.playerName

				if (action.itemType === "COIN") {
					state.LeftBlock[action.data.id].coinsDraggingIndex = (action.data.isDragging) ? action.data.index : -1;
				}

				return {
					...state,
					LeftBlock: [
						...state.LeftBlock
					]
				};
			}else if (action.alias === "RightBlock") {
				state.RightBlock[action.data.id].isDragging = action.isDragging
				state.RightBlock[action.data.id].type = action.itemType
				//if (action.isDragging)
				state.RightBlock[action.data.id].playerId = action.data.playerId
				state.RightBlock[action.data.id].playerName = action.data.playerName

				if (action.itemType === "COIN") {
					state.RightBlock[action.data.id].coinsDraggingIndex = (action.data.isDragging) ? action.data.index : -1;
				}

				return {
					...state,
					RightBlock: [
						...state.RightBlock
					]
				};
			}else if (action.alias === "BottomBlock") {
				state.BottomBlock[action.data.id].isDragging = action.isDragging
				state.BottomBlock[action.data.id].type = action.itemType
				//if (action.isDragging)
				state.BottomBlock[action.data.id].playerId = action.data.playerId
				state.BottomBlock[action.data.id].playerName = action.data.playerName

				if (action.itemType === "COIN") {
					state.BottomBlock[action.data.id].coinsDraggingIndex = (action.data.isDragging) ? action.data.index : -1;
				}

				return {
					...state,
					BottomBlock: [
						...state.BottomBlock
					]
				};
			}else if (action.alias === "Finance") {
				state.CenterBlock.Finance.cash[action.data.id].isDragging = action.isDragging
				state.CenterBlock.Finance.cash[action.data.id].type = action.itemType
				//if (action.isDragging)
				state.CenterBlock.Finance.cash[action.data.id].playerId = action.data.playerId
				state.CenterBlock.Finance.cash[action.data.id].playerName = action.data.playerName

				if (action.itemType === "COIN") {
					state.CenterBlock.Finance.cash[action.data.id].coinsDraggingIndex = (action.isDragging) ? action.data.index : -1;
				}

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Finance: {
							...state.CenterBlock.Finance,
							cash: [
								...state.CenterBlock.Finance.cash
							]
						}
					}
				};
			}else if (action.alias === "Research") {
				state.CenterBlock.Research[action.data.fieldType].isDragging = action.isDragging
				state.CenterBlock.Research[action.data.fieldType].type = action.itemType
				//if (action.isDragging)
					state.CenterBlock.Research[action.data.fieldType].playerId = action.data.playerId
				state.CenterBlock.Research[action.data.fieldType].playerName = action.data.playerName

				if (action.itemType === "COIN") {
					state.CenterBlock.Research[action.data.fieldType].coinsDraggingIndex = (action.data.isDragging) ? action.data.index : -1;
				}

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Research: {
							...state.CenterBlock.Research
						}
					}
				};
			}else if (action.alias === "Purchase") {
				state.CenterBlock.Purchase[action.data.fieldType][action.data.id].isDragging = action.isDragging
				state.CenterBlock.Purchase[action.data.fieldType][action.data.id].type = action.itemType
				//if (action.isDragging)
					state.CenterBlock.Purchase[action.data.fieldType][action.data.id].playerId = action.data.playerId
				state.CenterBlock.Purchase[action.data.fieldType][action.data.id].playerName = action.data.playerName

				if (action.itemType === "COIN") {
					state.CenterBlock.Purchase[action.data.fieldType][action.data.id].coinsDraggingIndex = (action.data.isDragging) ? action.data.index : -1;
				}

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Purchase: {
							...state.CenterBlock.Purchase
						}
					}
				};
			}else if (action.alias === "Production") {
				state.CenterBlock.Production[action.data.id][action.data.fieldType].isDragging = action.isDragging
				state.CenterBlock.Production[action.data.id][action.data.fieldType].type = action.itemType
				//if (action.isDragging)
				state.CenterBlock.Production[action.data.id][action.data.fieldType].playerId = action.data.playerId
				state.CenterBlock.Production[action.data.id][action.data.fieldType].playerName = action.data.playerName

				if (action.itemType === "COIN") {
					state.CenterBlock.Production[action.data.id][action.data.fieldType].coinsDraggingIndex = (action.data.isDragging) ? action.data.index : -1;
				}

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Production: [
							...state.CenterBlock.Production
						]
					}
				};
			}else if (action.alias === "ColorField") {
				state.CenterBlock.Stock[action.data.fieldType][action.data.id].isDragging = action.isDragging
				state.CenterBlock.Stock[action.data.fieldType][action.data.id].type = action.itemType
				//if (action.isDragging){
					state.CenterBlock.Stock[action.data.fieldType][action.data.id].playerId = action.data.playerId
					state.CenterBlock.Stock[action.data.fieldType][action.data.id].playerName = action.data.playerName
				//}

				return {
					...state,
					CenterBlock: {
						...state.CenterBlock,
						Stock: {
							...state.CenterBlock.Stock
						}
					}
				};
			}
			break;
		case 'UPDATE_CAPITAL_STATUS':
			state.CenterBlock.Finance.isDragging = action.isDragging
			state.CenterBlock.Finance.type = action.itemType
			//if (action.isDragging) {
				state.CenterBlock.Finance.playerId = action.data.playerId
				state.CenterBlock.Finance.playerName = action.data.playerName
			//}


			return {
				...state,
				CenterBlock: {
					...state.CenterBlock,
					Finance: {
						...state.CenterBlock.Finance
					}
				}
			};
		default:
			break;
	}

	return state
}

export default reducer;