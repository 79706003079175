import React from 'react';
import { Row, Col, DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap';

import localization from "../../../../utils/localization";

class StrategyPaperFixedAsset extends React.Component {

	optionChanged = (field, value) => {
		this.props.optionChanged("fixedAssets", this.props.option, field, value)
	}

	render() {
		const selectedLang = this.props.selectedLang;

		let title = ""
		switch (this.props.index) {
			case 0:
				title= "1 National";
				break;
			case 1:
				title= "2 National";
				break;
			case 2:
				title= "3 National";
				break;
			case 3:
				title= "1 International";
				break;
			case 4:
				title= "2 International";
				break;
			case 5:
				title= "3 International";
				break;
			case 6:
				title= "1 Intercontinental 1";
				break;
			case 7:
				title= "2 Intercontinental 1";
				break;
			case 8:
				title= "1 Intercontinental 2";
				break;
			case 9:
				title= "2 Intercontinental 2";
				break;
			default:
				break;
		}
		return (
			<Row>
				<Col md="5" className="removeLeftPadding">
					<div className="bold">{ title }</div>
				</Col>
				<Col md="7">
					<div>
						<DropdownButton
							as={ButtonGroup}
							variant="outline-secondary"
							className={ (this.props.field.factory !== "") ? "whiteDropdown" : "" }
							title={ (this.props.field.factory !== "") ? localization[selectedLang][this.props.field.factory.toLowerCase()] : `${ localization[selectedLang].purchase }/${ localization[selectedLang].rent }`}
							size="sm"
						>
							<Dropdown.Item eventKey="" onClick={ this.optionChanged.bind(this, "factory", "") }>-</Dropdown.Item>
							<Dropdown.Item eventKey="purchase" onClick={ this.optionChanged.bind(this, "factory", "Purchase") }>{ localization[selectedLang].purchase }</Dropdown.Item>
							<Dropdown.Item eventKey="rent" onClick={ this.optionChanged.bind(this, "factory", "Rent") }>{ localization[selectedLang].rent }</Dropdown.Item>
						</DropdownButton>
					</div>
					<div>
						<DropdownButton
							as={ButtonGroup}
							variant="outline-secondary"
							className={ (this.props.field.machine !== "") ? "whiteDropdown" : "" }
							title={ (this.props.field.machine !== "") ? this.props.field.machine : `f/m/s`}
							size="sm"
						>
							<Dropdown.Item eventKey="" onClick={ this.optionChanged.bind(this, "machine", "") }>-</Dropdown.Item>
							<Dropdown.Item eventKey="fast" onClick={ this.optionChanged.bind(this, "machine", "f") }>Fast</Dropdown.Item>
							<Dropdown.Item eventKey="medium" onClick={ this.optionChanged.bind(this, "machine", "m") }>Medium</Dropdown.Item>
							<Dropdown.Item eventKey="slow" onClick={ this.optionChanged.bind(this, "machine", "s") }>Slow</Dropdown.Item>
						</DropdownButton>
					</div>
				</Col>
			</Row>
		);
	}

}

export default StrategyPaperFixedAsset;