import React from "react";
import { useDrag, DragPreviewImage } from 'react-dnd';
import { Image } from "react-bootstrap";

// Images
import previewImg from "../../../assets/images/pushpin-preview.png";
import pinImg from "../../../assets/images/pushpin2.png";

export default function DraggablePin(props) {
	const [, drag, preview] = useDrag({
		item: { type: 'Pin' },
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (dropResult, monitor) => {
			let doppableItem = monitor.getDropResult();
			if (monitor.didDrop()) {
				const isDropToDelete = (doppableItem.droppableType === "delete") ? true : false;

				props.removePin(props.title, props.speed, props.id, isDropToDelete)
			}
		}
	});

	const canDrag = (props.canEdit) ? drag : null;
	return (
		<>
			<DragPreviewImage connect={preview} src={previewImg} />
			<Image className="pinImgView" src={pinImg} ref={canDrag} />
		</>
	);
}