import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// CSS
import "../../css/SimFlex/Common.css";
import "../../css/SimFlex/LeftBlock.css";
import '../../css/SimFlex/RightBlock.css';
import "../../css/SimFlex/BottomBlock.css";
import "../../css/SimFlex/FinanceBlock.css";
import "../../css/SimFlex/ResearchBlock.css";
import "../../css/SimFlex/PurchaseBlock.css";
import "../../css/SimFlex/ProductionBlock.css";
import "../../css/SimFlex/SalesStockBlock.css";

// Components
import LeftBlock from "./LeftBlock/LeftBlock";
import LeftBlockSpacer from "./LeftBlock/LeftBlockSpacer";
import CenterBlock from "./CenterBlock/CenterBlock";
import BottomBlock from "./CenterBlock/BottomBlock/BottomBlock";
import RightBlock from "./RightBlock/RightBlock";
import RightBlockSpacer from "./RightBlock/RightBlockSpacer";
import BottomRightBlock from "./RightBlock/BottomRightBlock";

class SimFlex extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			canEdit: props.canEdit
		}
	}

	render() {
		return (
			<div className="App">
				<Container fluid>
					<Row className="Board">
						<div className="Left-column">
							<LeftBlock canEdit={this.state.canEdit} id={0} germanTitle="Steuern" englishTitle="taxes" spanishTitle="impuestos" />
							<LeftBlock canEdit={this.state.canEdit} id={1} germanTitle="Zinsen" englishTitle="interests" spanishTitle="intereses" />
							<LeftBlock canEdit={this.state.canEdit} id={2} germanTitle="sonstige Gemeinkosten" englishTitle="other overheads" spanishTitle="otros gastos generales" />
							<LeftBlockSpacer />
							<LeftBlock canEdit={this.state.canEdit} id={3} germanTitle="Miete" englishTitle="lease" spanishTitle="renta" />
							<LeftBlock canEdit={this.state.canEdit} id={4} germanTitle="Qualitätssicherung" englishTitle="quality assurance" spanishTitle="aseguramiento de calidad" />
							<LeftBlock canEdit={this.state.canEdit} id={5} germanTitle="Prozessoptimierung" englishTitle="process optimization" spanishTitle="optimización de procesos" />
							<LeftBlockSpacer />
							<LeftBlockSpacer />
							<LeftBlock canEdit={this.state.canEdit} id={6} germanTitle="Gemeinkosten Vertrieb" englishTitle="indirect sales costs" spanishTitle="gastos generales de ventas" />
						</div>
						<div className="Middle-column">
							<CenterBlock canEdit={this.state.canEdit} />
							<div>
								<Row>
									<Col>
										<BottomBlock canEdit={this.state.canEdit} id={0} isFirst={true} germanTitle="sonstige Erträge" englishTitle="other incomes" spanishTitle="otros ingresos" />
									</Col>
									<Col></Col>
									<Col>
										<BottomBlock canEdit={this.state.canEdit} id={1} germanTitle="Lagerkosten" englishTitle="storage costs" spanishTitle="costes de almacenaje" />
									</Col>
									<Col>
										<BottomBlock canEdit={this.state.canEdit} id={2} germanTitle="Marketing" englishTitle="marketing" spanishTitle="marketing" />
									</Col>
								</Row>
							</div>
						</div>
						<div className="Right-column">
							<RightBlock canEdit={this.state.canEdit} id={0} germanTitle="Gemeinkosten F & E" englishTitle="indirect R & D costs" spanishTitle="gastos generales del desarrollo" />
							<RightBlock canEdit={this.state.canEdit} id={1} germanTitle="Abschreibung auf aktivierte Entwicklungskosten" englishTitle="depreciation of capitalized development costs" spanishTitle="depreciación de costes de desarrollo capitalizados" />
							<RightBlockSpacer />
							<RightBlockSpacer />
							<RightBlock canEdit={this.state.canEdit} id={2} germanTitle="Abschreibung auf Produktionsstätten" englishTitle="depreciation of production plants" spanishTitle="depreciaćion de las plantas" />
							<RightBlock canEdit={this.state.canEdit} id={3} germanTitle="Abschreibung auf Maschinen" englishTitle="depreciation of machines" spanishTitle="depreciaćion de las maquinas" />
							<RightBlock canEdit={this.state.canEdit} id={4} germanTitle="Gemeinkosten Produktion" englishTitle="indirect production costs" spanishTitle="gastos generales de producción" />
							<RightBlockSpacer />
							<BottomRightBlock canEdit={this.state.canEdit} germanTitle="Einzelkosten" englishTitle="direct costs" spanishTitle="costes directos" />
						</div>
					</Row>
				</Container>
			</div>
		);
	}
}

export default SimFlex;