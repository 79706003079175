import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";

// Components
import FinanceBlock from "./FinanceBlock/FinanceBlock";
import ResearchBlock from "./ResearchBlock/ResearchBlock";
import PurchaseBlock from "./PurchaseBlock/PurchaseBlock";
import ProductionBlock from "./ProductionBlock/ProductionBlock";
import SalesStockBlock from "./SalesStockBlock";

class CenterBlock extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit
		}
	}

	render() {
		return (
			<div className="Center-block">
				<Row>
					<div className="Finance-container-margin">
						<FinanceBlock canEdit={this.state.canEdit} />
					</div>
					<div className="Research-Purchasing-container">
						<div className="Research-Container">
							<ResearchBlock canEdit={this.state.canEdit} />
						</div>
						<div className="Purchase-Container">
							<PurchaseBlock canEdit={this.state.canEdit} />
						</div>
					</div>
				</Row>
				<Row>
					<Col>
						<ProductionBlock canEdit={this.state.canEdit} />
					</Col>
				</Row>
				<Row>
					<Col>
						<SalesStockBlock canEdit={this.state.canEdit} />
					</Col>
				</Row>
			</div>
		);
	}
}

export default CenterBlock;