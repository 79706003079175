import React from "react";
import { useDrop } from 'react-dnd';

// Constants
import colors from "../../../../utils/colors";

// Components
import DraggablePin from "../../Common/DraggablePin";

export default function ProductionDroppablePin(props) {

	const [{ isOver, canDrop }, drop] = useDrop({
		accept: 'Pin',
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		drop: (item) => {
			if (parseInt(item.count) > 0) {
				props.appendPin(props.title, props.speed, props.id);
			}else if (item.count === undefined) {
				props.appendPin(props.title, props.speed, props.id);
			}

			return item;
		},
	})

	const backgroundColor = (isOver && canDrop) ? colors.activeBackground : "";
	const canPlayerDrop = (props.canEdit) ? drop : null;

	return (
		<>
			{
				(props.hasPin)?
					<div className="Production-droppable-pin full-width-height pinContainer">
						<div className="dot-class"></div>
						<DraggablePin
							canEdit={props.canEdit}
							id={props.id}
							title={props.title}
							speed={props.speed}
							removePin={props.removePin}
						/>
					</div>
					:
					<div className="Production-droppable-pin full-width-height pinContainer" style={{ backgroundColor: backgroundColor }} ref={canPlayerDrop}>
						<div className="dot-class"></div>
					</div>
			}
		</>

	);
}