const startingKit = {
	coins: 60.5,
	tokens: 30,
	blackTokens: 0,
	pins: 2,
	factory: 1,
	machine: 1,
	production: {
		"5":{
			"STANDARD":1,
			"COMPACT":0,
			"PLUS":0,
			"LUXUS":0
		}
	}
}

export default startingKit;