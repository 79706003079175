import React, { Component } from "react";
import {connect} from "react-redux";
import Linkify from 'react-linkify';

// CSS
import "../../css/Chat.css";

// Dispatcher
import mapDispatchToProps from "../../reducers/dispatchers";



class ChatComponent extends Component{

	constructor(props) {
		super(props);

		this.state = {
			message: ""
		}
	}

	componentDidMount() {
		this.scrollToBottom();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.scrollToBottom();
	}

	scrollToBottom = () => {
		const scrollHeight = this.messagesEnd.scrollHeight;
		const height = this.messagesEnd.clientHeight;
		const maxScrollTop = scrollHeight - height;
		this.messagesEnd.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
	}

	sendMessage = () => {
		if (this.state.message !== "") {
			this.setState({ message: "" }, () => {
				this.resizeFrame()
			});
		}
	}

	resizeFrame = (e) => {
		let msg = document.getElementById("messageTxtArea");
		let div = document.getElementsByClassName("form-container")[0]

		setTimeout(function() {
			msg.style.cssText = 'height:0px';
			let height = Math.min(20 * 5, msg.scrollHeight);
			if (height < 30) {
				height = 30;
			}
			div.style.cssText = 'height:' + (height + 10) + 'px';
			msg.style.cssText = 'height:' + height + 'px';
		},0);
	}

	render() {
		return (
			<div className="chat-container">
				<div className="chat-popup" id="myForm">
					<div className="chat-popup-header">
						<div className="chat-popup-header-title">Messages</div>
						<div className="chat-popup-header-btn">
							<button type="button" onClick={ this.props.closeChat }>X</button>
						</div>
					</div>
					<div id="MessagesList" className="chat-messages" ref={ el => this.messagesEnd = el }>
						{
							this.props.session.messages.map((obj, index) => {
								return (
									<div key={index} className="chat-message-row">
										<span className="playerName">{ obj.playerName }</span>
										<div className="message">
											<Linkify properties={{target: '_blank'}}>
												{ obj.message }
											</Linkify>
										</div>
									</div>
								);
							})
						}
					</div>
					<div className="chat-form">
						<div className="form-container">
							<textarea
								id="messageTxtArea"
								placeholder="Type message.."
								name="msg"
								rows='1'
								required
								value={this.state.message}
								onKeyPress={ event => {
									if (event.key === "Enter" && !event.shiftKey) {
										this.sendMessage();
										event.preventDefault();
										event.stopPropagation();
									}else{
										this.resizeFrame()
									}
									return true;
								}}
								onChange={event => {
									this.setState({message: event.target.value})
								}}
							></textarea>
						</div>
					</div>
				</div>
			</div>
		);
	}

}

/*
<button type="submit" className="btn" onClick={this.sendMessage}>Send</button>
 */

const mapStateToProps = (state) => {
	return {
		session: state.session
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatComponent);