import React from 'react';
import { Table, FormControl } from 'react-bootstrap';

import localization from "../../../../utils/localization";

class IncomeStatement extends React.Component {

	handleChange(field, value) {
		this.props.inputChanged("incomeStatement", field, value)
	}

	render() {
		const selectedLang = this.props.selectedLang;

		const grossProfit = parseFloat(this.props.incomeStatement.salesRevenue) - parseFloat(this.props.incomeStatement.directCosts);

		let totalOverhead = 0;
		totalOverhead += parseFloat(this.props.incomeStatement.indirectRAndDCosts)
		totalOverhead += parseFloat(this.props.incomeStatement.docDevelopmentCosts)
		totalOverhead += parseFloat(this.props.incomeStatement.productionPlants)
		totalOverhead += parseFloat(this.props.incomeStatement.machines)
		totalOverhead += parseFloat(this.props.incomeStatement.indirectProductionCosts)
		totalOverhead += parseFloat(this.props.incomeStatement.marketing)
		totalOverhead += parseFloat(this.props.incomeStatement.storageCosts)
		totalOverhead += parseFloat(this.props.incomeStatement.indirectSalesCosts)
		totalOverhead += parseFloat(this.props.incomeStatement.processOptimization)
		totalOverhead += parseFloat(this.props.incomeStatement.qualityAssurance)
		totalOverhead += parseFloat(this.props.incomeStatement.lease)
		totalOverhead += parseFloat(this.props.incomeStatement.otherOverheads)

		const ebit = grossProfit - totalOverhead;

		let profitBeforeTax = ebit + parseFloat(this.props.incomeStatement.otherIncomes);
		profitBeforeTax -= parseFloat(this.props.incomeStatement.interest);

		let lossCarriedForward = parseFloat(this.props.incomeStatement.lossCarriedForward);
		let taxes = 0;
		if (lossCarriedForward >= profitBeforeTax) {
			taxes = 0
		}else{
			taxes = Math.round(((profitBeforeTax - lossCarriedForward) * 0.25) / .5) * .5;
		}

		const profitAfterTaxes = profitBeforeTax - taxes;

		return (
			<Table bordered>
				<tbody>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].salesRevenue }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.salesRevenue }
								onChange={ (e) => this.handleChange("salesRevenue", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].directCosts }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.directCosts }
								onChange={ (e) => this.handleChange("directCosts", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].grossProfit }</div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ grossProfit } disabled />
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].indirectRDCosts }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.indirectRAndDCosts }
								onChange={ (e) => this.handleChange("indirectRAndDCosts", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].depreciationOfCapitalizedDevelopmentCosts }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.docDevelopmentCosts }
								onChange={ (e) => this.handleChange("docDevelopmentCosts", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].depreciationOfProductionPlants }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.productionPlants }
								onChange={ (e) => this.handleChange("productionPlants", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].depreciationOfMachines }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.machines }
								onChange={ (e) => this.handleChange("machines", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].indirectProductionCosts }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.indirectProductionCosts }
								onChange={ (e) => this.handleChange("indirectProductionCosts", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].marketing }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.marketing }
								onChange={ (e) => this.handleChange("marketing", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].storageCosts }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.storageCosts }
								onChange={ (e) => this.handleChange("storageCosts", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].indirectSalesCosts }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.indirectSalesCosts }
								onChange={ (e) => this.handleChange("indirectSalesCosts", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].processOptimization }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.processOptimization }
								onChange={ (e) => this.handleChange("processOptimization", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].qualityAssurance }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.qualityAssurance }
								onChange={ (e) => this.handleChange("qualityAssurance", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].lease } </div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.lease }
								onChange={ (e) => this.handleChange("lease", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].otherOverheads }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.otherOverheads }
								onChange={ (e) => this.handleChange("otherOverheads", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].totalOverhead }</div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ totalOverhead } disabled />
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].operationProfit }</div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ ebit } disabled />
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].otherIncomes }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.otherIncomes }
								onChange={ (e) => this.handleChange("otherIncomes", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].interest }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.interest }
								onChange={ (e) => this.handleChange("interest", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].profitBeforeTax }</div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ profitBeforeTax } disabled />
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].lossCarriedForward }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.incomeStatement.lossCarriedForward }
								onChange={ (e) => this.handleChange("lossCarriedForward", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].taxes } 25%</div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ taxes } disabled />
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].profitAfterTax }</div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ profitAfterTaxes } disabled />
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}

}

export default IncomeStatement;