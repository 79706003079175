import React, {Component} from "react";
import { connect } from "react-redux";
import {Col, Row} from "react-bootstrap";

// Dispatchers
import mapDispatchToProps from "../../../../reducers/dispatchers";

// Components
import SalesNumberBox from "./SalesNumberBox";

class SalesRow extends Component{
	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit,
			title: props.title
		}
	}

	onGuidedTourNext = () => {
		this.props.nextGuidedTour();
	}
	onGuidedTourPrev = () => {
		this.props.prevGuidedTour();
	}

	render() {
		const rowObj = this.props.object;

		return (
			<Row className="sales-row-container">
				<Col><span className="sales-row-title">{ this.state.title }</span></Col>
				{
					rowObj.map((value, index) =>
						<Col key={index} style={{ position: 'relative' }}>
							<SalesNumberBox
								canEdit={this.state.canEdit}
								id={index}
								hasPin={value.pin}
								title={this.state.title}
								number={value.value}
								appendPin={this.props.appendPin}
								removePin={this.props.removePin}
							/>
							{
								(this.state.title === "Nat" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "salesPin") ?
									<div className="pinTourPopup">
										<div className="upArrow"></div>
										<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
										<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

										<div className="actions">
											<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
												Prev
											</div>
											{
												(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "pin" && value.pin) ?
													<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
														Next
													</div>
													:
													null
											}
										</div>
									</div>
								:
									null
							}
						</Col>
					)
				}
				{
					(rowObj.length === 1) ?
						<>
							<Col key={1}></Col>
							<Col key={2}></Col>
							<Col key={3}></Col>
						</>
						:
						null
				}
				{
					(rowObj.length === 2) ?
						<>
							<Col key={2}></Col>
							<Col key={3}></Col>
						</>
						:
						null
				}
			</Row>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		sales: state.board.CenterBlock.Sales,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesRow);;