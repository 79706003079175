const initState = {
	incomeStatement: {
		salesRevenue: 0,
		directCosts: 0,
		indirectRAndDCosts: 0,
		docDevelopmentCosts: 0,
		productionPlants: 0,
		machines: 0,
		indirectProductionCosts: 0,
		marketing: 0,
		storageCosts: 0,
		indirectSalesCosts: 0,
		processOptimization: 0,
		qualityAssurance: 0,
		lease: 0,
		otherOverheads: 0,
		otherIncomes: 0,
		interest: 0,
		lossCarriedForward: 0
	},
	balanceSheet: {
		capitalizedDevelopmentCosts: 0,
		productionFacilities: 0,
		machines: 0,
		finishedProducts: 0,
		inventories: 0,
		cash: 0,
		equity: 0,
		liabilitiesNonCurrent: 0,
		liabilitiesCurrent: 0
	},
	cashFlow: {
		cashLevelAtStart: 0,
		salesRevenue: 0,
		otherIncomes: 0,
		materialPurchase: 0,
		labour: 0,
		indirectRAndDCosts: 0,
		productDevelopmentCost: 0,
		productionFacilities: 0,
		machines: 0,
		lease: 0,
		indirectProductionCosts: 0,
		marketing: 0,
		storageCosts: 0,
		qualityAssurance: 0,
		indirectSalesCosts: 0,
		processOptimization: 0,
		otherOverheads: 0,
		interest: 0,
		taxes: 0,
		repayments: 0
	}
};

export default initState;