const startingKit = {
	national: {
		standard: {
			price: "",
			marketing: "",
			units: ""
		},
		compact: {
			price: "",
			marketing: "",
			units: ""
		},
		plus: {
			price: "",
			marketing: "",
			units: ""
		},
		luxus: {
			price: "",
			marketing: "",
			units: ""
		}
	},
	international: {
		standard: {
			price: "",
			marketing: "",
			units: ""
		},
		compact: {
			price: "",
			marketing: "",
			units: ""
		},
		plus: {
			price: "",
			marketing: "",
			units: ""
		},
		luxus: {
			price: "",
			marketing: "",
			units: ""
		}
	},
	intercontinental1: {
		standard: {
			price: "",
			marketing: "",
			units: ""
		},
		compact: {
			price: "",
			marketing: "",
			units: ""
		},
		plus: {
			price: "",
			marketing: "",
			units: ""
		},
		luxus: {
			price: "",
			marketing: "",
			units: ""
		}
	},
	intercontinental2: {
		standard: {
			price: "",
			marketing: "",
			units: ""
		},
		compact: {
			price: "",
			marketing: "",
			units: ""
		},
		plus: {
			price: "",
			marketing: "",
			units: ""
		},
		luxus: {
			price: "",
			marketing: "",
			units: ""
		}
	}
}

export default startingKit;