import React from "react";
import { useDrop } from 'react-dnd';

// Constants
import items from "../../../../../utils/items";
import colors from "../../../../../utils/colors";

// Components
import ColoredDraggableContainer from "./ColoredDraggableContainer";

export default function ColoredDroppableField(props) {

	const [{ isOver, canDrop }, drop] = useDrop({
		accept: items.productionContainer,
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		drop: (item) => {
			if (props.fieldType === item.cardColor) {
				props.appendContainer(item.coins, item.hasCard, item.cardValue, item.cardColor);

				return item
			}
			return {}
		},
	})

	const backgroundColor = (isOver && canDrop) ? colors.activeBackground : "";
	const canPlayerDrop = (props.canEdit) ? drop : null;

	return (
		<>
			{
				(props.data.hasContainer)?
					<div className="Colored-droppable-field">
						<ColoredDraggableContainer
							canEdit={props.canEdit}
							id={ props.id }
							data={ props.data }
							isHorizontal={ true }
							removeContainer={ props.removeContainer }
							isDraggingHandler={ props.isDraggingHandler }
						/>
					</div>
					:
					<div className="Colored-droppable-field" style={{ backgroundColor: backgroundColor }} ref={canPlayerDrop}></div>
			}
		</>
	);
}