import React from 'react';
import { Table, FormControl } from 'react-bootstrap';

import localization from "../../../../utils/localization";

class CashFlow extends React.Component {

	handleChange = (field, value) => {
		this.props.inputChanged("cashFlow", field, value)
	}

	render() {
		const selectedLang = this.props.selectedLang;

		const totalIncomes = parseFloat(this.props.cashFlow.salesRevenue) + parseFloat(this.props.cashFlow.otherIncomes);

		let totalOverhead = 0;
		totalOverhead += parseFloat(this.props.cashFlow.materialPurchase)
		totalOverhead += parseFloat(this.props.cashFlow.labour)
		totalOverhead += parseFloat(this.props.cashFlow.indirectRAndDCosts)
		totalOverhead += parseFloat(this.props.cashFlow.productDevelopmentCost)
		totalOverhead += parseFloat(this.props.cashFlow.productionFacilities)
		totalOverhead += parseFloat(this.props.cashFlow.machines)
		totalOverhead += parseFloat(this.props.cashFlow.lease)
		totalOverhead += parseFloat(this.props.cashFlow.indirectProductionCosts)
		totalOverhead += parseFloat(this.props.cashFlow.marketing)
		totalOverhead += parseFloat(this.props.cashFlow.storageCosts)
		totalOverhead += parseFloat(this.props.cashFlow.qualityAssurance)
		totalOverhead += parseFloat(this.props.cashFlow.indirectSalesCosts)
		totalOverhead += parseFloat(this.props.cashFlow.processOptimization)
		totalOverhead += parseFloat(this.props.cashFlow.otherOverheads)
		totalOverhead += parseFloat(this.props.cashFlow.interest)
		totalOverhead += parseFloat(this.props.cashFlow.taxes)

		const netCashFlow = totalIncomes - totalOverhead;


		let cashLevelAtEnd = parseFloat(this.props.cashFlow.cashLevelAtStart);
		cashLevelAtEnd += netCashFlow;
		cashLevelAtEnd += parseFloat(this.props.cashFlow.repayments);
		

		return (
			<Table bordered>
				<tbody>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].cashLevelAtStartOfYear }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.cashLevelAtStart }
								onChange={ (e) => this.handleChange("cashLevelAtStart", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].salesRevenue }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.salesRevenue }
								onChange={ (e) => this.handleChange("salesRevenue", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].otherIncomes }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.otherIncomes }
								onChange={ (e) => this.handleChange("otherIncomes", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].totalIncomes }</div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ totalIncomes } disabled />
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].materialsPurchase }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.materialPurchase }
								onChange={ (e) => this.handleChange("materialPurchase", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].labour }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.labour }
								onChange={ (e) => this.handleChange("labour", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].indirectRDCosts }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.indirectRAndDCosts }
								onChange={ (e) => this.handleChange("indirectRAndDCosts", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].productDevelopmentCost }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.productDevelopmentCost }
								onChange={ (e) => this.handleChange("productDevelopmentCost", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].purchaseOfProductionFacilities }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.productionFacilities }
								onChange={ (e) => this.handleChange("productionFacilities", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].purchaseOfMachines }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.machines }
								onChange={ (e) => this.handleChange("machines", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].lease }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.lease }
								onChange={ (e) => this.handleChange("lease", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].indirectProductionCosts }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.indirectProductionCosts }
								onChange={ (e) => this.handleChange("indirectProductionCosts", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].marketing }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.marketing }
								onChange={ (e) => this.handleChange("marketing", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].storageCosts }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.storageCosts }
								onChange={ (e) => this.handleChange("storageCosts", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].qualityAssurance }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.qualityAssurance }
								onChange={ (e) => this.handleChange("qualityAssurance", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].indirectSalesCosts }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.indirectSalesCosts }
								onChange={ (e) => this.handleChange("indirectSalesCosts", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].processOptimization }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.processOptimization }
								onChange={ (e) => this.handleChange("processOptimization", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].otherOverheads }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.otherOverheads }
								onChange={ (e) => this.handleChange("otherOverheads", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].interest }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.interest }
								onChange={ (e) => this.handleChange("interest", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].taxes }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.taxes }
								onChange={ (e) => this.handleChange("taxes", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].totalExpenditures }</div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ totalOverhead } disabled />
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].netCashFlow }</div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ netCashFlow } disabled />
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].creditRepayment }</div>
						</td>
						<td>
							<FormControl 
								aria-label="Small" 
								size="sm" 
								value={ this.props.cashFlow.repayments }
								onChange={ (e) => this.handleChange("repayments", e.target.value )} 
							/>
						</td>
					</tr>
					<tr>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>
							<div className="bold">{ localization[selectedLang].cashLevelAtYearEnd }</div>
						</td>
						<td>
							<FormControl aria-label="Small" size="sm" value={ cashLevelAtEnd } disabled />
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}

}

export default CashFlow;