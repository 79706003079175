import React, {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";

import ColoredDroppableHorizontalField from "./Fields/ColoredDroppableHorizontalField";

class StockRedBlock extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit
		}
	}

	render() {

		const fieldsList = this.props.data;
		const droppableFieldsList = fieldsList.map((value, key) => {
			if (key % 4 === 0) {
				return <React.Fragment key={key}>
					<div className="w-100"></div>
					<Col><ColoredDroppableHorizontalField canEdit={this.state.canEdit} id={key} type="red" fieldObject={value} /></Col>
				</React.Fragment>
			}

			return <Col key={key}><ColoredDroppableHorizontalField canEdit={this.state.canEdit} id={key} type="red" fieldObject={value} /></Col>
		})

		return (
			<Container className="Stock-red-block colored-block">
				<Row>
					{ droppableFieldsList }
				</Row>
			</Container>
		);
	}
}

export default StockRedBlock;
