const initState = {
	currentStep: 0,
	steps: [
		{
			position: 'logo',
			title: "Welcome To SIMFlex Guided Tour!",
			message: "We will walk you through the digital version of SIMFlex and how to use the interface.",
			validation: {}
		},
		{
			position: 'teamName',
			title: "This is your team name",
			message: "All team members can change the name by clicking on it.",
			validation: {}
		},
		{
			position: 'boardYear',
			title: "",
			message: "This is the current session year",
			validation: {}
		},
		{
			position: 'zoomFeature',
			title: "Zoom in and out",
			message: "You can freely zoom in or out to navigate on the board.",
			validation: {}
		},
		{
			position: 'chatIcon',
			title: "Chat!",
			message: "You can chat with your teammates to collaborate on ideas and strategies. Click to open the chat window",
			validation: {}
		},
		{
			position: 'counter',
			title: "",
			message: "When a task is up, this counter will remind your team of the task deadline.",
			validation: {}
		},
		{
			position: 'language',
			title: "Languages",
			message: "You can control your checklist and documents’ language by clicking here. N.B: currently available in English & German.",
			validation: {}
		},
		{
			position: 'checklist',
			title: "Checklist",
			message: "Here is your checklist for each year. Your facilitator will guide you through the steps. Click to expand and collapse.",
			validation: {}
		},
		{
			position: 'fileLibrary',
			title: "Files Library",
			message: "All your team’s files can be accessed from here. Click to expand and collapse.",
			validation: {}
		},
		/*{
			position: 'strategyPaper',
			title: "Strategy Paper",
			message: "Align with your team on the year strategy. During the live session your team will align in real-time on each year’s strategy."
		},*/
		{
			position: 'teamTable',
			title: "Team Table",
			message: "Here is your team’s table. Click to access your team’s resources.",
			validation: {}
		},
		{
			position: 'cash',
			title: "",
			message: "This is your cash area. Drag an empty container from your team’s table to this field.",
			validation: {
				type: "container"
			}
		},
		{
			position: 'cash',
			title: "",
			message: "Great! Now fill up cash -> Move ALL coins to cash total 60.5.",
			validation: {
				type: "coins",
				value: 60.5
			}
		},
		{
			position: 'liabilities',
			title: "",
			message: "This year you took up credit for 30 (5% interest rate). Drag the red liability token to this field.",
			validation: {
				type: "token",
				value: 30
			}
		},
		{
			position: 'RAndD',
			title: "",
			message: "Drag an empty container and click on your cash to subtract 6 coins from your cash to represent R & D investment.",
			validation: {
				type: "container-subtract",
				value: 6
			}
		},
		{
			position: 'RAndD',
			title: "",
			message: "The value you subtracted is now back to your team table. You can drag or click on the container to add subtracted coins.",
			validation: {
				type: "coins",
				value: 6
			}
		},
		{
			position: 'purchasing',
			title: "",
			message: "Time to buy your material. Drag an empty container over to this field and fill it with 13 from your cash. Drag and drop or click to open the add and subtract box.",
			validation: {
				type: "container-coins",
				value: 13
			}
		},
		{
			position: 'production',
			title: "",
			message: "These are your production lines in different markets."
		},
		{
			position: 'factory',
			title: "",
			message: "This year you will build a factory in the national market. Drag an empty container from your team table.",
			validation: {
				type: "container"
			}
		},
		{
			position: 'factory',
			title: "",
			message: "Now make an investment by adding 10 coins from your cash.",
			validation: {
				type: "coins",
				value: 10
			}
		},
		{
			position: 'factory',
			title: "",
			message: "Good! Now you can drag the white factory label and add it to this container to display your factory ownership.",
			validation: {
				type: "label"
			}
		},
		{
			position: 'productionPin',
			title: "Purchase machines",
			message: "Please add your pin to production medium.",
			validation: {
				type: "pin"
			}
		},
		{
			position: 'machine',
			title: "Invest in machinery",
			message: "Add an empty container then add 7.5 coins from your cash to purchase a medium machine.",
			validation: {
				type: "container-coins",
				value: 7.5
			}
		},
		{
			position: 'machine',
			title: "",
			message: "Good! Now you can drag the blue machine label.",
			validation: {
				type: "label"
			}
		},
		{
			position: 'salesPin',
			title: "",
			message: "Please add your pin to sales national.",
			validation: {
				type: "pin"
			}
		},
		{
			position: 'product',
			title: "",
			message: "Drag an empty container to this field.",
			validation: {
				type: "container"
			}
		},
		{
			position: 'product',
			title: "",
			message: "This year your factory has produced 5 units of the standard product. Now drag the green product label to it.",
			validation: {
				type: "label"
			}
		},
		{
			position: 'product2',
			title: "",
			message: "Now add 10 coins from material.",
			validation: {
				type: "coins",
				value: 10
			}
		},
		{
			position: 'product',
			title: "",
			message: "Now add 10 coins from cash to cover labor cost.",
			validation: {
				type: "coins",
				value: 20
			}
		},
		{
			position: 'qualityAssurance',
			title: "",
			message: "Now add container and 1 coin from cash to quality assurance.",
			validation: {
				type: "coins",
				value: 1
			}
		},
		{
			position: 'greenBlock',
			title: "",
			message: "Now move your finished product to stock!",
			validation: {
				type: "coins",
				value: 20
			}
		},
		{
			position: 'fileLibrary2',
			title: "Files Library",
			message: "Time to set your sales decisions for this year. Open up your files library and go to decision sheet."
		},
		{
			position: 'decisionSheet',
			title: "",
			message: "Here your team aligns on your market strategies"
		},
		{
			position: 'decisionSheet2',
			title: "",
			message: "This year you decisions are preset to (standard/national = Price 6 / Marketing 0 / Units 5)"
		},
		{
			position: 'sellProducts',
			title: "",
			message: "Now move your products from stock to direct costs!",
			validation: {
				type: "coins",
				value: 20
			}
		},
		{
			position: 'teamTable-2',
			title: "Got sales revenues",
			message: "Now you got 30 coins sales revenues in your cash",
			validation: {}
		},
		{
			position: 'cash',
			title: "",
			message: "Now fill up cash -> Move ALL coins to cash",
			validation: {
				type: "coins",
				value: 43
			}
		},
		{
			position: 'indirectProductionCost',
			title: "",
			message: "Now add container and 1.5 coins from cash to indirect production cost.",
			validation: {
				type: "coins",
				value: 1.5
			}
		},
		{
			position: 'depreciationOfCapitalized',
			title: "",
			message: "Now add container and 1 coins from R & D to depreciation of capitalized development costs.",
			validation: {
				type: "coins",
				value: 1
			}
		},
		{
			position: 'depreciationOfProductionPlanet',
			title: "",
			message: "Now add container and 0.5 coins from facility/plant to depreciation of production plants.",
			validation: {
				type: "coins",
				value: 0.5
			}
		},
		{
			position: 'depreciationOfMachine',
			title: "",
			message: "Now add container and 1.5 coins from machines to depreciation of machine.",
			validation: {
				type: "coins",
				value: 1.5
			}
		},
		{
			position: 'indirectRAndD',
			title: "",
			message: "Now add container and 5 coins from cash to indirect R & D costs.",
			validation: {
				type: "coins",
				value: 5
			}
		},
		{
			position: 'material',
			title: "",
			message: "Now move 10 coins from cash to materials.",
			validation: {
				type: "container-coins",
				value: 13
			}
		},
		{
			position: 'interest',
			title: "",
			message: "Now move 1.5 coins from cash to interest.",
			validation: {
				type: "coins",
				value: 1.5
			}
		},
		{
			position: 'center',
			title: "",
			message: "Great your tour has ended. Your facilitator will answer any questions in your live session. You can replay the tour by refreshing this page."
		},
		{
			position: 'fileLibrary2',
			title: "Files Library",
			message: "Time to set your balance sheet, P&L and cash flow for this year. Open up your files library and go to balance sheet, P&L and cash flow."
		},
		{
			position: 'end'
		}
	]
};

export default initState;