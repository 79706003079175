import React, { Component } from "react";

import TokenProperties from "./TokenProperties";
import ContainerProperties from "./ContainerProperties";

class Properties extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit
		}
	}


	render() {
		return (
			<>
				{
					(this.props.properties.type === "token")?
						<TokenProperties canEdit={this.state.canEdit} startingKit={this.props.startingKit} properties={this.props.properties} board={this.props.board} closePanel={this.props.closePanel} />
						:
						<ContainerProperties
							canEdit={this.state.canEdit}
							startingKit={this.props.startingKit}
							properties={this.props.properties}
							board={this.props.board}
							closePanel={this.props.closePanel}
						/>
				}
			</>
		);
	}
}

export default Properties;