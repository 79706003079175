import React, {Component} from "react";
import { connect } from "react-redux";
import {Col, Container, Row} from "react-bootstrap";

// Constants
import dropFieldClassNames from "../../../../utils/dropFieldClassNames";
import items from "../../../../utils/items";

// Dispatchers
import mapDispatchToProps from "../../../../reducers/dispatchers";

// Components
import NumberBox from "../../Common/NumberBox";
import ProductionDroppablePin from "./ProductionDroppablePin";
import DroppableField from "../../Common/DroppableField";

class ProductionBlockSection extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit,
			id: props.id,
			title: props.title
		}
	}

	appendPin = (title, speed, id) => {
		this.props.addPin("Production", title, speed, id)
	}

	removePin = (title, speed, id) => {
		this.props.removePin("Production", title, speed, id)
	}

	appendContainer = (fieldType, coins, id, hasCard, cardValue, cardColor) => {
		this.props.addContainerFieldType("Production", fieldType, coins, id, hasCard, cardValue, cardColor, window.playerId, window.playerName);
	}

	removeContainer = (fieldType, id, isDropToDelete) => {
		this.props.removeContainerFieldType("Production", fieldType, id);
	}

	addCoin = (fieldType, coinValue, id) => {
		this.props.addCoinFieldType("Production", fieldType, coinValue, id, window.playerId, window.playerName);
	}

	removeCoin = (fieldType, index, id, isDropToDelete) => {
		this.props.removeCoinFieldType("Production", fieldType, index, id, window.playerId, window.playerName);
	}

	addCard = (optionType, id, value, color) => {
		this.props.addCard("Production", optionType, id, value, color);
	}

	showContainerProperties = (optionType) => {
		this.props.openContainerPropertiesWithOption('FIELD', 'Production', optionType, this.state.id);
	}

	renderNumbers(index) {
		switch(this.state.title.toLowerCase()) {
			case "national":
			case "international":
				if (index === 0 || index === 1 || index === 2){
					return (
						<NumberBox isProduction={true} number={2}></NumberBox>
					);
				}else{
					return (
						<NumberBox isProduction={true} number={3}></NumberBox>
					);
				}
			case "inter-continental 1":
				if (index === 0 || index === 1 || index === 2){
					return (
						<NumberBox isProduction={true} number={1.5}></NumberBox>
					);
				}else{
					return (
						<NumberBox isProduction={true} number={2.5}></NumberBox>
					);
				}
			case "inter-continental 2":
				if (index === 0 || index === 1 || index === 2){
					return (
						<NumberBox isProduction={true} number={1}></NumberBox>
					);
				}else{
					return (
						<NumberBox isProduction={true} number={2}></NumberBox>
					);
				}
			default:
				break;
		}
	}

	onGuidedTourNext = () => {
		this.props.nextGuidedTour();
	}
	onGuidedTourPrev = () => {
		this.props.prevGuidedTour();
	}
	
	render() {
		let coinsValue = 0;
		let coinsMachineValue = 0;
		let coinsProductValue = 0;
		if (this.state.id === 0 && this.state.title === "National") {
			if (this.props.production[this.state.id].factory.hasContainer) {
				const coins = this.props.production[this.state.id].factory.coins
				if (coins.length > 0) {
					coinsValue = coins.reduce((total, num) => {
						return parseFloat(total) + parseFloat(num);
					})
				}
			}
			if (this.props.production[this.state.id].machine.hasContainer) {
				const coins = this.props.production[this.state.id].machine.coins
				if (coins.length > 0) {
					coinsMachineValue = coins.reduce((total, num) => {
						return parseFloat(total) + parseFloat(num);
					})
				}
			}
			if (this.props.production[this.state.id].product.hasContainer) {
				const coins = this.props.production[this.state.id].product.coins
				if (coins.length > 0) {
					coinsProductValue = coins.reduce((total, num) => {
						return parseFloat(total) + parseFloat(num);
					})
				}
			}
		}

		return (
			<Container className="Production-block-container">
				<Row className="custom-height">
					<Col xs={4}>
						<div className="Block-title-bold-text Production-title-text-size Same-line">Region</div>
						<div className="Production-title-text-size Same-line">region</div>
						<div className="Production-title-text-size Text-italic Same-line">región</div>
					</Col>
					<Col>
						<div className="Block-title-bold-text Production-title-text-size Same-line">Lohnkosten/Serie</div>
						<div className="Production-title-text-size Same-line">labor costs/unit</div>
						<div className="Production-title-text-size Text-italic Same-line">coste salarial</div>
					</Col>
				</Row>
				<Row>
					<Col xs={4}>
						<div style={{ display: "table", width: "90%" }}>
							<div className="production-block-title-box">
								<div className="production-block-title-box-text">
									{ this.state.title }
								</div>
							</div>
						</div>
					</Col>
					<Col>
						<Container>
							<Row>
								<Col className="">
									<div className="standard height">
										<div className="full-width-height margin-V-Center">
											{ this.renderNumbers(0) }
										</div>
									</div>
								</Col>
								<Col className="">
									<div className="compact height">
										<div className="full-width-height margin-V-Center">
											{ this.renderNumbers(1) }
										</div>
									</div>
								</Col>
								<Col className="">
									<div className="plus height">
										<div className="full-width-height margin-V-Center">
											{ this.renderNumbers(2) }
										</div>
									</div>
								</Col>
								<Col className="">
									<div className="luxus height">
										<div className="full-width-height margin-V-Center">
											{ this.renderNumbers(3) }
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
				<Row className="custom-height">
					<Col xs={4}>
						<div className="Block-title-bold-text Production-sub-title-text-size">Maschine</div>
						<div className="Production-sub-title-text-size">machine</div>
						<div className="Production-sub-title-text-size Text-italic">máquina</div>
					</Col>
					<Col>
						<Container>
							<Row>
								<Col>
									<div className="standard custom-height"> &nbsp; </div>
								</Col>
								<Col>
									<div className="compact custom-height"> &nbsp; </div>
								</Col>
								<Col>
									<div className="plus custom-height"> &nbsp; </div>
								</Col>
								<Col>
									<div className="luxus custom-height"> &nbsp; </div>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
				<Row className="custom-row-line">
					<Col xs={4} className="zIndex-3">
						<div className="full-width-height margin-V-Center">
							<Row className="speed-row-text margin-V-Center">
								<Col xs={4}>
									<div className="speed-text center">
										<span className="bold-text">F</span>
									</div>
								</Col>
								<Col className="margin-V-Center">
									<div className="speed-text">fast</div>
								</Col>
							</Row>
						</div>
					</Col>
					<Col>
						<Container>
							<Row>
								<Col>
									<div className="standard custom-height-17">
										<ProductionDroppablePin
											canEdit={this.state.canEdit}
											id={this.state.id}
											title="standard"
											speed="fast"
											hasPin={this.props.production[this.state.id].pins.fast.standard}
											appendPin={this.appendPin}
											removePin={this.removePin}
										/>
									</div>
								</Col>
								<Col>
									<div className="compact custom-height-17">
										<ProductionDroppablePin
											canEdit={this.state.canEdit}
											id={this.state.id}
											title="compact"
											speed="fast"
											hasPin={this.props.production[this.state.id].pins.fast.compact}
											appendPin={this.appendPin}
											removePin={this.removePin}
										/>
									</div>
								</Col>
								<Col>
									<div className="plus custom-height-17">
										<ProductionDroppablePin
											canEdit={this.state.canEdit}
											id={this.state.id}
											title="plus"
											speed="fast"
											hasPin={this.props.production[this.state.id].pins.fast.plus}
											appendPin={this.appendPin}
											removePin={this.removePin}
										/>
									</div>
								</Col>
								<Col>
									<div className="luxus custom-height-17">
										<ProductionDroppablePin
											canEdit={this.state.canEdit}
											id={this.state.id}
											title="luxus"
											speed="fast"
											hasPin={this.props.production[this.state.id].pins.fast.luxus}
											appendPin={this.appendPin}
											removePin={this.removePin}
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
				<Row className="custom-row-line">
					<Col xs={4} className="zIndex-3">
						<div className="full-width-height margin-V-Center">
							<Row className="speed-row-text margin-V-Center">
								<Col xs={4}><div className="speed-text center"><span className="bold-text">M</span></div></Col>
								<Col className="margin-V-Center"><div className="speed-text">medium</div></Col>
							</Row>
						</div>
					</Col>
					<Col>
						<Container>
							<Row>
								<Col>
									<div className="standard custom-height-17">
										<ProductionDroppablePin
											canEdit={this.state.canEdit}
											id={this.state.id}
											title="standard"
											speed="medium"
											hasPin={this.props.production[this.state.id].pins.medium.standard}
											appendPin={this.appendPin}
											removePin={this.removePin}
										/>
										{
											(this.state.id === 0 && this.state.title === "National" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "productionPin") ?
												<div className="pinTourPopup">
													<div className="upArrow"></div>
													<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
													<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

													<div className="actions">
														<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
															Prev
														</div>
														{
															(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "pin" && this.props.production[this.state.id].pins.medium.standard) ?
																<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
																	Next
																</div>
																:
																null
														}
													</div>
												</div>
											:
												null
										}
									</div>
								</Col>
								<Col>
									<div className="compact custom-height-17">
										<ProductionDroppablePin
											canEdit={this.state.canEdit}
											id={this.state.id}
											title="compact"
											speed="medium"
											hasPin={this.props.production[this.state.id].pins.medium.compact}
											appendPin={this.appendPin}
											removePin={this.removePin}
										/>
									</div>
								</Col>
								<Col>
									<div className="plus custom-height-17">
										<ProductionDroppablePin
											canEdit={this.state.canEdit}
											id={this.state.id}
											title="plus"
											speed="medium"
											hasPin={this.props.production[this.state.id].pins.medium.plus}
											appendPin={this.appendPin}
											removePin={this.removePin}
										/>
									</div>
								</Col>
								<Col>
									<div className="luxus custom-height-17">
										<ProductionDroppablePin
											canEdit={this.state.canEdit}
											id={this.state.id}
											title="luxus"
											speed="medium"
											hasPin={this.props.production[this.state.id].pins.medium.luxus}
											appendPin={this.appendPin}
											removePin={this.removePin}
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
				<Row className="custom-row-line">
					<Col xs={4} className="zIndex-3">
						<div className="full-width-height margin-V-Center">
							<Row className="speed-row-text margin-V-Center">
								<Col xs={4}><div className="speed-text center"><span className="bold-text">S</span></div></Col>
								<Col className="margin-V-Center"><div className="speed-text">slow</div></Col>
							</Row>
						</div>
					</Col>
					<Col>
						<Container>
							<Row>
								<Col>
									<div className="standard custom-height-17">
										<ProductionDroppablePin
											canEdit={this.state.canEdit}
											id={this.state.id}
											title="standard"
											speed="slow"
											hasPin={this.props.production[this.state.id].pins.slow.standard}
											appendPin={this.appendPin}
											removePin={this.removePin}
										/>
									</div>
								</Col>
								<Col>
									<div className="compact custom-height-17">
										<ProductionDroppablePin
											canEdit={this.state.canEdit}
											id={this.state.id}
											title="compact"
											speed="slow"
											hasPin={this.props.production[this.state.id].pins.slow.compact}
											appendPin={this.appendPin}
											removePin={this.removePin}
										/>
									</div>
								</Col>
								<Col>
									<div className="plus custom-height-17">
										<ProductionDroppablePin
											canEdit={this.state.canEdit}
											id={this.state.id}
											title="plus"
											speed="slow"
											hasPin={this.props.production[this.state.id].pins.slow.plus}
											appendPin={this.appendPin}
											removePin={this.removePin}
										/>
									</div>
								</Col>
								<Col>
									<div className="luxus custom-height-17">
										<ProductionDroppablePin
											canEdit={this.state.canEdit}
											id={this.state.id}
											title="luxus"
											speed="slow"
											hasPin={this.props.production[this.state.id].pins.slow.luxus}
											appendPin={this.appendPin}
											removePin={this.removePin}
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
				<Row style={{"marginTop": "5px"}}>
					<div style={{ "width": "37%" }}>
						<Container>
							<Row style={{ position: 'relative' }}>
								<div className="vertical-text-container">
									<div className="vertical-text first text-bold">gekayfte Produktionsstätte</div>
									<div className="vertical-text second">purchased production plant</div>
									<div className="vertical-text third">planta de producción comprada</div>
								</div>
								<div className="production-field-container" onClick={this.showContainerProperties.bind(this, "factory")}>
									<DroppableField
										canEdit={this.state.canEdit}
										class={ dropFieldClassNames.Production }
										id={ this.state.id }
										data={ this.props.production[this.state.id].factory }
										containerType={items.factoryContainer}
										productionType={ "factory" }
										isHorizontal={ false }
										appendContainer={ this.appendContainer }
										removeContainer={ this.removeContainer }
										addCoin={ this.addCoin }
										removeCoin={ this.removeCoin }
										addCard={ this.addCard }
									/>
								</div>
								{
									(this.state.id === 0 && this.state.title === "National" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "factory") ?
										<div className="factoryTourPopup">
											<div className="upArrow"></div>
											<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
											<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

											<div className="actions">
												<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
													Prev
												</div>
												{
													(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "container" && this.props.production[this.state.id].factory.hasContainer) ?
														<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
															Next
														</div>
														:
														null
												}
												{
													(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "coins" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value === coinsValue) ?
														<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
															Next
														</div>
														:
														null
												}
												{
													(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "label" && this.props.production[this.state.id].factory.hasCard) ?
														<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
															Next
														</div>
														:
														null
												}
											</div>
										</div>
									:
										null
								}
							</Row>
						</Container>
					</div>
					<div style={{ "width": "31.5%" }}>
						<Container>
							<Row style={{ position: 'relative' }}>
								<div className="vertical-text-container2">
									<div className="vertical-text second text-bold">Maschine machine máquina</div>
								</div>
								<div className="production-field-container2" onClick={this.showContainerProperties.bind(this, "machine")}>
									<DroppableField
										canEdit={this.state.canEdit}
										class={ dropFieldClassNames.Production }
										id={ this.state.id }
										data={ this.props.production[this.state.id].machine }
										containerType={items.machineContainer}
										productionType={ "machine" }
										isHorizontal={ false }
										appendContainer={ this.appendContainer }
										removeContainer={ this.removeContainer }
										addCoin={ this.addCoin }
										removeCoin={ this.removeCoin }
										addCard={ this.addCard }
									/>
								</div>
								{
									(this.state.id === 0 && this.state.title === "National" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "machine") ?
										<div className="factoryTourPopup">
											<div className="upArrow"></div>
											<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
											<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

											<div className="actions">
												<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
													Prev
												</div>
												{
													(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "container-coins" && this.props.production[this.state.id].machine.hasContainer && this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value === coinsMachineValue) ?
														<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
															Next
														</div>
														:
														null
												}
												{
													(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "label" && this.props.production[this.state.id].machine.hasCard) ?
														<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
															Next
														</div>
														:
														null
												}
											</div>
										</div>
									:
										null
								}
							</Row>
						</Container>
					</div>
					<div style={{ "width": "31.5%" }}>
						<Container>
							<Row style={{ position: 'relative' }}>
								<div className="vertical-text-container2">
									<div className="vertical-text second text-bold">Produkte products productos</div>
								</div>
								<div className="production-field-container2" onClick={this.showContainerProperties.bind(this, "product")}>
									<DroppableField
										canEdit={this.state.canEdit}
										class={ dropFieldClassNames.Production }
										id={ this.state.id }
										data={ this.props.production[this.state.id].product }
										containerType={items.productionContainer}
										productionType={ "product" }
										isHorizontal={ false }
										appendContainer={ this.appendContainer }
										removeContainer={ this.removeContainer }
										addCoin={ this.addCoin }
										removeCoin={ this.removeCoin }
										addCard={ this.addCard }
									/>
								</div>
								{
									(this.state.id === 0 && this.state.title === "National" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "product") ?
										<div className="factoryTourPopup">
											<div className="upArrow"></div>
											<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
											<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

											<div className="actions">
												<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
													Prev
												</div>
												{
													(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "container" && this.props.production[this.state.id].product.hasContainer) ?
														<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
															Next
														</div>
														:
														null
												}
												{
													(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "coins" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value === coinsProductValue) ?
														<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
															Next
														</div>
														:
														null
												}
												{
													(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "label" && this.props.production[this.state.id].product.hasCard) ?
														<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
															Next
														</div>
														:
														null
												}
											</div>
										</div>
									:
										null
								}
								{
									(this.state.id === 0 && this.state.title === "National" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "product2") ?
										<div className="factoryTourPopup">
											<div className="upArrow"></div>
											<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
											<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

											<div className="actions">
												<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
													Prev
												</div>
												{
													(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "coins" && this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value === coinsProductValue) ?
														<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
															Next
														</div>
														:
														null
												}
											</div>
										</div>
									:
										null
								}
							</Row>
						</Container>
					</div>
				</Row>
			</Container>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		production: state.board.CenterBlock.Production,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductionBlockSection);