const initState = {
	en: [
			{
				title: "Preparation Of The Year",
				list: [
					{
						title: "1. Purchase of forecast",
						value: "3 per market or product",
						actions: ["Transfer from cash to \"indirect sales costs\""]
					},
					{
						title: "2. Strategic planning",
						value: "",
						actions: [
							"Complete strategy paper and decide about credit"
						]
					},
					{
						title: "3. As required take up credits or repay them",
						value: "max. equity x 5 and long-term > short-term",
						actions: [
							"Take the money from bank and transfer to cash or repay credit from cash to bank"
						]
					}
				]
			},
			{
				title: "Investment",
				list: [
					{
						title: "4. Develop new products",
						value: "Depending on product",
						actions: [
							"Pay from cash to \"Capitalized development costs\" (for stopped projects 0.5 each to \"indirect R & D costs\")"
						]
					},
					{
						title: "5. Open up new markets",
						value: "2 per market and year",
						actions: [
							"Pay from cash to \"indirect sales costs\" (for stopped projects 0.5 each to \"indirect sales costs\"",
							"If a market is developed it can be used in the following year"
						]
					},
					{
						title: "6. Purchase or rent production facilities",
						value: "Two years at 5 per production facility",
						actions: [
							"On Purchase pay from cash to \"production plant\" (per production facility ten value tokens of 0.5) if you rent to \"lease\" 3 per production facility",
						]
					},
					{
						title: "7. Purchase machines",
						value: "Fast 10 - Medium 7,5 - Slow 5",
						actions: [
							`Pay from cash to "machine"`
						]
					}
				]
			},
			{
				title: "Product manufacture",
				list: [
					{
						title: "8. Produce",
						value: "Depending on direct costs",
						actions: [
							"Transfer the assets from inventories to production",
							"Depending on machine max. 4/6/10 units",
							"Pay wages from cash assets to products"
						]
					},
					{
						title: "9. Quality assurance",
						value: "1 per production facility",
						actions: [
							"Pay from cash to \"quality assurance\""
						]
					}
				]
			},
			{
				title: "Product sales",
				list: [
					{
						title: "10. Prepare for sales",
						value: "",
						actions: [
							"Transfer the assets from production to \"stock\"",
							"Complete the decision sheet"
						]
					},
					{
						drawRedLine: true,
						title: "11. Pay marketing",
						value: "As per decision sheet",
						actions: [
							"Pay \"marketing\" from cash assets"
						]
					},
					{
						title: "12. Sell",
						value: "",
						actions: [
							"Transfer the assets from stock to \"direct costs\"",
							"Get your sales revenue from the trainer and put them in cash assets"
						]
					},
					{
						title: "13. Pay for storage costs",
						value: "0.5 per unit",
						actions: [
							"For each unit in stock pay from cash to \"storage costs\""
						]
					},
					{
						title: "14. Pay for non-delivery of goods",
						value: "2 per unit",
						actions: [
							"For each promised but not delivered unit pay from cash to \"other overheads\""
						]
					},
					{
						title: "15. Pay logistics costs",
						value: "2 per market",
						actions: [
							"For each market that was delivered in but which did not have a production facility pay from cash to \"other overheads\""
						]
					}
				]
			},
			{
				title: "Annual financial statements",
				list: [
					{
						title: "16. Pay administration costs",
						value: "0.5 plus 1 per production facility",
						actions: [
							"Pay \"indirect production costs\" from cash assets"
						]
					},
					{
						title: "17. Write-down capitalized development costs",
						value: "1 per full-developed product",
						actions: [
							"Transfer the assets from \"capitalized development costs\" to \"depreciation of capitalized development costs\""
						]
					},
					{
						title: "18. Write-down production facilities",
						value: "0.5 per completed production facility",
						actions: [
							"Transfer the assets from \"production\" to \"depreciation of production plants\""
						]
					},
					{
						title: "19. Write-down machines",
						value: "Per Fast: 2 - Medium: 1,5 - Slow: 1",
						actions: [
							"Transfer the assets from \"machine\" to \"depreciation of machines\""
						]
					},
					{
						title: "20. Pay R&D overheads",
						value: "At start 5",
						actions: [
							"Pay \"indirect R&D costs\" from cash assets"
						]
					},
					{
						title: "21. Pay process optimization",
						value: "",
						actions: [
							"Pay from cash to \"process optimization\" to reduce R&D overheads"
						]
					},
					{
						title: "22. Purchase materials",
						value: `Depending on product \n
							From 25 units/product -5% \n
							From 35 unites/product -10%`,
						actions: [
							"Pay \"inventories\" from cash assets",
							"Get your discount from the trainer and put it in other income"
						]
					},
					{
						title: "Pay interest",
						value: "5% or 10%",
						actions: [
							"Pay \"interest\" from cash assets"
						]
					},
					{
						title: "24. Pay taxes",
						value: "25%",
						actions: [
							"Prepare the income statement",
							"Pay \"taxes\" from cash assets",
							"Prepare the balance-sheet"
						]
					}
				]
			}
		],
	de: [
		{
			title: "Vorbereitung des Jahres",
			list: [
				{
					title: "1. Prognosekauf",
					value: "Je Markt oder Produkt 3",
					actions: [
						"Zahlen Sie aus der Kasse an \"Gemeinkosten Vertrieb\""
					]
				},
				{
					title: "2. Strategieplanung",
					value: "",
					actions: [
						"Füllen Sie das Strategiepapier aus und entscheiden Sie über Kredite"
					]
				},
				{
					title: "3. Nehmen Sie bei Bedarf Kredite auf oder zahlen diese zurück",
					value: "maximal EK x 5 und langfristig > kurzfristig",
					actions: [
						"Holen Sie das Geld von der Bank und führen es der Kasse zu bzw. zahlen Sie Kredite aus der Kasse an die Bank zurück"
					]
				}
			]
		},
		{
			title: "Investitionen",
			list: [
				{
					title: "4. Entwickeln Sie neue Produkte",
					value: "Je nach Produkt",
					actions: [
						"Zahlen Sie aus der Kasse an \"aktivierte Entwicklungskosten\" (für gestoppte Projekte je 0.5 an \"Gemeinkosten F&E\")"
					]
				},
				{
					title: "5. Erschließen Sie neue Märkte",
					value: "2 je Markt und Jahr",
					actions: [
						"Zahlen Sie aus der Kasse an \"Gemeinkosten Vertrieb\" (für gestoppte Projekte je 0.5 in \"Gemeinkosten Vertrieb\")",
						"Ist ein Markt erschlossen, kann dieser im nächsten Jahr genutzt werden"
					]
				},
				{
					title: "6. Kaufen oder mieten Sie Produktionsstätten",
					value: "Zwei Jahre je 5 pro Produktionsstätte",
					actions: [
						"Bei Kauf zahlen Sie aus der Kasse an \"Produktionsstätte\" (je Produktionsstätte zehn 0.5 Wertmarken), wenn Sie mieten an \"Miete\" je Produktionsstätte 3",
					]
				},
				{
					title: "7. Kaufen Sie Maschinen",
					value: "Fast 10 - Medium 7,5 - Slow 5",
					actions: [
						`Zahlen Sie aus der Kasse an \"Maschine\"`
					]
				}
			]
		},
		{
			title: "Produkterstellung",
			list: [
				{
					title: "8. Produzieren Sie",
					value: "Ja nach Einzelkosten",
					actions: [
						"Verschieben Sie die Werte aus den Vorräten in die Produktion",
						"Je nach Maschine maximal 4/6/10 Serien",
						"Zahlen Sie Löhne aus der Kasse an \"Produkte\""
					]
				},
				{
					title: "9. Qualitätssicherung",
					value: "1 je Produktionsstätte",
					actions: [
						"Zahlen Sie aus der Kasse an \"Qualitätssicherung\""
					]
				}
			]
		},
		{
			title: "Produktvertrieb",
			list: [
				{
					title: "10. Bereiten Sie den Verkauf vor",
					value: "",
					actions: [
						"Verschieben Sie die Werte aus der Produktion in das Lager",
						"Füllen Sie das Entscheidungsblatt aus"
					]
				},
				{
					drawRedLine: true,
					title: "11. Zahlen Sie Marketing",
					value: "Nach Entscheidungsblatt",
					actions: [
						"Zahlen Sie aus der Kasse an \"Marketing\""
					]
				},
				{
					title: "12. Verkaufen Sie",
					value: "",
					actions: [
						"Verschieben Sie die Werte aus dem Lager an \"Einzelkosten\"",
						"Holen Sie lhre Umsatzerlöse von der Spielleitung und führen diese der Kasse zu"
					]
				},
				{
					title: "13. Zahlen Sie für Lagerkosten",
					value: "0.5 je Serie",
					actions: [
						"Für jede im Lager stehende Serie zahlen Sie aus der Kasse an \"Lagerkosten\""
					]
				},
				{
					title: "14. Zahlen Sie für nicht gelieferte Ware",
					value: "2 je Serie",
					actions: [
						"Für jede zugesagte und nicht gelieferte Serie zahlen Sie aus der Kasse an \"sonstige Gemeinkosten\""
					]
				},
				{
					title: "15. Zahlen Sie Logistikkosten",
					value: "2 je Markt",
					actions: [
						"Für jeden belieferten Markt ohne eigene Produktionsstätte zahlen Sie aus der Kasse an \"sonstige Gemeinkosten\""
					]
				}
			]
		},
		{
			title: "Annual financial statements",
			list: [
				{
					title: "16. Zahlen Sie Verwaltungskosten",
					value: "0.5 plus 1 je Prod uktionsstatte",
					actions: [
						"Zahlen Sie aus der Kasse an \"Gemeinkosten Produktion\""
					]
				},
				{
					title: "17. Schreiben Sie aktivierte Entwicklungskosten ab",
					value: "1 je fertig entwickeltes",
					actions: [
						"Verschieben Sie die Werte von \"aktivierte Entwick­ lungskosten\" an \"Abschreibung auf aktivierte Entwicklungskosten\""
					]
				},
				{
					title: "18. Schreiben Sie Produktionsstätten ab",
					value: "0.5 je fertiger Produktionsstätte",
					actions: [
						"Verschieben Sie die Werte von \"Produktionsstätte\" an \"Abschreibung auf Produktionsstätten\""
					]
				},
				{
					title: "19. Schreiben Sie Maschinen ab",
					value: "Je Fast: 2 - Je Medium: 1,5 - Je Slow: 1",
					actions: [
						"Verschieben Sie die Werte von \"Maschine\" an \"Abschreibung auf Maschinen\""
					]
				},
				{
					title: "20. Zahlen Sie Gemeinkosten F & E",
					value: "Zu Beginn 5",
					actions: [
						"Zahlen Sie aus der Kasse an \"Gemeinkosten F & E\""
					]
				},
				{
					title: "21. Zahlen Sie für Prozessoptimierung",
					value: "",
					actions: [
						"Zahlen Sie aus der Kasse an \"Prozessoptimierung\" um die Gemeinkosten F & E zu senken"
					]
				},
				{
					title: "22. Kaufen Sie Material ein",
					value: `Je nach Produkt \n
						Ab 25 Serien/Produkt -5% \n
						Ab 35 Serien/Produkt -10%`,
					actions: [
						"Zahlen Sie aus der Kasse an \"Vorräte\"",
						"Holen Sie sich gegebenenfalls lhren Rabatt von der Spielleitung"
					]
				},
				{
					title: "23. Zahlen Sie Zinsen",
					value: "5% bzw. 10%",
					actions: [
						"Zahlen Sie aus der Kasse an \"Zinsen\""
					]
				},
				{
					title: "24. Zahlen Sie Steuern",
					value: "25%",
					actions: [
						"Erstellen Sie die GuV",
						"Zahlen Sie aus der Kasse an \"Steuern\"",
						"Erstellen Sie die Bilanz"
					]
				}
			]
		}
	]
}

export default initState;