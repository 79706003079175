import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Image } from 'react-bootstrap';

// Constants
import dropFieldClassNames from "../../../utils/dropFieldClassNames";

// Dispatcher
import mapDispatchToProps from "../../../reducers/dispatchers";

// Images
import rightArrow from "../../../assets/images/right-arrow.png";

// Components
import DroppableField from "../Common/DroppableField";


class RightBlock extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit,
			id: props.id,
			germanTitle: props.germanTitle,
			englishTitle: props.englishTitle,
			spanishTitle: props.spanishTitle
		}
	}

	appendContainer = (coins) => {
		this.props.addContainer("RightBlock", this.state.id, coins, window.playerId, window.playerName);
	}

	removeContainer = () => {
		this.props.removeContainer("RightBlock", this.state.id);
	}

	addCoin = (value) => {
		this.props.addCoin("RightBlock", this.state.id, value, window.playerId, window.playerName);
	}

	removeCoin = (index) => {
		this.props.removeCoin("RightBlock", this.state.id, index, window.playerId, window.playerName);
	}

	showContainerProperties = () => {
		this.props.openContainerProperties('FIELD', 'RightBlock', this.state.id);
	}

	onGuidedTourNext = () => {
		this.props.nextGuidedTour();
	}
	onGuidedTourPrev = () => {
		this.props.prevGuidedTour();
	}

	render() {
		let coinsValue = 0
		this.props.rightBlock[this.state.id].coins.forEach( coin => {
			coinsValue += coin
		})

		return (
			<Container>
				<Row>
					<div className="left-right-block Right-block">
						<Row>
							<div className="text-column">
								<p className="Text-bold-size">{ this.state.germanTitle }</p>
								<p className="Text-size">{ this.state.englishTitle }</p>
								<p className="Text-size">{ this.state.spanishTitle }</p>
							</div>
							<div className="field-column" onClick={this.showContainerProperties}>
								<DroppableField
									canEdit={this.state.canEdit}
									class={ dropFieldClassNames.RightBlock }
									data={ this.props.rightBlock[this.state.id] }
									isHorizontal={ false }
									appendContainer={ this.appendContainer }
									removeContainer={ this.removeContainer }
									addCoin={ this.addCoin }
									removeCoin={ this.removeCoin }
								/>
							</div>

							{
								(this.state.id === 4 && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "indirectProductionCost") ?
									<div className="rightBlockTourPopup">
										<div className="upArrow"></div>
										<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
										<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

										<div className="actions">
											<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
												Prev
											</div>
											{
												(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "coins" && coinsValue === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value) ?
													<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
														Next
													</div>
													:
													null
											}
										</div>
									</div>
								:
									null
							}
							{
								(this.state.id === 1 && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "depreciationOfCapitalized") ?
									<div className="rightBlockTourPopup">
										<div className="upArrow"></div>
										<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
										<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

										<div className="actions">
											<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
												Prev
											</div>
											{
												(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "coins" && coinsValue === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value) ?
													<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
														Next
													</div>
													:
													null
											}
										</div>
									</div>
								:
									null
							}
							{
								(this.state.id === 2 && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "depreciationOfProductionPlanet") ?
									<div className="rightBlockTourPopup">
										<div className="upArrow"></div>
										<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
										<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

										<div className="actions">
											<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
												Prev
											</div>
											{
												(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "coins" && coinsValue === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value) ?
													<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
														Next
													</div>
													:
													null
											}
										</div>
									</div>
								:
									null
							}
							{
								(this.state.id === 3 && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "depreciationOfMachine") ?
									<div className="rightBlockTourPopup">
										<div className="upArrow"></div>
										<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
										<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

										<div className="actions">
											<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
												Prev
											</div>
											{
												(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "coins" && coinsValue === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value) ?
													<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
														Next
													</div>
													:
													null
											}
										</div>
									</div>
								:
									null
							}
							{
								(this.state.id === 0 && this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "indirectRAndD") ?
									<div className="rightBlockTourPopup">
										<div className="upArrow"></div>
										<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
										<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

										<div className="actions">
											<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
												Prev
											</div>
											{
												(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "coins" && coinsValue === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value) ?
													<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
														Next
													</div>
													:
													null
											}
										</div>
									</div>
								:
									null
							}
						</Row>
						<Image src={ rightArrow } className="Arrow-class" />
					</div>
				</Row>
			</Container>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		rightBlock: state.board.RightBlock,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RightBlock);