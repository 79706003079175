import React, { Component } from "react";
import {Table, Col, Container, Row, Image, Button} from "react-bootstrap";
import {connect} from "react-redux";

// Dispatcher
import mapDispatchToProps from "../reducers/dispatchers";

// Images
import containerImg from "../assets/images/container-h.png";
import flippedContainerImg from "../assets/images/box-container-flipped.png";
import prevCoinImg from "../assets/images/dollar_coin.png";
import prevSilverCoinImg from "../assets/images/dollar_coin_silver.png";
import prevBronzeCoinImg from "../assets/images/dollar_coin_bronze.png";

import coinImg from "../assets/images/dollar_coin_h.png";
import coinSilverImg from "../assets/images/dollar_coin_silver_h.png";
import coinBronzeImg from "../assets/images/dollar_coin_bronze_h.png";
import facrotyImgTiles from "../assets/images/factory-img-h.png";
import machineImgTiles from "../assets/images/machine-img-h.png";
import facrotyImg from "../assets/images/factory-card.png";
import green from "../assets/images/tiled-green-card.png";
import yellow from "../assets/images/tiled-yellow-card.png";
import blue from "../assets/images/tiled-blue-card.png";
import red from "../assets/images/tiled-red-card.png";

//const formatNumber = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
const formatNumber = new Intl.NumberFormat('en-US');
const fieldsWithTypeAliases = ["Research", "Purchase", "Production", "ColorField"];

class ContainerProperties extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit,
			showAddCashQuestion: false,
			showSubtractCashQuestion: false,
			showInvestmentLine: false,
			addCashValue: 0,
			subtractCashValue: 0
		}
	}

	addCoinsBtn = () => {
		this.setState({ showAddCashQuestion: true, showSubtractCashQuestion: false })
	}

	subtractCoinsBtn = () => {
		this.setState({ showAddCashQuestion: false, showSubtractCashQuestion: true })
	}

	addCoinsAction = (currentCoins) => {
		let cashValue = parseFloat(this.state.addCashValue);
		if (this.props.startingKit.coins >= cashValue) {
			let newCoins = []
			while (cashValue > 0) {
				if (cashValue >= 10) {
					newCoins.push(10)
					cashValue -= 10
				}else if(cashValue >= 1) {
					newCoins.push(1)
					cashValue -= 1
				}else{
					newCoins.push(0.5)
					cashValue -= 0.5
				}
			}

			if (newCoins.length > 0) {
				if ((newCoins.length + currentCoins.length) <= 20) {
					this.setState({ showAddCashQuestion: false })

					if (fieldsWithTypeAliases.includes(this.props.properties.options.fieldName)) {
						const fieldType = (this.props.properties.options.title !== undefined) ? this.props.properties.options.title : this.props.properties.options.optionType
						this.props.addCoinsFieldType(this.props.properties.options.fieldName, fieldType, newCoins, this.props.properties.options.id, window.playerId, window.playerName)
					}else{
						this.props.addCoins(this.props.properties.options.fieldName, this.props.properties.options.id, newCoins, window.playerId, window.playerName)
					}

					setTimeout(() => {
						this.props.decreaseStartKitCoin(parseFloat(this.state.addCashValue));
					}, 200);

					this.props.closePanel();
				}
			}
		}
	}

	subtractCoinsAction = (currentCoins) => {
		if (currentCoins.length > 0) {
			let cashValue = parseFloat(this.state.subtractCashValue);

			let currentCoinsValue = 0
			for (let i = 0; i < currentCoins.length; i++) {
				currentCoinsValue += currentCoins[i];
			}

			if (currentCoinsValue >= cashValue) {
				let newCashValue = currentCoinsValue - cashValue;
				let newCoins = []
				while (newCashValue > 0) {
					if (newCashValue >= 10) {
						newCoins.push(10)
						newCashValue -= 10
					}else if(newCashValue >= 1) {
						newCoins.push(1)
						newCashValue -= 1
					}else{
						newCoins.push(0.5)
						newCashValue -= 0.5
					}
				}

				if (newCoins.length <= 20) {
					this.setState({ showSubtractCashQuestion: false })
					if (fieldsWithTypeAliases.includes(this.props.properties.options.fieldName)) {
						const fieldType = (this.props.properties.options.title !== undefined) ? this.props.properties.options.title : this.props.properties.options.optionType
						this.props.updateCoinsFieldType(this.props.properties.options.fieldName, fieldType, newCoins, this.props.properties.options.id, window.playerId, window.playerName)
					}else{
						this.props.updateCoins(this.props.properties.options.fieldName, this.props.properties.options.id, newCoins, window.playerId, window.playerName)
					}

					setTimeout(() => {
						this.props.increaseStartKitCoin(parseFloat(this.state.subtractCashValue));
					}, 200);

					this.props.closePanel();
				}
			}
		}
	}

	factoryRent = (id, coins) => {
		this.setState({
			showInvestmentLine: false
		})
		this.props.rentFactory(id)

		if (coins.length > 0) {
			// Add to starting kit
			let coinsValue = coins.reduce((total, num) => {
				return parseFloat(total) + parseFloat(num);
			})

			this.props.increaseStartKitCoin(coinsValue);
		}
	}

	factoryPurchase = (id) => {
		this.setState({
			showInvestmentLine: true
		})
		this.props.purchaseFactory(id)
	}

	getCoinRow = (key, imgSrc, count, value) => {
		return (
			<tr key={key}>
				<td><Image src={imgSrc} className="prevCoinImg" /></td>
				<td>{ count }</td>
				<td>{ formatNumber.format(parseFloat(value) * count) }</td>
			</tr>
		)
	}

	render() {
		const properties = this.props.properties;
		const boardData = this.props.board;
		let leftRightBottomAliases = ["LeftBlock", "RightBlock", "BottomBlock"];

		let productionType = "";
		let productionObject = {};
		let coins = [];
		let coinsList = {};
		let showActionsButtons = true;

		if (leftRightBottomAliases.includes(properties.options.fieldName)) {
			const object = boardData[properties.options.fieldName][properties.options.id]
			coins = object.coins;
			if (!object.hasContainer) {
				showActionsButtons = false
			}
		}else if (properties.options.fieldName === "Finance") {
			const object = boardData.CenterBlock[properties.options.fieldName].cash[properties.options.id]
			coins = object.coins;
			if (!object.hasContainer) {
				showActionsButtons = false
			}
		}else if (properties.options.fieldName === "Research") {
			const object = boardData.CenterBlock[properties.options.fieldName][properties.options.title]
			coins = object.coins;
			if (!object.hasContainer) {
				showActionsButtons = false
			}
		}else if (properties.options.fieldName === "Purchase") {
			const object = boardData.CenterBlock[properties.options.fieldName][properties.options.title][properties.options.id];
			coins = object.coins;
			if (!object.hasContainer) {
				showActionsButtons = false
			}
		}else if (properties.options.fieldName === "Production") {
			productionType = properties.options.optionType;
			productionObject = boardData.CenterBlock[properties.options.fieldName][properties.options.id][properties.options.optionType];
			coins = productionObject.coins;

			if (!productionObject.hasContainer) {
				showActionsButtons = false
			}
		}else if (properties.options.fieldName === "ColorField") {
			coins = boardData.CenterBlock.Stock[properties.options.optionType][properties.options.id].coins;
			productionObject = boardData.CenterBlock.Stock[properties.options.optionType][properties.options.id];

			showActionsButtons = false;
		}else if (properties.options.fieldName === "DirectCost") {
			coins = boardData[properties.options.fieldName][properties.options.id].coins;
			productionObject = boardData[properties.options.fieldName][properties.options.id];

			showActionsButtons = false;
		}

		coins.forEach((value) => {
			coinsList[`${ value }`] = (coinsList[`${ value }`]) ? coinsList[`${ value }`] + 1 : 1;
		});

		let coinsRows = [];
		let totalCoinsValue = 0;
		let totalCoinsCount = 0;

		if (coinsList[`10`]) {
			totalCoinsValue += (10 * parseFloat(coinsList[`10`]));
			totalCoinsCount += parseFloat(coinsList[`10`]);

			coinsRows.push(this.getCoinRow(0, prevCoinImg, coinsList[`10`], 10));
		}
		if (coinsList[`1`]) {
			totalCoinsValue += (1 * parseFloat(coinsList[`1`]));
			totalCoinsCount += parseFloat(coinsList[`1`]);

			coinsRows.push(this.getCoinRow(1, prevSilverCoinImg, coinsList[`1`], 1));
		}
		if (coinsList[`0.5`]) {
			totalCoinsValue += (0.5 * parseFloat(coinsList[`0.5`]));
			totalCoinsCount += parseFloat(coinsList[`0.5`]);

			coinsRows.push(this.getCoinRow(2, prevBronzeCoinImg, coinsList[`0.5`], 0.5));
		}

		return (
			<>
				<div className="containerObjClass">
					{
						(productionObject.isFlip && productionObject.cardType === "factory") ?
							<>
								<Image className="objModelClass" src={flippedContainerImg} />
								<div className="card-droppable">
									{
										(productionObject.hasCard && productionType === "factory")?
											<Image className="Container-Card" src={facrotyImg} />
											:
											null
									}
								</div>
							</>
							:
							<>
								<Image className="objModelClass" src={containerImg}/>
								<div className="coins-container">
									<div className="helper"></div>
									{
										(coins.map((value, index) => {
											return (
												(value === 10 &&
													<Image key={index} src={coinImg} className="coins"/>)
												|| (value === 1 &&
													<Image key={index} src={coinSilverImg} className="coins"/>)
												|| (value === 0.5 &&
													<Image key={index} src={coinBronzeImg} className="coins"/>)
											)
										}))
									}
									<>
										{
											(productionObject.hasCard && productionObject.cardType === "factory")?
												<Image className="Container-Card" src={facrotyImgTiles} />
												:
												null
										}
									</>
									<>
										{
											(productionObject.hasCard && productionObject.cardType === "machine")?
												<Image className="Container-Card" src={machineImgTiles} />
												:
												null
										}
									</>
									<>
										{
											(productionObject.hasCard && productionObject.cardType === "label") ?
												<div className="productCardContainer">
													{(
														(productionObject.cardColor === "green" &&
															<Image className="productCardImg" src={green} />)
														|| (productionObject.cardColor === "yellow" &&
															<Image className="productCardImg" src={yellow} />)
														|| (productionObject.cardColor === "blue" &&
															<Image className="productCardImg" src={blue} />)
														|| (productionObject.cardColor === "red" &&
															<Image className="productCardImg" src={red} />)
													)}
													<>
														{
															(productionObject.cardValue > 0) ?
																<div className="productCardValue">{ productionObject.cardValue }</div>
																:
																null
														}
													</>
												</div>
												:
												null
										}
									</>
								</div>
							</>
					}
				</div>

				{
					(productionType === "factory" && this.state.canEdit) ?
						<Container style={{ float: "right", width: "30%" }}>
							<Row>
								<Col>
									<Button
										className="purchase-btn"
										onClick={this.factoryPurchase.bind(this, properties.options.id)}
										size="sm"
									>
										Purchase
									</Button>
								</Col>
							</Row>
							<Row style={{ marginTop: "5px" }}>
								<Col>
									<Button
										className="rent-btn"
										onClick={this.factoryRent.bind(this, properties.options.id, coins)}
										size="sm"
									>
										Rent
									</Button>
								</Col>
							</Row>
						</Container>
						:
						null
				}

				<div className="clearfix"></div>
				{
					(this.state.showInvestmentLine) ?
						<div className="investmentLine">Make sure to make an investment</div>
						:
						null
				}
				<div style={{ height: 20 }}></div>
				<Table striped bordered>
					<thead>
						<tr style={{ color: 'white', backgroundColor: "#002955"}}>
							<th>Value</th>
							<th>Count</th>
							<th>Cash</th>
						</tr>
					</thead>
					<tbody>
					{ coinsRows }
					</tbody>
					<tbody>
						<tr>
							<td className="bold-text">Total</td>
							<td className="bold-text">{ totalCoinsCount }</td>
							<td className="bold-text">{ formatNumber.format(totalCoinsValue) }</td>
						</tr>
					</tbody>
				</Table>
				<div className="horizontal-separator">&nbsp;</div>
				{
					(showActionsButtons && !productionObject.isFlip) ?
						<div className="container-actions">
							<Container>
								<Row>
									<Col xs={1}></Col>
									<Col>
										<Button
											className="purchase-btn"
											onClick={ this.addCoinsBtn }
											size="sm"
										>
											Add
										</Button>
									</Col>
									<Col xs={2}></Col>
									<Col>
										<Button
											className="rent-btn"
											onClick={ this.subtractCoinsBtn }
											size="sm"
										>
											Subtract
										</Button>
									</Col>
									<Col xs={1}></Col>
								</Row>
								<Row>
									<Col className="questions-container">
										{
											(this.state.showAddCashQuestion) ?
												<>
													<div className="question-text">How much cash do you want to add?</div>
													<div className="question-input">
														<input
															type="text"
															autoFocus
															id="units"
															pattern="^-?[0-9]\d*\.?\d*$"
															name="units"
															onKeyPress={
																(event) => {
																	if (event.key === "Enter") {
																		this.addCoinsAction(coins)
																	}
																}
															}
															onChange={e => this.setState({ addCashValue: e.target.value })}
														/>
													</div>
													<div className="question-btn">
														<Button size="sm" onClick={ this.addCoinsAction.bind(this, coins) }>Confirm</Button>
													</div>
												</>
												:
												null
										}
										{
											(this.state.showSubtractCashQuestion) ?
												<>
													<div className="question-text">How much cash do you want to subtract?</div>
													<div className="question-input">
														<input
															type="text"
															autoFocus
															id="units2"
															pattern="^-?[0-9]\d*\.?\d*$"
															name="units2"
															onKeyPress={
																(event) => {
																	if (event.key === "Enter") {
																		this.subtractCoinsAction(coins)
																	}
																}
															}
															onChange={e => this.setState({ subtractCashValue: e.target.value })}
														/>
													</div>
													<div className="question-btn">
														<Button size="sm" onClick={ this.subtractCoinsAction.bind(this, coins) }>Confirm</Button>
													</div>
												</>
												:
												null
										}
									</Col>
								</Row>
							</Container>
						</div>
						:
						null
				}
			</>
		);

	}
}

const mapStateToProps = (state) => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainerProperties);
