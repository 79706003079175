import React, {Component} from "react";
import {connect} from "react-redux";

// Dispatcher
import mapDispatchToProps from "../../../../../reducers/dispatchers";

// Components
import ColoredDroppableField from "./ColoredDroppableField";

class ColoredDroppableHorizontalField extends Component{

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit,
			id: props.id,
			type: props.type
		}
	}

	appendContainer = (coins, hasCard, cardValue, cardColor) => {
		this.props.addContainerColorField('ColorField', this.state.type, this.state.id, coins, hasCard, cardValue, cardColor, window.playerId, window.playerName)
	}

	removeContainer = () => {
		this.props.removeContainerColorField('ColorField', this.state.type, this.state.id)
	}

	showContainerProperties = () => {
		this.props.openContainerPropertiesWithOption('FIELD', 'ColorField', this.state.type, this.state.id);
	}

	render() {
		return (
			<div className="droppable-field" onClick={this.showContainerProperties}>
				<ColoredDroppableField
					canEdit={this.state.canEdit}
					id={ this.state.id }
					data={ this.props.fieldObject }
					fieldType={this.state.type}
					appendContainer={ this.appendContainer }
					removeContainer={ this.removeContainer }
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ColoredDroppableHorizontalField);
