import React, {Component} from "react";
import {Row, Col} from "react-bootstrap";

// Components
import ResearchRow from "./ResearchRow";

class ResearchBlock extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit
		}
	}

	render() {
		return (
			<div className="Research-block-container">
				<Row>
					<Col>
						<p className="Research-bold-text Research-title-text-size">F & E</p>
					</Col>
					<Col>
						<p className="Research-title-text-size">R & D</p>
					</Col>
					<Col>
						<p className="Research-title-text-size Text-italic">I & D</p>
					</Col>
					<Col></Col>
				</Row>
				<ResearchRow canEdit={this.state.canEdit} className="Standard-row" title="STANDARD" />
				<ResearchRow canEdit={this.state.canEdit} className="Compact-row" title="COMPACT" />
				<ResearchRow canEdit={this.state.canEdit} className="Plus-row" title="PLUS" />
				<ResearchRow canEdit={this.state.canEdit} className="Luxus-row" title="LUXUS" />
			</div>
		);
	}
}

export default ResearchBlock;