const initState = {
	LeftBlock: [
		{
			hasContainer: false,
			coins: [],
			playerId: 0,
			isDragging: false,
			coinsDraggingIndex: -1
		},
		{
			hasContainer: false,
			coins: [],
			playerId: 0,
			isDragging: false,
			coinsDraggingIndex: -1
		},
		{
			hasContainer: false,
			coins: [],
			playerId: 0,
			isDragging: false,
			coinsDraggingIndex: -1
		},
		{
			hasContainer: false,
			coins: [],
			playerId: 0,
			isDragging: false,
			coinsDraggingIndex: -1
		},
		{
			hasContainer: false,
			coins: [],
			playerId: 0,
			isDragging: false,
			coinsDraggingIndex: -1
		},
		{
			hasContainer: false,
			coins: [],
			playerId: 0,
			isDragging: false,
			coinsDraggingIndex: -1
		},
		{
			hasContainer: false,
			coins: [],
			playerId: 0,
			isDragging: false,
			coinsDraggingIndex: -1
		}
	],
	CenterBlock: {
		Finance: {
			capital: "",
			token_5: {
				type: "",
				value: 0
			},
			token_10: {
				type: "",
				value: 0
			},
			token_black: {
				type: "",
				value: 0
			},
			cash: [
				{
					hasContainer: false,
					coins: []
				},
				{
					hasContainer: false,
					coins: []
				},
				{
					hasContainer: false,
					coins: []
				},
				{
					hasContainer: false,
					coins: []
				},
				{
					hasContainer: false,
					coins: []
				},
				{
					hasContainer: false,
					coins: []
				},
				{
					hasContainer: false,
					coins: []
				},
				{
					hasContainer: false,
					coins: []
				},
				{
					hasContainer: false,
					coins: []
				},
				{
					hasContainer: false,
					coins: []
				},
				{
					hasContainer: false,
					coins: []
				},
				{
					hasContainer: false,
					coins: []
				}
			]
		},
		Research: {
			standard: {
				hasContainer: false,
				coins: []
			},
			compact: {
				hasContainer: false,
				coins: []
			},
			plus: {
				hasContainer: false,
				coins: []
			},
			luxus: {
				hasContainer: false,
				coins: []
			}
		},
		Purchase: {
			standard: [
				{
					hasContainer: false,
					coins: []
				},
				{
					hasContainer: false,
					coins: []
				}
			],
			compact: [
				{
					hasContainer: false,
					coins: []
				},
				{
					hasContainer: false,
					coins: []
				}
			],
			plus: [
				{
					hasContainer: false,
					coins: []
				},
				{
					hasContainer: false,
					coins: []
				}
			],
			luxus: [
				{
					hasContainer: false,
					coins: []
				},
				{
					hasContainer: false,
					coins: []
				}
			]
		},
		Production: [
			{
				pins: {
					fast: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					medium: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					slow: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					}
				},
				factory: {
					hasContainer: false,
					coins: [],
					isFlip: false,
					hasCard: false,
					cardType: 'factory'
				},
				machine: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardType: 'machine'
				},
				product: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardColor: "",
					cardValue: 0,
					cardType: 'label'
				}
			},
			{
				pins: {
					fast: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					medium: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					slow: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					}
				},
				factory: {
					hasContainer: false,
					coins: [],
					isFlip: false,
					hasCard: false,
					cardType: 'factory'
				},
				machine: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardType: 'machine'
				},
				product: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardColor: "",
					cardValue: 0,
					cardType: 'label'
				}
			},
			{
				pins: {
					fast: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					medium: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					slow: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					}
				},
				factory: {
					hasContainer: false,
					coins: [],
					isFlip: false,
					hasCard: false,
					cardType: 'factory'
				},
				machine: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardType: 'machine'
				},
				product: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardColor: "",
					cardValue: 0,
					cardType: 'label'
				}
			},
			{
				pins: {
					fast: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					medium: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					slow: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					}
				},
				factory: {
					hasContainer: false,
					coins: [],
					isFlip: false,
					hasCard: false,
					cardType: 'factory'
				},
				machine: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardType: 'machine'
				},
				product: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardColor: "",
					cardValue: 0,
					cardType: 'label'
				}
			},
			{
				pins: {
					fast: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					medium: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					slow: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					}
				},
				factory: {
					hasContainer: false,
					coins: [],
					isFlip: false,
					hasCard: false,
					cardType: 'factory'
				},
				machine: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardType: 'machine'
				},
				product: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardColor: "",
					cardValue: 0,
					cardType: 'label'
				}
			},
			{
				pins: {
					fast: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					medium: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					slow: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					}
				},
				factory: {
					hasContainer: false,
					coins: [],
					isFlip: false,
					hasCard: false,
					cardType: 'factory'
				},
				machine: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardType: 'machine'
				},
				product: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardColor: "",
					cardValue: 0,
					cardType: 'label'
				}
			},
			{
				pins: {
					fast: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					medium: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					slow: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					}
				},
				factory: {
					hasContainer: false,
					coins: [],
					isFlip: false,
					hasCard: false,
					cardType: 'factory'
				},
				machine: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardType: 'machine'
				},
				product: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardColor: "",
					cardValue: 0,
					cardType: 'label'
				}
			},
			{
				pins: {
					fast: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					medium: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					slow: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					}
				},
				factory: {
					hasContainer: false,
					coins: [],
					isFlip: false,
					hasCard: false,
					cardType: 'factory'
				},
				machine: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardType: 'machine'
				},
				product: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardColor: "",
					cardValue: 0,
					cardType: 'label'
				}
			},
			{
				pins: {
					fast: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					medium: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					slow: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					}
				},
				factory: {
					hasContainer: false,
					coins: [],
					isFlip: false,
					hasCard: false,
					cardType: 'factory'
				},
				machine: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardType: 'machine'
				},
				product: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardColor: "",
					cardValue: 0,
					cardType: 'label'
				}
			},
			{
				pins: {
					fast: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					medium: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					},
					slow: {
						standard: false,
						compact: false,
						plus: false,
						luxus: false
					}
				},
				factory: {
					hasContainer: false,
					coins: [],
					isFlip: false,
					hasCard: false,
					cardType: 'factory'
				},
				machine: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardType: 'machine'
				},
				product: {
					hasContainer: false,
					coins: [],
					hasCard: false,
					cardColor: "",
					cardValue: 0,
					cardType: 'label'
				}
			}
		],
		Sales: {
			Nat: [
				{
					value: 0,
					pin: false
				}
			],
			Int: [
				{
					value: 2,
					pin: false
				}
			],
			IntC1: [
				{
					value: 2,
					pin: false
				},
				{
					value: 2,
					pin: false
				}
			],
			IntC2: [
				{
					value: 2,
					pin: false
				},
				{
					value: 2,
					pin: false
				},
				{
					value: 2,
					pin: false
				},
				{
					value: 2,
					pin: false
				}
			]
		},
		Stock: {
			green: [
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				}
			],
			yellow: [
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				}
			],
			blue: [
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				}
			],
			red: [
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				},
				{
					hasContainer: false,
					coins: [],
					cardType: 'label',
					hasCard: false,
					cardColor: "",
					cardValue: 0
				}
			]
		}
	},
	RightBlock: [
		{
			hasContainer: false,
			coins: []
		},
		{
			hasContainer: false,
			coins: []
		},
		{
			hasContainer: false,
			coins: []
		},
		{
			hasContainer: false,
			coins: []
		},
		{
			hasContainer: false,
			coins: []
		}
	],
	BottomBlock: [
		{
			hasContainer: false,
			coins: []
		},
		{
			hasContainer: false,
			coins: []
		},
		{
			hasContainer: false,
			coins: []
		}
	],
	DirectCost:[
		{
			hasContainer: false,
			coins: [],
			cardType: 'label',
			hasCard: false,
			cardColor: "",
			cardValue: 0
		},
		{
			hasContainer: false,
			coins: [],
			cardType: 'label',
			hasCard: false,
			cardColor: "",
			cardValue: 0
		},
		{
			hasContainer: false,
			coins: [],
			cardType: 'label',
			hasCard: false,
			cardColor: "",
			cardValue: 0
		},
		{
			hasContainer: false,
			coins: [],
			cardType: 'label',
			hasCard: false,
			cardColor: "",
			cardValue: 0
		},
		{
			hasContainer: false,
			coins: [],
			cardType: 'label',
			hasCard: false,
			cardColor: "",
			cardValue: 0
		},
		{
			hasContainer: false,
			coins: [],
			cardType: 'label',
			hasCard: false,
			cardColor: "",
			cardValue: 0
		},
		{
			hasContainer: false,
			coins: [],
			cardType: 'label',
			hasCard: false,
			cardColor: "",
			cardValue: 0
		},
		{
			hasContainer: false,
			coins: [],
			cardType: 'label',
			hasCard: false,
			cardColor: "",
			cardValue: 0
		},
		{
			hasContainer: false,
			coins: [],
			cardType: 'label',
			hasCard: false,
			cardColor: "",
			cardValue: 0
		},
		{
			hasContainer: false,
			coins: [],
			cardType: 'label',
			hasCard: false,
			cardColor: "",
			cardValue: 0
		},
		{
			hasContainer: false,
			coins: [],
			cardType: 'label',
			hasCard: false,
			cardColor: "",
			cardValue: 0
		},
		{
			hasContainer: false,
			coins: [],
			cardType: 'label',
			hasCard: false,
			cardColor: "",
			cardValue: 0
		}
	]
};

export default initState;