import React, {Component} from "react";
import { connect } from "react-redux";
import {Col, Container, Row} from "react-bootstrap";

// Constants
import dropFieldClassNames from "../../../../utils/dropFieldClassNames";

// Dispatcher
import mapDispatchToProps from "../../../../reducers/dispatchers";

// Components
import DroppableField from '../../Common/DroppableField';
import TokenDroppableContainer from "./TokenDroppableContainer";
import Tooltip from "rc-tooltip/es";

class FinanceBlock extends Component {

	constructor(props) {
		super(props);

		this.state = {
			canEdit: props.canEdit
		}

		this.showTokenProperties = this.showTokenProperties.bind(this);
	}

	appendContainer = (coins, id) => {
		this.props.addContainer("Finance", id, coins, window.playerId, window.playerName);
	}

	removeContainer = (id) => {
		this.props.removeContainer("Finance", id);
	}

	addCoin = (id, value) => {
		this.props.addCoin("Finance", id, value, window.playerId, window.playerName);
	}

	removeCoin = (id, index) => {
		this.props.removeCoin("Finance", id, index, window.playerId, window.playerName);
	}

	onCapitalChange = (event) => {
		this.props.updateCapital(event.target.value);
	}

	showTokenProperties(tokenType) {
		if (tokenType === "token_black") {
			if (this.props.startingKit.blackTokens === 0 && this.props.finance.token_black.value === 0){
				return;
			}
		}
		this.props.openTokenProperties('TOKEN', tokenType);
	}

	showContainerProperties(id) {
		this.props.openContainerProperties('FIELD', "Finance", id);
	}

	onGuidedTourNext = () => {
		this.props.nextGuidedTour();
	}
	onGuidedTourPrev = () => {
		this.props.prevGuidedTour();
	}

	render() {
		let showTooltip = false
		let canEditCapital = !this.state.canEdit

		if (this.props.finance.isDragging && this.props.finance.type === "CAPITAL") {
			if (this.props.finance.playerId !== window.playerId) {
				showTooltip = true
				canEditCapital = false
			}
		}

		let hasContainer = false;
		let coinsValue = 0;

		const cashList = this.props.finance.cash;
		const droppableFieldsList = cashList.map((value, index) => {
			if (value.hasContainer) {
				hasContainer = true;

				if (value.coins.length > 0) {
					coinsValue = value.coins.reduce((total, num) => {
						return parseFloat(total) + parseFloat(num);
					})
				}
			}

			return (
				<React.Fragment key={index}>
					{
						(index % 4 === 0) ?
							<div className="w-100"></div>
							:
							null
					}
					<Col onClick={this.showContainerProperties.bind(this, index)}>
						<DroppableField
							canEdit={this.state.canEdit}
							id={ index }
							class={ dropFieldClassNames.Finance }
							data={ value }
							isHorizontal={ true }
							appendContainer={this.appendContainer}
							removeContainer={this.removeContainer}
							addCoin={this.addCoin}
							removeCoin={this.removeCoin}
						/>
					</Col>
				</React.Fragment>
			);
		})

		return (
			<div className="Finance-container">
				<Row>
					<Col>
						<div className="Finance-bold-title-text-size">FINANZEN</div>
					</Col>
					<Col>
						<div className="Finance-title-text-size">FINANCE</div>
					</Col>
					<Col>
						<div className="Finance-title-text-size Text-italic">FINANZAS</div>
					</Col>
					<Col xs={5}></Col>
				</Row>
				<Row>
					<div className="Finance-capital-container">
						<Row>
							<Col>
								<div className="Finance-bold-text Finance-text-size">Eigenkapital</div>
							</Col>
						</Row>
						<Row>
							<Col><div className="Finance-capital-text">Capital</div></Col>
							<Col><div className="Finance-text-italic">capital</div></Col>
							<Col><div className="Finance-text-italic">propio</div></Col>
						</Row>
						<Row>
							<Col>
								<div className="Finance-capital-field">
									<Tooltip placement="topRight" visible={ showTooltip }  overlay={<span>{ this.props.finance.playerName }</span>}>
										<input
											id="capitalInput"
											value={ this.props.finance.capital }
											readOnly={ canEditCapital }
											onChange={ this.onCapitalChange }
											className="capitalInput"
											type="text"
										/>
									</Tooltip>
								</div>
							</Col>
						</Row>
					</div>
					<div className="Finance-cash-container" style={{ position: 'relative' }}>
						<span className="Finance-cash-text-size">
							<span className="Text-bold">Kasse</span> cash caja
						</span>
						<div className="Finance-droppable-container">
							<Row>{ droppableFieldsList }</Row>
						</div>
						{
							(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "cash") ?
								<div className="headerGuidedTourPopup">
									<div className="upArrow"></div>
									<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
									<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

									<div className="actions">
										<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
											Prev
										</div>
										{
											(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "container" && hasContainer) ?
												<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
													Next
												</div>
												:
												null
										}
										{
											(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "coins" && coinsValue === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value) ?
												<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
													Next
												</div>
												:
												null
										}
									</div>
								</div>
							:
								null
						}
					</div>
				</Row>
				<Row className="Liability-block" style={{ position: 'relative' }}>
					<div className="width-20-percent">
						<Container>
							<Row>
								<Col><div className="Finance-capital-text">Kredite kurzfristig 10%</div></Col>
							</Row>
							<Row>
								<Col><div className="Finance-text-italic">liability current 10%</div></Col>
							</Row>
							<Row>
								<Col><div className="Finance-text-italic">créditos a corto plazo</div></Col>
							</Row>
						</Container>
					</div>
					<div className="width-20-percent" onClick={this.showTokenProperties.bind(this, "token_10")}>
						<TokenDroppableContainer
							canEdit={this.state.canEdit}
							type="token_10"
							value={this.props.finance.token_10}
						/>
					</div>
					<div className="width-20-percent" onClick={this.showTokenProperties.bind(this, "token_black")}>
						{
							(this.props.startingKit.blackTokens > 0 || this.props.finance.token_black.value > 0) ?
								<TokenDroppableContainer
									canEdit={this.state.canEdit}
									type="token_black"
									value={this.props.finance.token_black}
								/>
								:
								null
						}
					</div>
					<div className="width-20-percent">
						<Container className="Liability-right-section">
							<Row>
								<Col><div className="Finance-capital-text">Kredite langfristig 5%</div></Col>
							</Row>
							<Row>
								<Col><div className="Finance-text-italic">liabilities non-current 5%</div></Col>
							</Row>
							<Row>
								<Col><div className="Finance-text-italic">créditos a largo plazo</div></Col>
							</Row>
						</Container>
					</div>
					<div className="width-20-percent" onClick={this.showTokenProperties.bind(this, "token_5")}>
						<TokenDroppableContainer
							canEdit={this.state.canEdit}
							type="token_5"
							value={this.props.finance.token_5}
						/>
					</div>
					{
						(this.props.guidedTour.steps[this.props.guidedTour.currentStep].position === "liabilities") ?
							<div className="liabilityTourPopup">
								<div className="upArrow"></div>
								<h5>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].title }</h5>
								<p>{ this.props.guidedTour.steps[this.props.guidedTour.currentStep].message }</p>

								<div className="actions">
									<div className="prevBtnContainer" onClick={ this.onGuidedTourPrev.bind(this) }>
										Prev
									</div>
									{
										(this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.type === "token" && this.props.finance.token_5.value === this.props.guidedTour.steps[this.props.guidedTour.currentStep].validation.value) ?
											<div className="nextBtnContainer" onClick={ this.onGuidedTourNext.bind(this) }>
												Next
											</div>
											:
											null
									}
								</div>
							</div>
						:
							null
					}
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		finance: state.board.CenterBlock.Finance,
		startingKit: state.startingKit,
		guidedTour: state.guidedTour
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FinanceBlock);